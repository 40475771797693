// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customTitle {
  padding: 32px 32px 12px 32px;
  @include breakpoint-down('md') {
    padding: 16px;
  }
  @include flex($justify: space-between);
  .descriptionTitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: $grey-primary100;
    @include breakpoint-down('md') {
      font-size: 14px;
    }
  }
  border-bottom: 1px solid $grey-primary700;
  margin-bottom: 8px;
}

.tareModal {
  [class^='modal'] {
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    max-width: 420px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    @include breakpoint-down('md') {
      width: 90%;
    }
  }
}

.top {
  @include flex($items: flex-end);
  svg {
    width: 16px;
    height: 16px;
  }
}

.body {
  padding: 32px;
}
.content {
  @include flex($justify: space-around);
  width: 60%;
  margin: auto;
  @include breakpoint-down('md') {
    width: 80%;
  }
}
.action {
  @include flex($justify: space-around);
  width: 70%;
  margin: auto;
  margin-top: 32px;
  button {
    width: 160px;
    height: 56px;
    background-color: $main400;
    color: $grey-primary900;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    &:disabled {
      color: $main600;
      cursor: not-allowed;
    }

    &:focus {
      background-color: $main600;
    }
  }
}
.item {
  @include flex();
  gap: 8px;
  color: $grey-primary50;
}
