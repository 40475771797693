// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';
.collectPhotoModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: auto;
    display: flex;
  }
  box-sizing: border-box;

  [class^='content'] {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;
  }

  .customTitle {
    padding: 32px 32px 16px;
    box-shadow: 0px 1px 0px $borderGrey;
    @include flex($justify: space-between);

    .title {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .body {
    .videoContainer {
      width: 100%;
      height: 480px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      .previewImg {
        max-width: 640px;
        max-height: 80%;
      }

      .defaultImg {
        max-width: 180px;
      }

      video {
        max-width: 640px;
        max-height: 80%;
      }
    }

    .bottomButton {
      margin-top: 4px;
      padding: 24px 16px 32px 16px;

      > .captureButton, > .submitButton {
        height: $buttonMediumHeight;
        border-radius: 4px;
        border: none;
        background-color: $main400;
        color: $grey-primary900;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        padding: 12px 16px;

        &:focus {
          background-color: $main600;
        }
      }
      > .captureButton:disabled, > .submitButton:disabled {
        height: $buttonMediumHeight;
        border-radius: 4px;
        border: none;
        color: $main600;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        padding: 12px 16px;
        cursor: not-allowed;
      }

      .recaptureButton {
        height: $buttonMediumHeight;
        border-radius: 4px;
        border: 1px solid $grey-primary50;
        color: $grey-primary50;
        background-color: $grey-primary900;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        padding: 12px 16px;

        &:disabled {
          border: 1px solid $grey-primary600;
          color: $grey-primary600;
          cursor: not-allowed;
        }

        &:focus {
          border: 1px solid $grey-primary600;
        }
      }
    }
  }
}
