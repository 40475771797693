// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';
.textInput {
  position: relative;
  > div {
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
}

.mainFrame {
  border: 1px solid $primary200;
  cursor: pointer;
  border-radius: 4px;
  overflow-x: auto;
  padding: 0 10px;
  @include flex($justify: left);
  &.empty {
    border: 1px solid $error-border-color;
  }
  input {
    height: 42px;
    border: none;
    width: 250px;
    color: $primary600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    box-sizing: border-box;
    &:focus:not(.focus-visible) {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  p {
    margin-top: 4px;
    color: $error-border-color;
    text-align: left;
  }
  .error {
    color: $error-border-color;
  }
}

.inputFullWidth {
  width: 100% !important;
}

.errorText {
  color: $error-border-color;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
  margin-top: 4px;
}

.description {
  font-size: 12px !important;
  color: $primary300;
  font-weight: 400 !important;
}
