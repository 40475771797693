@import 'variables.scss';
@import 'mixin.scss';

.bookingContainer {
  width: 100%;
  height: 100%;

  .w65 {
    width: 65%;
  }

  .w35 {
    width: 35%;
  }

  .heading {
    display: inline-table;
    width: 100%;
    background-color: $grey-primary900;
    position: sticky;
    top: -1px;
    z-index: 1;
    border-bottom: 1px solid #2c2c2c;

    @include flex($direction: row, $justify: flex-start);

    .headingItem {
      padding: 8px 32px;
      color: $grey-primary50;
    }

    .textLeft {
      text-align: left;
    }
  }

  .items {
    @include flex($direction: column);
  }
}
