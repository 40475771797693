// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.recycleChainRoutesSettingModal {
  [class^='modal'] {
    @include flex($items: center, $justify: center);

    overflow: auto;
  }

  [class^='content'] {
    width: 945px;
    margin: 0 0 0 auto;
    background-color: $primary50;
  }

  &Header {
    @include flex($items: center, $justify: space-between);

    background-color: $primary75;
    box-shadow: 0px 1px 0px $shadow-primary75;
    padding: 20px 32px;
  }

  &Title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $primary600;
  }

  &IconClose {
    @include flex();

    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &Content {
    @include flex($justify: flex-start);

    height: calc(100vh - 132px);
    overflow: hidden;
    padding: 32px;

    &Left {
      flex: 3;
      height: 100%;
      padding-right: 32px;
    }

    &Line {
      width: 1px;
      height: calc(100% + 64px);
      background-color: $primary100;
      margin: -32px 0;
    }

    &Right {
      flex: 7;
      height: 100%;
      padding-left: 32px;

      &Line {
        height: 1px;
        width: calc(100% + 64px);
        background-color: $primary100;
        margin: 24px -32px;
      }
    }

    &RoutesSetting {
      height: calc(100% - 94px);
      overflow: auto;
      padding: 0 16px;
      margin: 0 -16px;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background-color: $primary75;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary100;
        border-radius: 4px;
        opacity: 0.7;
      }

      &::-webkit-scrollbar-thumb:hover {
        opacity: 1px;
      }
    }

    &Footer {
      margin: 0 auto;
    }

    &SubmitButton {
      min-width: 224px;
      background-color: $primary400;
      border: none;
      border-radius: 4px;
      padding: 12px 0px;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $primary50;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
        color: $primary200;
        background-color: $primary400;
      }
    }
  }

  &Sites {
    margin-top: 48px;
    height: calc(100% - 92px);
    overflow: auto;
  }

  &Site {
    @include flex();

    gap: 8px;
    background-color: $primary100;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    color: $primary600;
    text-align: left;
    width: fit-content;
    word-break: break-all;
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  &RouteSetting {
    &:not(:first-child) {
      margin-top: 24px;
    }

    &Header {
      background-color: $primary100;
      border: none;
      border-radius: 4px;
      padding: 12px 16px;
    }

    &Title {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $primary600;
      text-align: left;
      word-break: break-all;
    }

    &Content {
      @include flex($items: flex-start);

      gap: 16px;
      margin-top: 8px;
    }

    &List {
      flex: 1;

      &Header {
        @include flex($justify: flex-start);

        gap: 8px;
        background-color: $primary300;
        border-radius: 4px 4px 0px 0px;
        padding: 12px 16px;
      }

      &Index {
        @include flex();

        background-color: $primary50;
        border-radius: 50px;
        width: 24px;
        height: 24px;
      }

      &Title {
        font-size: 16px;
        line-height: 24px;
        color: $primary50;
      }

      &Sites {
        padding: 16px;
        border: 1px solid $primary100;
        border-radius: 0px 0px 4px 4px;
      }
    }
  }
}
