// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tableList {
  .scrollWapper{
    overflow: hidden;
  }

  .scrollWapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }

  .scrollWapper::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  

  table#customizeItemTable {
    min-width: 1200px;
    max-width: 100%;
    max-height: calc(100vh - 314px);
    height: calc(100vh - 300px) !important;

    tbody {
      overflow: auto;
      max-height: 600px;
      width: 100%;
      tr {
        &:hover {
          background: $table-hover-bg;
        }
      }
      
      td {
        padding: 10px !important;

        p {
          word-break: break-all;
          text-align: left;
          line-height: 20px;
          @include ellipseText(2);
        }
      }
    }
    
    thead {
      z-index: 0 !important;
    }

    thead>tr>th {
      padding: 10px !important;
      font-size: 14px;
      line-height: 20px;
    }

    tr {
      font-size: 14px;
      line-height: 20px;
      min-width: 1200px;
   
      .actionField {
        width: 8%;

        .contentAction {
          @include flex($justify: flex-start);
        }
      }
      .userName {
        width: 20%;
      }
      .siteId {
        width: 10%;
      }
      
      .operatorId {
        width: 14%;
      }

      .role{
        width: 14%;
        p {
          line-height: 1.5rem;
          @include ellipseText(2);
        }
      }
      
      .operatorType {
        width: 14%;
      }

      .password {
        width: 10%;
      }
    }
  
    td {
      vertical-align: middle;
      cursor: pointer;
      border-top: 1px solid $borderSecond-color;
      border-bottom: 1px solid $borderSecond-color;
      box-shadow: 0px 1px 0px $borderSecond-color
    }

    .msgError { 
      text-align: center;
    }
  }

  table#customizeItemTable::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }

  table#customizeItemTable::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  .actionEdit {
    margin-right: 24px;
  }
}
