// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.wrapper {
  width: 100%;

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    th {
      text-align: start;
      padding: 12px 16px;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0em;
      color: $grey-primary100;
    }

    .highlight {
      background: #2c2c2cb2;
    }

    .weight {
      text-align: right;
      background: #2c2c2cb2;
    }

    td {
      text-align: start;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: $grey-primary50;

      @include truncate-oneline();
    }
  }

  th:first-child {
    border-top-left-radius: 4px;
  }

  th:last-child {
    border-top-right-radius: 4px;
  }

  th,
  td {
    border: 1px solid $grey-primary700;
  }

  .totalWeight {
    margin-top: 16px;
    text-align: right;
    color: $grey-primary50;
    word-break: break-word;
    line-height: 22px;

    @include ellipseText(2);

    .container {
      @include flex($justify: space-between, $direction: row-reverse);
      gap: 4px 16px;
      flex-wrap: wrap;
    }
  }
}
