// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

@mixin text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

@mixin button {
  width: 100%;
  height: 88px;
  margin: 0;
  padding: 20px 40px;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;

  @include breakpoint-down('md') {
    width: 100%;
    padding: 10px;
    line-height: 18px;
  }
}

.compressionRegistration {
  .register {
    padding: 0 32px;
  }

  .scrollContents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .cItem {
      label {
        font-weight: 700;
      }
    }
    .cWeight {
      label {
        font-weight: 700;
      }
    }

    max-height: calc(
      var(--vh) - #{$headerHeight + $tabHeight + $bottomSmallHeight}
    );
    color: $grey-primary50 !important;
    input[type='text'],
    textarea {
      background-color: $grey-primary900;
      color: $grey-primary50;
      caret-color: $primary50;
      font-size: 16px;
      @include breakpoint-down('md') {
        font-size: 14px;
      }
    }
  }

  .weightContent {
    width: 100%;
    height: calc(100vh - 116px);
    position: relative;

    @include flex();

    .scaleConnectToggle {
      display: none;
      position: absolute;

      @include mq('xl') {
        display: block;
        top: 50%;
        right: 32px;
        transform: translateY(calc(50% - 196px));
      }
    }

    .scaleConnectToggleMobile {
      @include mq('xl') {
        display: none;
      }
    }

    .weightContainer {
      width: 640px;

      display: flex;
      flex-direction: column;
      gap: 24px;

      .title {
        text-align: left;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
        color: $grey-primary50;
      }

      .content {
        font-size: 20px;
        color: $grey-primary50;
        text-align: left;
      }

      @include mq('xs') {
        padding: 8px;
      }

      .field {
        padding: 24px 108px 24px 48px;
        border-radius: 12px;
        background: $login-input-bg;
        position: relative;

        .input {
          width: 100%;

          font-size: 128px;
          font-weight: 900;
          line-height: 160px;
          letter-spacing: 0em;

          color: $grey-primary50;
          border: none;
          border-radius: inherit;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
        .unit {
          position: absolute;
          right: 48px;
          bottom: 24px;

          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: left;
          color: $background-white;
        }
      }

      .bottom {
        .buttons {
          @include flex();
          gap: 24px;

          .back {
            @include button();

            color: $grey-primary50;
            background: transparent;
            border: 1px solid $grey-primary50;
          }

          .submit {
            @include button();

            color: $grey-primary900;
            background: $main400;
            border: 1px solid $main400;

            &:disabled {
              color: $main600;
              background-color: $main400;
              cursor: not-allowed;
              border: none;
            }

            &:focus {
              background-color: $main600;
            }
          }
        }
      }
    }
  }

  [class^='bottom-field'] {
    box-sizing: border-box;
    left: 0;
  }

  .bottomBtn {
    margin: 16px auto;
    @include flex($items: center, $justify: center);
    @include breakpoint-down('md') {
      margin: 16px;
    }

    button {
      min-width: 168px;
      padding: 16px 32px;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      height: $buttonMediumHeight;
      box-sizing: border-box;
      border-radius: 4px;

      @include breakpoint-down('md') {
        width: 100%;
        padding: 10px;
        line-height: 18px;
      }
    }

    .back {
      margin-right: 16px;
      color: $grey-primary50;
      background: transparent;
      border: 1px solid $grey-primary50;
    }

    .btnEditing {
      color: $grey-primary50;
      background-color: $grey-primary900;
      border: 1px solid $grey-primary50;
      border-radius: 4px;
      margin-right: 24px;
      @include breakpoint-down('md') {
        margin-right: 16px;
      }
      &:focus {
        border: 1px solid $grey-primary600;
      }
    }

    .btnRequest {
      width: 168px;
      height: 56px;
      color: $grey-primary900;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      margin: 0;
      font-size: 1rem !important;

      @include text();

      &:disabled {
        color: $main600;
        background-color: $main400;
        cursor: not-allowed;
        border: none;
      }

      &:focus {
        background-color: $main600;
      }
    }
  }

  table.receives-list {
    width: 100%;
    th {
      width: 30%;
    }
    td {
      text-align: left;
    }
    th,
    td {
      height: 44px;
      font-size: 17px;
      vertical-align: middle;
      border-bottom: 1px solid #dfdfdf;
      input {
        border: none;
        width: 90%;
        height: 100%;
      }
    }
  }

  [class^='PreRegisResourceSelect_resourcesListWrapper'] {
    height: calc(var(--vh) - #{$tabHeight + $headerHeight} - 78px - #{$bottomSmallHeight});
  }

  [class^='PreRegisResourceSelect_filterWrapper'] {
    height: calc(var(--vh) - #{$tabHeight + $headerHeight} - 102px - #{$bottomSmallHeight});
  }
}
