// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.resource {
  position: relative;

  @include flex($direction: column, $justify: normal, $items: normal);

  gap: 16px;

  background-color: #2c2c2c3d;
  padding: 16px 16px 32px 16px;

  .title {
    @include flex($justify: space-between);

    p {
      color: $grey-primary50;
      font-weight: bold;
    }
  }

  .formInput {
    height: 44px;

    @include flex($direction: row);

    .inputFrame {
      width: 100%;
      flex: 1 4 0%;
      > * {
        margin: 16px 0;
      }

      .textFrame {
        gap: 8px 8px;
        flex-wrap: wrap;

        @include flex($items: start, $justify: start);

        & > div {
          width: calc(25% - 6px);
        }

        [class^='SelectFieldMultipleChoise_selectMultipleField'] {
          height: 44px;
        }
      }
    }
  }

  .submitButton {
    height: $buttonSmallHeight;
    padding: 12px 20px;
    background-color: $main400;
    color: $grey-primary900;
    border-radius: 4px;
    border: 1px solid transparent;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;

    &:hover,
    &:active {
      background-color: $main500;
    }

    &:disabled {
      cursor: not-allowed;
      color: $main600;
      background-color: $main300 !important;
    }
  }

  .primaryTable {
    background-color: $grey-primary900 !important;
    color: $grey-primary50;
    width: 100%;
    max-height: 492px;
    border: 1px solid $grey-primary700;
    border-bottom: none;

    @include customScroll();

    thead {
      background-color: $grey-primary800 !important;
    }

    th {
      font-size: 12px !important;
      color: $grey-primary100;
      border-left: none;
      border-right: 1px solid $grey-primary700;
      border-bottom: 1px solid $grey-primary700;
      word-break: keep-all;

      @include breakpoint-down('md') {
        font-size: 14px !important;
        line-height: 20px;
      }
    }

    td {
      border: 1px solid $grey-primary700;
      border-left: none;
      border-top: none;

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .left {
    text-align: left;
  }

  .checkBox {
    width: 8%;
    vertical-align: middle;
    cursor: pointer;

    input:checked + label::before {
      background-color: #86ea5c !important;
    }
  }

  .label {
    width: 19%;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    padding: 2px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .type {
    width: 13%;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    padding: 2px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .content {
    width: 23%;
    line-height: 24px;
    font-size: 14px;
    text-align: left;
    padding: 2px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .weight {
    width: 10%;
    line-height: 24px;
    font-size: 14px;
    text-align: right;
    padding: 2px 16px 2px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .total {
    color: $grey-primary50;
    text-align: right;

    p {
      font-size: 14px;
      line-height: 20px;

      span {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
      }
    }
  }
}

.resourceContainer {
  @include flex($direction: column, $items: normal, $justify: normal);
  @include customScroll();
  gap: 20px;
  overflow: auto;
  background: $grey-primary900;
  height: calc(var(--vh) - 108px);
  padding: 20px;
}

.noData {
  padding: 24px 0;
  border-bottom: 1px solid $grey-primary700;
}
