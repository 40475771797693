// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.scanICCard {
  background-color: $grey-primary900;
  box-sizing: border-box;
  height: calc(100vh - 64px);
  width: 100%;
  overflow: auto;
  & > div {
    height: 100%;
    width: 100%;
    @include flex($direction: column);
  }
  .modalICCards {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $modalICCardBg;
    @include flex();
  }
  .modalICCardsContent {
    width: 480px;
    background: $grey-primary900;
    border-radius: 12px;
    padding-bottom: 16px;
  }
  .modalICCardsHeader {
    padding: 32px 32px 16px;
    border-bottom: 1px solid $grey-primary800;
    @include flex($justify: space-between);
    span {
      color: $grey-primary50;
      font-weight: 700;
      font-size: 18px;
    }
  }
  .modalICCardsBody {
    box-sizing: border-box;
    height: 192px;
    overflow-y: auto;
    padding: 0px 32px 12px;
    .icCardItem {
      padding: 24px 0 4px;
      @include flex($justify: space-between);
      span {
        color: $grey-primary50;
        flex: 1;
        text-align: left;
        @include ellipseText(1);
      }
    }
  }
  form {
    visibility: hidden;
  }
  .message {
    font-weight: 700;
    font-size: 18px;
    color: $grey-primary50;
    margin-bottom: 32px;
    @include breakpoint-down('md') {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .empty {
    background: $grey-primary700;
    width: 384px;
    height: 384px;
    @include breakpoint-down('md') {
      width: 250px;
      height: 250px;
    }
  }
}
