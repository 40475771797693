// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionInput {
  min-height: calc(100vh - #{$headerHeight});
  background-color: $grey-primary900;
  display: flex;
  justify-content: center;
  position: relative;

  .formInput {
    margin: 0 auto;
    padding-top: 100px;

    @include breakpoint-down('md') {
      width: calc(100% - 32px);
    }
  }

  .scaleConnectToggle {
    display: none;
    position: absolute;

    @include mq('xl') {
      display: block;
      top: 100px;
      right: 32px;
      transform: translateY(50%);
    }
  }

  .scaleConnectToggleMobile {
    margin-bottom: 12px;

    @include mq('xl') {
      display: none;
    }

    @include breakpoint-down('lg') {
      margin-left: 89px;
      margin-right: 89px;
    }

    @include breakpoint-down('md') {
      margin-left: 0;
    }
  }

  @include breakpoint-down('md') {
    overflow-x: hidden;
  }
}

.commonInfo {
  width: 640px;

  @include breakpoint-down('md') {
    width: 100%;
    margin-left: 0 !important;
  }

  @include breakpoint-down('lg') {
    margin: 0 89px;
  }

  .info {
    .labelBox {
      gap: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 28px;

      .label {
        color: $grey-primary50;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        @include fontSizeText();
        line-height: 48px;

        @include breakpoint-down('md') {
          font-size: 24px;
          line-height: 32px;
          text-align: left;
          margin-left: 6px;
        }
      }

      .type {
        min-width: 32px;
        box-sizing: border-box;

        @include breakpoint-down('md') {
          min-width: auto;
          padding: 0;
        }

        .itemType {
          background-color: $main500;
          border-radius: 4px;
          padding: 4px;

          svg {
            path {
              fill: $black;
            }
          }
        }
      }
    }

    .weightFix {
      color: $grey-primary50;
      text-align: left !important;

      @include fontSizeText();

      @include breakpoint-down('md') {
        font-size: 24px;
        line-height: 32px;
        margin-top: 16px;
      }
    }
  }
}

.formInput {
  margin-bottom: 50px;
}

.weightField {
  position: relative;
  margin-top: 1.5rem;

  [class^='InputDataListField'] {
    input {
      text-align: center;
      font-size: 128px;
      line-height: 160px;
      font-weight: 900;
      padding: 24px 89px 24px 48px;
      height: 48px;
      box-sizing: border-box;
      color: $grey-primary50;
      background-color: $login-input-bg;
      border: 1px solid transparent;
      border-radius: 12px;
      width: 650px;
      height: 208px;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
        width: auto;
        height: 128px;
        font-size: 68px;
        line-height: 80px;
        width: calc(100% - 7px);
      }
    }

    &>div {
      text-align: center !important;
    }

  }

  .weightUnit {
    position: absolute;
    top: 121px;
    right: 30px;
    color: $background-white;
    line-height: 1.5rem;
    font-size: 36px;
    font-weight: bold;

    @include breakpoint-down('lg') {
      top: 122px;
      right: 110px;
    }

    @include breakpoint-down('md') {
      font-size: 24px;
      line-height: 32px;
      top: 63px;
      right: 25px;
    }
  }
}

.emissionTare {
  font-size: 20px;
  line-height: 28px;
  gap: 1rem;
  flex-wrap: wrap;
  display: flex;
  max-width: 640px;

  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
    margin-left: 5px !important;
  }

  @include breakpoint-down('lg') {
    margin-left: 95px;
  }

  span {
    font-weight: 400;
    padding: 12px 24px;
    color: $grey-primary900;
    background: $grey-primary50;
    border-radius: 4px;
    border: 1px solid $grey-primary50;
    cursor: pointer;

    @include breakpoint-down('md') {
      padding: 12px 16px;
    }
  }

  .emissionSelected {
    text-shadow: 0.5px 0 currentColor;
    color: $grey-primary900;
    border: 1px solid $main100;
    background-color: $main100;
  }
}

.submitButton {
  height: 80px;
  padding: 16px 50px;
  margin: 24px 0;
  background-color: $main400;
  color: $grey-primary900;
  border-radius: 4px;
  border: none;
  width: 650px;
  @include fontSizeText();

  &:focus {
    background-color: $main600;
  }

  @include breakpoint-down('md') {
    width: calc(100% - 7px);
    font-size: 24px;
    line-height: 32px;
  }
}

.disableButton {
  cursor: not-allowed;
  color: $main600;
  background-color: $main400;
}
