// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.grade-total .label-text {
  border-bottom: none;
  font-weight: bold;
}
.container {
  background-color: $grey-primary900;
  min-height: calc(100vh - 65px);
}
