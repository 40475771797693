@import 'variables.scss';
@import 'mixin.scss';

.recycleChainDesignAddSiteForm {
  height: calc(100vh - 210px);

  .recycleForm {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .inputFrame {
    @include flex($justify: space-between);
    align-items: start;

    .chainName {
      width: 60%;
      padding-right: 16px;
    }

    .materialName {
      width: 40%;
      gap: 16px;
      @include flex($items: flex-start);

      & > div {
        width: 50%;
      }
    }
  }

  .siteArea {
    height: calc(100% - 115px);
    margin-top: 16px;
    flex: 1 1 0%;
    
    .siteList {
      height: calc(100% - 75px);
      margin: 0 -8px 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $primary100;
      overflow-x: auto;
      scrollbar-width: thin;
      @include flex($justify: flex-start, $items: flex-start);
    }

    .siteList::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $crollColor;
    }

    .siteList::-webkit-scrollbar {
      height: 5px;
    }
  }
  .message {
    color: $function300;
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
  }
  .submitButton {
    width: 180px;
    height: 44px;
    background-color: $primary400;
    border-radius: 4px;
    border: none;
    color: $primary50;
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    &:hover,
    &:active {
      background-color: $primary500;
    }
    &:disabled {
      color: $primary200;
      cursor: not-allowed;
      background-color: $primary400 !important ;
    }
    &.white {
      background: $primary50;
      color: $primary600;
      margin-right: 24px;
      border: 1px solid $primary400;
    }
    &.white,
    &.gray {
      flex: none;
    }
  }
  .editButtons {
    @include flex();
  }
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    @include flex();
  }
  [class^='contents'] {
    width: 448px;
    margin: auto;
    border-radius: 12px;
  }
  .modalContent {
    padding: 32px;
    position: relative;
    .submitButton {
      width: 256px;
    }
  }
  .customTitle {
    @include flex();
    .descriptionTitle {
      font-size: 20px;
      font-weight: 700;
      color: $primary600;
    }
    .top {
      position: absolute;
      top: 32px;
      right: 32px;
    }
  }
  .modalBody {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
    color: $primary600;
  }
}
