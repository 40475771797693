// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: $modal-z-index;
  overflow: auto;

  .contents {
    margin: 5% 5%;
    width: 90%;
    text-align: center;
    background: white;
    &.small {
      max-width: 600px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      padding-top: 30px;
      margin-bottom: 10px;
    }

    .body {
      margin-top: 20px;
    }

    .modal-bottom-field {
      height: inherit;
      display: flex;
      align-items: flex-end;
      .bottom-btn-field {
        margin-bottom: 30px;
        .primary-btn {
          margin: 15px 0;
        }
      }
    }
  }

  .closed {
    display: none;
  }
}
