// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionConfirmModal {
  position: relative;

  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }

  [class^='modal'] {
    @include flex($items: center, $justify: center);
  }

  [class^='content'] {
    width: 784px;
    margin: auto;
    background-color: $grey-primary900;
    border-radius: 4px;
    @include breakpoint-down('md') {
      width: calc(100% - 24px);
    }
  }

  .modalContent {
    .top {
      position: relative;
      padding: 2rem 2rem 1.5rem 2rem;
      box-shadow: 0px 1px 0px $primary600;
      @include flex($items: center, $justify: space-between);

      @include breakpoint-down('md') {
        padding: 24px 60px 16px 16px;
      }

      .title {
        font-size: 36px;
        line-height: 40px;
        font-weight: 700;
        color: $grey-primary50;
        text-align: left;
        @include fontSizeText();

        @include breakpoint-down('md') {
          font-size: 20px;
          line-height: 28px;
        }
      }

      .closeIcon {
        position: absolute;
        right: 32px;
      }
    }

    .body {
      margin: 24px 36px;

      @include breakpoint-down('md') {
        margin: 24px 30px 12px;
      }

      .tare {
        color: $grey-primary50;
        font-size: 36px;
        word-break: break-word;
        text-align: left;
        line-height: 50px;
        display: flex;
        gap: 4px 16px;
        flex-wrap: wrap;

        @include breakpoint-down('md') {
          font-size: 16px;
          line-height: 28px;
        }

        .tareName {
          min-width: calc(20% - 8px);
        }
      }

      .confirmInfo {
        text-align: left;
        display: flex;
        flex-direction: column;

        .confirmInfoRow {
          padding: 6px 0;
          font-size: 36px;
          line-height: 50px;
          color: $grey-primary50;
          @include flex($items: left, $justify: center);
          @include breakpoint-down('md') {
            font-size: 20px;
            line-height: 28px;
          }

          .confirmInfoLabel {
            width: 20%;
            text-align: left;
            padding-right: 10px;
            font-weight: 400;
          }

          .confirmInfoContents {
            width: 80%;
            font-weight: 400;
          }
        }
      }

      .contentUnit {
        color: $grey-primary50;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: baseline;

        .quantity {
          padding-bottom: 8px;

          @include breakpoint-down('md') {
            height: auto;
          }

          span {
            font-size: 128px;
            line-height: 160px;
            font-weight: 900;
            color: $grey-primary50;

            @include breakpoint-down('md') {
              font-size: 68px;
              line-height: 90px;
            }
          }
        }

        .weightUnit {
          padding-bottom: 8px;

          span {
            margin-left: 12px;
            @include fontSizeText();

            @include breakpoint-down('md') {
              font-size: 20px;
              line-height: 28px;
            }
          }
        }
      }
    }

    .confirmBtn {
      display: flex;
      justify-content: center;
      margin-bottom: 48px;

      @include breakpoint-down('md') {
        flex-direction: column-reverse;
        margin-bottom: 24px;
        align-items: center;
      }

      .finishButton {
        height: 80px;
        width: 332px;
        color: $grey-primary900;
        background-color: $main400;
        border: 1px solid $main400;
        border-radius: 4px;

        font-weight: bold;
        padding: 0 28px;
        margin-left: 24px;
        font-size: 36px;
        line-height: 32px;

        @include breakpoint-down('md') {
          height: 68px;
          width: 334px;
          font-size: 20px;
          line-height: 28px;
          margin-left: 0;
        }
      }

      .submitButton {
        height: 80px;
        width: 332px;
        color: $grey-primary50;
        background-color: $grey-primary900;
        border-radius: 4px;
        border: 1px solid $grey-primary50;
        font-weight: bold;
        padding: 0 28px;
        font-size: 36px;
        line-height: 32px;

        @include breakpoint-down('md') {
          height: 68px;
          width: 334px;
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 12px;
        }
      }
    }
  }

  .alertInfo {
    margin: 20px auto;

    &.errors {
      line-height: 24px;
      color: $function300;
    }
  }
}
