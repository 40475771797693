// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tenantManagement {
  padding: 16px 32px 0;

  @include breakpoint-down('md') {
    padding: 8px 16px 0;
  }

  .title {
    @include flex($justify: space-between);
    margin-bottom: 24px;

    .count {
      color: $grey-primary50;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
      span {
        font-weight: bold;
      }
    }
  }

  .actionWrapper {
    display: flex;
    gap: 8px;

    .searchBox {
      background-color: #4D4D4D;
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding-left: 12px;
      gap: 8px;

      input {
        font-size: 14px;
        outline: none;
        height: 100%;
        box-sizing: border-box;
        background-color: transparent;
        color: white;
        border: none;
        padding-right: 12px;
      }
    }
  }

  .addButton {
    height: $buttonSmallHeight;
    padding: 12px 20px;
    background-color: $main400;
    color: $grey-primary900;
    border-radius: 4px;
    border: 1px solid transparent;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    @include flex();

    &:active {
      background-color: $main600;

    }

    .iconAdd {
      margin-top: 2px;
      svg {
        path {
          stroke: $grey-primary900;
        }
      }
    }

    span {
      margin-left: 6px;
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .tenantTable {
    width: 100%;
    max-height: calc(
      var(--vh) - #{$headerHeight} - 16px - 60px - 40px
    );
    border-radius: 4px;
    border-bottom: 1px solid $grey-primary700;
    margin-bottom: 24px;
    overflow: auto;
    display: block;
    @include customScroll();

    @include breakpoint-down('md') {
      overflow: auto;
      max-height: calc(
        var(--vh) - #{$headerSmallHeight} - 8px - 48px - 12px
      );
      margin-bottom: 12px;
      display: block;
    }

    th,
    td {
      color: $grey-primary50;
      width: 25%;
      min-width: 140px;
      padding: 16px;
      height: auto;
      text-align: left;
      border: 1px solid $grey-primary700;
      vertical-align: middle;

      @include breakpoint-down('md') {
        padding: 6px;
      }
    }

    .SmallCol {
      width: 10%;
      min-width: 70px;
    }

    thead {
      position: sticky;
      top: -2px;
      background-color: $grey-primary800 !important;

      tr {
        height: $theadTableHeight;
        box-sizing: border-box;

        th {
          color: $grey-primary100;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    tbody {
      background: $grey-primary900;

      tr {
        height: 56px;
        box-sizing: border-box;

        td {
          &.icCardStyles {
            padding: 0;

            .icCardItem {
              td {
                border-bottom: 0;

                &:first-child {
                  border-left: 0;
                }

                &:last-child {
                  border-right: 0;
                }
              }

              &:first-child {
                td {
                  border-top: 0;
                }
              }
            }
          }

          &.noData {
            color: $grey-primary50;
            text-align: center;
            line-height: 56px;
            padding: 0 16px;
            font-size: 0.9em;
          }

          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $grey-primary50;
            display: inline-block;
            word-break: break-all;
          }

          input {
            background-color: $login-input-bg;
            border: 1px solid transparent;
            height: $inputSmallHeight;
            padding: 12px 16px;
            border-radius: 4px;
            width: 100%;
            color: $grey-primary50;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            box-sizing: border-box;

            &:focus:not(.focus-visible) {
              border: 1px solid $main400;
              outline: none;
              box-shadow: none;
            }
          }

          &.action {
            padding: 6px 16px;
            text-align: center;

            @include breakpoint-down('md') {
              padding: 6px 8px;
            }
          }

          button {
            margin: 0;
            padding: 12px 20px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            border: 1px solid $grey-primary50;
            box-sizing: border-box;
            width: 100%;

            @include breakpoint-down('md') {
              padding: 6px;
              font-size: 12px;
              line-height: 16px;
            }

            &.btnDashboard {
              height: $buttonSmallHeight;
              color: $grey-primary50;
              background-color: $grey-primary900;
              border: 1px solid $grey-primary50;
              border-radius: 4px;
              white-space: nowrap;

              &:focus {
                border: 1px solid $grey-primary600;
              }
            }
          }
        }
      }
    }

    .actionField {
      width: 20%;
      text-align: center;
    }
    .actionField1 {
      width: 10%;
      min-width: 80px;
      padding: 0px !important;

      .contentAction {
        @include flex();
        svg {
          cursor: pointer;
        }

        .actionEdit {
          padding: 16px;
          flex: 1;
          text-align: center;
          svg > path {
            stroke: $main400;
          }
        }

        .actionDelete {
          padding: 16px;
          svg > path {
            stroke: $function300;
          }
          flex: 1;
          text-align: center;
        }
      }
    }
  }

  .tenantExpandedTable {
    th,
    td {
      width: 20%;
    }
  }
}
