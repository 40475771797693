// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.navBar {
  .hamburgerMenu {
    .iconMenu {
      padding: 12px;
      cursor: pointer;
      position: absolute;
      top: 12px;
      right: 0;
      @include breakpoint-down('md') {
        top: 4px;
      }
    }
  }
  .menuContentAll {
    position: fixed;
    display: block;
    background-color: $dark400;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    transition: all 0.5s;

    .menuContents {
      width: 300px;
      height: 100%;
      overflow: auto;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      position: fixed;
      right: 0;
      z-index: 1;
      top: 0;
      background-color: $grey-primary900;

      .iconClose {
        padding: 32px;
        text-align: right;
        cursor: pointer;
      }

      .menuLinks {
        width: 100%;
        height: 100%;
        background-color: $grey-primary900;

        .menuTitle {
          img {
            height: 48px;
          }
        }

        .menuSubTitleContainer {
          padding: 24px 0px;
        }

        .menuSubtitle {
          color: $grey-primary50;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        }

        p {
          padding: 0 0 36px;
          color: $grey-primary50;
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          @include breakpoint-down('md') {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      .nameMobile {
        display: none;
        @include breakpoint-down('md') {
          display: block;
        }
      }

      .siteNameAndLanguages {
        display: none;

        .languages {
          position: relative;
        }

        @include breakpoint-down('lg') {
          padding: 8px 0 8px 24px;
          gap: 8px;
          @include flex($justify: space-between);

          .siteName {
            color: $grey-primary50;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            @include ellipseText(1);
          }

          .languages {
            color: $grey-primary50;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }
        }

        @include breakpoint-down('md') {
          padding: 8px 0 8px 24px;
          gap: 8px;
          @include flex($justify: space-between);

          .siteName {
            color: $grey-primary50;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;

            @include ellipseText(1);
          }

          .languages {
            color: $grey-primary50;
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
          }
        }
      }
    }

    .menuContents::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }

    .menuContents ul {
      // margin-top: 20px;
    }

    .menuContents ul li {
      list-style: none;
      text-align: left;

      path {
        stroke: $grey-primary300;
      }
      rect {
        stroke: $grey-primary300;
      }
    }

    .active {
      background-color: $primary600;
    }

    .menuContents ul li a,
    .logout {
      height: 72px;
      width: 100%;
      padding: 24px 0px 24px 68px;
      color: $grey-primary300;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      box-sizing: border-box;
      text-decoration: none;
      position: relative;
      cursor: pointer;
      @include flex($items: center, $justify: left);
      @include breakpoint-down('md') {
        height: $menuTabHeight;
        font-size: 14px;
        line-height: 20px;
        padding: 16px 0px 16px 68px;
      }

      svg {
        position: absolute;
        left: 32px;
      }
    }
    .menuContents ul li.active,
    .menuContents ul li:hover {
      background-color: $grey-primary800;
      a,
      .logout {
        color: $grey-primary50;
      }
      path {
        stroke: $grey-primary50;
        color: $grey-primary50;
      }
      rect {
        stroke: $grey-primary50;
      }
    }
  }

  .version {
    padding: 16px 8px;
    text-align: center !important;
    color: #878787;
    background-color: #1b1b1b;
    box-sizing: border-box;
    font-size: 14px;
  }
}
