// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.labelContainer {
  @include flex($justify: space-between);
  cursor: pointer;
  gap: 4px;

  .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #b7b7b7;
  }
}

.dropDown {
  box-sizing: border-box;
  width: 100%;
  min-width: 150px;
  max-width: 300px;
  z-index: 100;
  list-style: none;
  position: absolute;

  .clear {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: $function500;
    border-radius: 4px;
    color: $grey-primary50;
    margin-top: 4px;
    height: 38px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }

  ul {
    background-color: $grey-primary600;
    border: 1px solid $grey-primary500;
    max-height: 210px;
    overflow-y: auto;
    cursor: pointer;
    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
    font-size: 14px;

    li {
      position: relative;
      display: block;
      padding: 5px 0px;
      border-bottom: 1px solid $grey-primary500;
      overflow-wrap: break-word;
      color: $primary600;

      &:hover {
        background-color: $grey-primary400;
      }

      &.selected {
        background-color: $grey-primary400;
      }

      .optionName {
        color: $grey-primary50;
        width: 100%;
        text-align: left;
        padding-left: 8px;
      }
    }

    li:last-child {
      border-bottom: 0px;
    }
  }
}

.wrapSelected::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.wrapSelected,
ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.labelExpanded {
  flex-direction: column;
  justify-content: start;
  align-items: flex-start
}

.valuesList {
  display: flex;
  gap: 4px;
  overflow-x: auto;

  .valueItem {
    font-size: 14px;
    background-color: #b7b7b7;
    padding: 6px 8px;
    color: black;
    border-radius: 4px;
    display: flex;
    align-items: center;
    line-height: 20px;
    gap: 4px;
  }

  .value {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
