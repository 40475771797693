// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.offlineModal {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 40000;
  background-color: $modal-background;
}
.content {
  width: 100%;
  @include flex($justify: end);
  @include breakpoint-down('md') {
    @include flex($justify: center);
  }
}

.box {
  background-color: white;
  margin-top: 40px;
  margin-right: 40px;
  @include breakpoint-down('md') {
    margin-right: 0;
  }
  padding: 20px;
  border-radius: 8px;
  p {
    color: $error-border-color;
  }
}
