// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.traceability {
  padding: 8px 20px;
  height: calc(var(--vh) - 84px);
  overflow-y: auto;

  .content {
    background: #2c2c2c3d;
    border-radius: 12px;
    padding: 16px 16px 32px 16px;
    text-align: left;

    color: $grey-primary50;

    .header {
      height: 44px;
      margin-bottom: 16px;

      @include flex($justify: space-between);

      .title {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
      }

      .actions {
        gap: 4px;
        @include flex($items: center, $justify: space-between);
  
        a,
        .downloadCsv {
          background-color: $bgPurchase;
          padding: 12px 24px;
          font-size: 14px;
          font-weight: 700;
          color: $grey-primary50;
          border: none;
          box-sizing: border-box;
          margin: 0;
          @include flex($items: center);
  
          svg {
            margin-right: 4px;
          }
        }
      }
    }

    .primaryTable {
      background-color: $grey-primary900 !important;
      color: $grey-primary50;
      width: 100%;
      max-height: calc(var(--vh) - 192px);
      border: 1px solid $grey-primary700;
      border-bottom: none;

      thead {
        background-color: $grey-primary800 !important;
      }
  
      th {
        font-size: 12px !important;
        padding: 2px 16px;
        color: $grey-primary100;
        border-left: none;
        border-right: 1px solid $grey-primary700;
        border-bottom: 1px solid $grey-primary700;
  
        @include breakpoint-down('md') {
          font-size: 14px !important;
          line-height: 20px;
        }
      }

      .td110 {
        width: 10%;
        min-width: 115px;
      }

      .td120 {
        width: 15%;
        min-width: 120px;
      }

      .td100 {
        width: 12%;
        min-width: 70px;
      }
  
      td {
        border: 1px solid $grey-primary700;
        border-left: none;
        border-top: none;
  
        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }
      }

      tbody {
        tr {
          td {
            padding: 2px 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            border: 1px solid #3c3c3cb2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .td110 {
            max-width: 115px;
          }

          .td120 {
            max-width: 120px;
          }

          .td100 {
            max-width: 70px;
          }
        }

        tr:hover {
          background-color: $grey-primary800;
          cursor: pointer;

          @include disableSelectText();
        }
      }   
    }
  }
}

.nodata {
  text-align: center;
}

