// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionEditItem {
  display: flex;
  width: 100%;
  margin: auto;
  background-color: $grey-primary900;
  border-radius: 4px;
  border-bottom: 1px solid $primary600;
  align-items: center;

  @include breakpoint-down('md') {
    width: fit-content;
  }
}

.emissionEditItemCol {
  text-align: left;
  padding: 9px;
  position: relative;
  color: $grey-primary50;
  font-size: 16px;
  line-height: 24px;

  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }

  .unit {
    position: absolute;
    top: 22px;
    right: 20px;
    font-size: 16px;
    line-height: 24px;
    color: $grey-primary300;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .input {
    width: -webkit-fill-available;
    height: 48px;
    border: 1px solid $login-input-bg;
    border-radius: 4px;
  }

  input[type='text'] {
    height: $inputHeight;
    padding: 12px 30px 12px 16px;
    background-color: $login-input-bg;
    color: $grey-primary50;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid transparent;
    box-sizing: border-box;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }

    &:focus:not(.focus-visible) {
      border: 1px solid $main400;
      outline: none;
      box-shadow: none;
    }
  }
}

.optionsArea {
  display: flex;
  align-items: center;
  gap: 8px 16px;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.emissionEditItemColDate {
  width: 20%;
  min-width: 125px;
}

.emissionType {
  color: $grey-primary50;
  font-size: 14px;
  line-height: 20px;
  background-color: $main400;
  border-radius: 4px;
  height: 28px;
  width: 35px;
  @include flex();

  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }

  svg {
    path {
      fill: $black;
    }
  }
}

.emissionEditItemColLabel {
  width: 60%;
  min-width: 200px;
  padding: 20px !important;
  border-left: 1px solid $primary600;
  border-right: 1px solid $primary600;

  p {
    @include ellipseText(2);
    word-break: break-all;
  }
}

.emissionEditItemColInput {
  width: 20%;
  min-width: 130px;
}
