// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.barcodeICCardForm {
  .barcodeICCardContent {
    padding: 1.5rem 2rem;

    .barcodeICCardContentRow {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $black;
      text-align: left;
      margin-bottom: 12px;
      @include flex($items: start, $justify: flex-start);

      &:last-child {
        margin-bottom: 0;
      }

      .title {
        min-width: 170px;
        font-weight: 700;
      }

      .barcodeICCardInfo {
        width: calc(100% - 170px);

        .barcodeInfo {
          color: $mariner;
          font-weight: 400;
          text-decoration: underline;
          word-break: break-all;
          cursor: pointer;
        }

        .barcodeICCardItem {
          margin-bottom: 12px;
          @include flex($justify: space-between);

          .barcodeICCardName {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 400;
            word-break: break-all;
          }

          .iconDelete {
            min-width: 30px;
            line-height: 16px;
            text-align: right;
            cursor: pointer;
            svg {
              width: 16px;
              height: 16px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .line {
    width: 480px;
    height: 1px;
    background-color: $primary100;
    margin-left: -32px;
  }

  .submit {
    margin-top: 1.5rem;
    .submitButton {
      height: $buttonSmallHeight;
      width: 100%;
      padding: 12px 16px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: bold;
      box-sizing: border-box;
    }
  }
}
