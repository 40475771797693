// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminOperatorTypeForm {
  margin: auto;
  @include flex();

  .formInput {
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
      gap: 10px;
      padding: 0;
    }

    .inputFrame {
      padding: 32px 20px;

      .operatorTypeFrame {
        margin-top: 0;
        @include flex($items: center, $justify: space-around);
        .operatorTypeFrameItemLabel {
          color: $primary600;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
        }
      }
    }

    .submit {
      padding: 24px 32px 0;
      margin: 0 -32px;
      border-top: 1px solid $primary100;
      @include flex();
    }

    .submitButton {
      height: 44px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-size: 14px;
      font-weight: 700;
      flex: 1;
      &:hover,
      &:active {
        background-color: $primary500;
      }
      &:disabled {
        color: $primary200;
        cursor: not-allowed;
        background-color: $primary400 !important ;
      }
    }
  }

  .checkCard {
    gap: 10px;
  }
}
