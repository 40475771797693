@import 'variables.scss';
@import 'mixin.scss';

.container {
  width: 100%;
  height: 100%;

  @include flex($direction: column);
  gap: 12px;
  color: #e7e7e7;

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .value {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
  }
}
