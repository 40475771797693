// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.calendar-tablet {
  .react-calendar-tablet {
    background-color: $grey-primary800;
    color: white;
    margin: 0 auto;
    border: none;
    border-radius: 8px;
    padding: 12px 0 0;

    .react-calendar__navigation__arrow {
      color: white;
    }

    .react-calendar__navigation__label {
      font-size: 16px;
      color: white;
      font-weight: bold;
      background: none;
    }
    .react-calendar__month-view {
      padding: 0 16px 16px;
    }

    .react-calendar__tile.react-calendar__tile--now {
      border-radius: 4px;
      color: $grey-primary900;
      background-color: $grey-primary50;
    }

    .react-calendar__tile {
      width: 40px;
      height: 40px;
      margin-bottom: 4px;
      background-color: $grey-primary800;
      font-weight: bold;
      color: $grey-primary50;
      font-size: 16px;
      &.react-calendar__tile--active {
        background-color: $grey-primary500;
        color: $grey-primary50;
        font-weight: bold;
        border-radius: 4px;
      }
      &:disabled {
        cursor: not-allowed;
        color: $grey-primary300 !important;
        background-color: none;
      }
      &:hover {
        background-color: $grey-primary500;
        border-radius: 4px;
      }
    }
    .react-calendar__month-view__weekdays {
      border-top: 1px solid $grey-primary700;
      border-bottom: 1px solid $grey-primary700;
    }
    .react-calendar__month-view__weekdays__weekday {
      color: $grey-primary300;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize !important;
    }
    .react-calendar__month-view__weekdays__weekday abbr {
      text-decoration: none;
    }
    .react-calendar__tile--range {
      border-radius: 0px !important;
    }
    .react-calendar__tile--rangeStart {
      border-radius: 0px;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
    .react-calendar__tile--rangeEnd {
      border-radius: 0px;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    .react-calendar__navigation {
      margin: 0;
    }

    .react-calendar__navigation button:disabled {
      svg {
        path {
          stroke: $grey-primary400;
        }
      }
    }
    .react-calendar__navigation button:disabled {
      background-color: $grey-primary800;
      cursor: not-allowed;
    }
    .react-calendar__navigation button:enabled:hover {
      background-color: transparent !important;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: transparent !important;
    }
  }
}
