// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

@mixin container() {
  padding: 24px 16px;
  border-radius: 12px;
}

.analysis {
  width: 100%;
  @include flex($direction: row, $items: flex-start);
  gap: 20px;

  &_left {
    @include flex($direction: column);
    width: calc(100% - 373px);
    gap: 19px;

    &_top {
      width: 100%;
      @include flex($direction: row);
      gap: 20px;

      &_column {
        @include flex($direction: column);
        @include container();
        height: 368px; // for change
        background: $grey-primary800;
        gap: 24px;
        flex: 1;

        &_title {
          width: 100%;
          height: 44px;
          @include flex($direction: row, $justify: space-between);

          &_text {
            color: $grey-primary50;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
          }

          &_select {
            min-width: 188px;
            height: 44px;
          }
        }

        &_chart {
          width: 100%;
          height: 256px;

          canvas {
            cursor: pointer;
            width: 100% !important
          }
        }

        &_description {
          @include flex($direction: row, $justify: flex-end);
          width: 100%;

          span {
            color: $grey-primary50;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
          }
        }

        .msgEmpty {
          height: 100%;
          @include flex($items: center, $justify: center);

          p {
            color: $primary50;
          }
        }
      }
    }
  }
}

.modal {
  @include flex($direction: column);
  gap: 16px;

  .selectContainer {
    @include flex($justify: flex-end);
    width: 100%;

    .select {
      width: 188px;

      [class^='SelectField_select'] {
        margin-bottom: 0 !important;
      }
    }
  }

  .chartContainer {
    overflow-y: auto;
    width: 100%;

    .chartContainerBox {
      position: relative;
      max-height: 400px;
    }
  }
}
