@import 'variables.scss';
@import 'mixin.scss';

.inputField {
  @include flex($direction: column, $items: flex-start);
  gap: 8px;

  .wrapper {
    @include flex();
    width: 100%;
    height: 44px;
    padding: 12px;
    border: 1px solid $grey-primary100;
    border-radius: 4px;

    .input {
      width: 100%;
    }

    input {
      outline: none;
      border: none;
      color: $grey-primary600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      box-sizing: border-box;

      &:disabled {
        background-color: $grey-primary75;
        color: $grey-primary600;
      }

      &:focus:not(.focus-visible) {
        border: 1px solid $grey-primary600;
        outline: none;
        box-shadow: none;
      }

      &.endExtraTextInput {
        padding-right: 67px;
      }
    }

    input:focus {
      border: none !important;
    }

    &.darkMode {
      input {
        padding: 10px 24px 10px 10px;
        background-color: $login-input-bg;
        border: 1px solid transparent;
        color: $grey-primary50;

        &:focus:not(.focus-visible) {
          border: 1px solid $main400;
          outline: none;
          box-shadow: none;
        }

        &:disabled {
          border-color: #6f6f6f !important;
          background-color: #6f6f6f !important;
          color: #b7b7b7 !important;
          cursor: not-allowed;
        }

        &::placeholder {
          color: #b7b7b7 !important;
        }
      }
    }
  }

  .error {
    font-size: 14px;
    color: #e9081e;
    font-weight: 400;
    text-align: start;
    line-height: 18px;
  }
}
