// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminSelectRangeDownloadForm {
  margin: auto;
  @include flex();

  .formInput {
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    .inputFrame {
      padding: 28px 0 24px;

      .targetDateGroup {
        font-size: larger;
        color: $primary50;
        box-sizing: border-box;
        gap: 12px;
        padding: 1.5rem 0;
        @include flex($justify: space-evenly);

        p {
          padding: 5px;
        }
      }

      .calendarField {
        width: 240px;
        height: 44px;
        box-sizing: border-box;
        border: 1px solid $primary200;
        color: $primary600;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        padding: 12px;
        border-radius: 4px;
        @include flex($justify: space-between);
      }

      .operatorTypeFrameItemLabel {
        color: $primary600;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
      }
    }

    .submit {
      padding: 24px 32px 0;
      margin: 0 -32px;
      border-top: 1px solid $primary100;
      @include flex();
    }

    .submitButton {
      height: 44px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-weight: 700;
      flex: 1;
      &:hover,
      &:active {
        background-color: $primary500;
      }
      &:disabled {
        color: $primary200;
        cursor: not-allowed;
        background-color: $primary400 !important ;
      }
    }
  }
}
