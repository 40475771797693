// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.modalContent {
  padding: 2rem;
}

.customTitle{
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .descriptionTitle{
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: $primary600;
  }
}

.createCategoryModal {
  [class^='modal'] {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;
  }
}

.top {
  display: flex;
  justify-content: flex-end;
}
