// gkc_hash_code : 0D30ELUTRZ1J0KLGGPHD83YZTB
@import 'variables.scss';
@import 'mixin.scss';

.weightLimit {
  box-sizing: border-box;

  @include disable-select();

  [class^='modal'] {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    max-width: 416px;
    margin: auto;
    border-radius: 4px;
    height: auto;
    background-color: $background-white;
    color: $grey-primary600;
    padding: 32px;
    position: relative;
  }

  .titleContainer {
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    }
    .closeIcon {
      position: absolute;
      top: 32px;
      right: 32px;
      svg > path {
        stroke: $primary400;
      }
    }

    margin-bottom: 20px;
  }

  .contentContainer {
    margin-bottom: 24px;
    white-space: pre-line;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $grey-primary600;
  }

  .closeBtn {
    width: 240px;
    height: 44px;
    border-radius: 4px;
    padding: 12px 24px;
    background-color: $grey-primary600;

    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: $background-white;

    margin: 0;
  }
}

.darkmode {
  [class^='content'] {
    background-color: $grey-primary900;
    color: $grey-primary50;
    border-radius: 12px;
    max-width: 320px;
  }

  .titleContainer {
    margin-bottom: 16px;
  }

  .contentContainer {
    margin-bottom: 32px;
    color: $grey-primary50;
  }

  .closeBtn {
    width: auto;
    padding: 16px 32px;
    background-color: $grey-primary600;
    color: $background-white;
    margin: 0;
  }

  .closeBtn {
    height: $buttonMediumHeight;
    border-radius: 4px;
    border: none;
    background-color: $main400;
    color: $grey-primary900;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;

    &:focus {
      background-color: $main600;
    }
  }

  .closeBtn:disabled {
    border-radius: 4px;
    border: none;
    color: $main600;
    font-weight: bold;
    width: 242px;
    height: 68px;
    cursor: not-allowed;
  }
}
