// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminInvoiceTable {
  .tableList {
    table#adminInvoiceTable {
      max-width: 100%;
      max-height: calc(100vh - 264px);
      overflow: auto;
      height: calc(100vh - 335px) !important;
  
      tbody {
        overflow: auto;
        max-height: 600px;
        width: 100%;
        tr {
          &:hover {
            background: $table-hover-bg;
          }
        }

        .disabledTr {
          pointer-events: none;
          opacity: 0.4;
        }
        
        td {
          padding: 10px !important;
  
          p {
            word-break: break-all;
            text-align: left;
            line-height: 20px;
            @include ellipseText(2);
          }
        }

        .siteName {
          width: 60%;
        }
        .status {
          width: 20%;
        }
      }
      thead > tr > th {
        padding: 10px !important;
        font-size: 14px;
        line-height: 20px;
      }
  
      .status {
        width: 20%;
        p {
          padding: 4px 8px;
          border-radius: 2px;
          background-color: $primary100;
          color: $primary600;
          font-weight: 700;
          text-align: center;
          width: fit-content;
        }
      }

      .siteName {
        width: 60%;
        span {
          padding: 4px 8px;
          border-radius: 2px;
          background-color: $primary400;
          color: $primary50;
          font-weight: 700;
          text-align: center;
          width: fit-content;
        }
        p {
          margin-top: 4px;
        }
      }
      tr {
        font-size: 14px;
        line-height: 20px;
        min-width: 1200px;
      }
    
      td {
        vertical-align: middle;
        cursor: pointer;
        border-top: 1px solid $borderSecond-color;
        border-bottom: 1px solid $borderSecond-color;
        box-shadow: 0px 1px 0px $borderSecond-color;
      }
  
      .msgError { 
        text-align: center;
      }
    }
  }
  .submit {
    padding: 1.5rem 2rem 0;
    border-top: 1px solid $primary100;
    text-align: center;
    .submitButton {
      height: 44px;
      width: 240px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: bold;
  
      &:hover, &:active {
        background-color: $primary500;
      }
      &:disabled {
        cursor: not-allowed;
        color: $primary200;
        background-color: $primary400 !important;
      }
    }
  }
}

table#adminInvoiceTable::-webkit-scrollbar {
  width: 5px;
}
table#adminInvoiceTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}
