// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.areaName {
  font-size: 1.2em;
  font-weight: bold;
}

.unCollectPage {
  min-height: calc(100vh - #{$headerHeight});
  background-color: $grey-primary900;
}

.unCollectHead {
  box-sizing: border-box;
  padding: 12px 32px;
  @include flex($items: center, $justify: space-between);

  @include breakpoint-down('md') {
    padding: 6px 1rem;
  }

  .countList {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: $grey-primary50;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }

    span {
      font-weight: 700;
    }
  }

  .totalImport {
    gap: 54px;
    @include flex($items: center, $justify: space-between);

    @include breakpoint-down('md') {
      gap: 16px;
    }

    .total {
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 400;
      color: $grey-primary50;

      &.center {
        text-align: center;
        display: block;
      }

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }

      span {
        font-weight: 700;
      }
    }

    .btnFileImportIcon {
      height: $buttonSmallHeight;
      box-sizing: border-box;
      background-color: $primary600;
      border: 1px solid $primary500;
      font-size: 0.875rem;
      font-weight: 700;
      color: $grey-primary50;
      gap: 0.5rem;
      cursor: pointer;
      padding: 12px 24px;
      border-radius: 4px;

      @include flex($items: center, $justify: space-between);

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
        min-width: 155px;
        max-width: 150px;
        padding: 12px;
      }

      .iconFileImportIcon {
        svg > path {
          stroke: $primary50;
        }
      }
    }
  }
}

[class^='bottom-field'] {
  height: $bottomSmallHeight;

  @include breakpoint-down('md') {
    height: 90px;

    [class^='customPagination'] li a {
      padding: 6px 14px;
    }
  }
}

.bottom {
  padding: 0 32px;
  @include flex($justify: space-between);
  flex-direction: row-reverse;
  height: 100%;

  @include breakpoint-down('md') {
    padding: 2px 32px 0;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .bottomBtn {
    padding: 16px;
    gap: 16px;
    @include flex($items: center, $justify: center);

    @include breakpoint-down('md') {
      padding: 8px 0;
    }

    button {
      padding: 16px 40px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      border: 1px solid $grey-primary50;
      height: $buttonMediumHeight;
      box-sizing: border-box;

      @include breakpoint-down('md') {
        padding: 14px;
        width: 100%;
        height: $buttonTinyHeight;
        line-height: 10px;
      }
    }

    .btnEditing {
      color: $grey-primary50;
      background-color: $grey-primary900;
      border: 1px solid $grey-primary50;
      border-radius: 4px;

      &:disabled {
        cursor: not-allowed;
        color: $grey-primary300;
        border-color: $grey-primary300;
      }

      &:focus {
        border: 1px solid $grey-primary600;
      }

      @include breakpoint-down('md') {
        min-width:  112px;
      }
    }

    .btnRequest {
      color: $grey-primary900;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      min-width: 172px;

      &:disabled {
        cursor: not-allowed;
        background-color: $main400;
        color: $main600;
        border: none;
      }

      &:focus {
        background-color: $main600;
      }
    }
  }
}
