// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.PoolDashboard {
  text-align: unset;
  padding: 32px;

  .ChartMapZone {
    height: 816px;
    display: flex;
    gap: 16px;
    margin: 16px 0;
  }

  .Map {
    width: calc((100% - 16px) / 2);
  }

  .Charts {
    width: calc((100% - 16px) / 2);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .DonutCharts {
    height: calc((100% - 16px) / 2);
    overflow: hidden;
  }

  .LineChart {
    flex: 1;
  }
}
