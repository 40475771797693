// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0 12px;
  > * {
    margin: 12px 0;
  }
  .passwordEys {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.submit {
  padding: 30px 30px 0;
  margin: 0 -32px;
  border-top: 1px solid $primary100;
  @include flex();
}

.submitButton {
  height: 44px;
  width: 240px;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: $primary50;
  font-weight: 700;
  flex: 1;
  &:hover,
  &:active {
    background-color: $primary500;
  }
}

.disableButton {
  border-radius: 4px;
  border: none;
  color: $primary200;
  font-weight: 700;
  height: 44px;
  width: 100%;
  cursor: not-allowed;
  background-color: $primary400 !important ;
}
.headingImages {
  text-align: left;
  font-weight: 700;
  margin-top: 16px;
  font-size: 14px;
}
