// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

@mixin text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

@mixin button {
  width: 100%;
  height: 88px;
  margin: 0;
  padding: 20px 40px;
  border-radius: 8px;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;

  @include breakpoint-down('md') {
    width: 100%;
    padding: 10px;
    line-height: 18px;
  }
}

.recycleComplete {
  .regist {
    padding: 0 32px;
  }

  .scrollContents {
    max-height: calc(
      var(--vh) - #{$headerHeight + $tabHeight} - #{$bottomSmallHeight}
    );
    color: $grey-primary50 !important;

    @include flex($items: flex-start, $direction: column);
  }

  .weightContent {
    width: 100%;
    height: calc(100vh - 116px);

    @include flex();

    .weightContainer {
      width: 640px;

      display: flex;
      flex-direction: column;
      gap: 24px;

      .titleContainer {
        .title {
          text-align: left;
          font-size: 36px;
          line-height: 48px;
          font-weight: 700;
          color: $grey-primary50;
        }

        .overload {
          font-size: 24px;
          font-weight: 400;
          line-height: 40px;
          color: $grey-primary50;
          text-align: left;
        }
      }

      @include mq('xs') {
        padding: 8px;
      }

      .field {
        padding: 24px 108px 24px 48px;
        border-radius: 12px;
        background: $login-input-bg;
        position: relative;

        .input {
          width: 100%;

          font-size: 128px;
          font-weight: 900;
          line-height: 160px;
          letter-spacing: 0em;

          color: $grey-primary50;
          border: none;
          border-radius: inherit;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
        .unit {
          position: absolute;
          right: 48px;
          bottom: 24px;

          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          letter-spacing: 0em;
          text-align: left;
          color: $background-white;
        }
      }

      .bottom {
        .buttons {
          @include flex();
          gap: 24px;

          .back {
            @include button();

            color: $grey-primary50;
            background: transparent;
            border: 1px solid $grey-primary50;
          }

          .submit {
            @include button();

            color: $grey-primary900;
            background: $main400;
            border: 1px solid $main400;

            &:disabled {
              color: $main600;
              background-color: $main400;
              cursor: not-allowed;
              border: none;
            }

            &:focus {
              background-color: $main600;
            }
          }
        }
      }
    }
  }

  [class^='bottom-field'] {
    left: 0;
    box-sizing: border-box;
  }

  .bottomBtn {
    margin: 16px auto;
    @include flex($items: center, $justify: center);
    @include breakpoint-down('md') {
      margin: 16px;
    }
    gap: 16px;

    button {
      min-width: 168px;
      padding: 16px 32px;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      height: $buttonMediumHeight;
      box-sizing: border-box;
      border-radius: 4px;

      @include breakpoint-down('md') {
        width: 100%;
        padding: 10px;
        line-height: 18px;
      }
    }

    .btnRequest {
      color: $grey-primary900;
      background-color: $main400;
      margin: 0;
      border: none;

      &:focus {
        background-color: $main600;
      }

      &:disabled {
        color: $main600;
        cursor: not-allowed;
      }
    }

    .btnBack {
      color: $grey-primary50;
      background: transparent;
      border: 1px solid $grey-primary50;
    }
  }

  [class^='preRegistResourceSelect'] {
    min-height: auto;
  }

  [class^='RecycleProductRegistration_recycleProductRegistration'] {
    min-height: auto;
  }

  [class^='primary-table'] {
    margin-bottom: 0 !important;
    max-height: calc(100vh - 224px) !important;
  }
}
