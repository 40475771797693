// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.primaryTable {
  max-height: 390px;
  background-color: $grey-primary900 !important;
  margin-bottom: 16px !important;
  color: $grey-primary50;
  width: 100%;
  border: 1px solid $grey-primary700;
  border-radius: 4px;
  border-bottom: none;

  thead {
    box-shadow: 0px 1px 0px #36383c;
    background-color: $grey-primary800 !important;
  }

  th {
    font-size: 12px !important;
    color: $grey-primary100;
    border-left: none !important;
    border-right: 1px solid $grey-primary700 !important;
    border-bottom: 1px solid $grey-primary700 !important;

    @include breakpoint-down('md') {
      font-size: 14px !important;
      line-height: 20px;
    }
  }

  td {
    border: 1px solid $grey-primary700 !important;
    border-left: none !important;
    border-top: none !important;
    height: 0 !important;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.left {
  text-align: left;
}

.checkBox {
  width: 8%;
  vertical-align: middle;
  padding: 12px 16px;
  cursor: pointer;

  input:checked + label::before {
    background-color: #86EA5C !important;
  }
}

.label {
  width: 19%;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.type {
  width: 13%;
  font-size: 14px;
  text-align: left;
  line-height: 24px;
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.content {
  width: 20%;
  line-height: 24px;
  font-size: 14px;
  text-align: left;
  padding: 8px 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.weight {
  width: 10%;
  line-height: 24px;
  font-size: 14px;
  text-align: right;
  padding: 8px 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

[class^='customCheckBox'] {
  input:checked + label::before {
    background-color: #86EA5C !important;
  }
}

