// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.productRegistration {
  .table {
    margin-top: 24px;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    border: 1px solid $grey-primary700;
    border-radius: 4px;

    @include breakpoint-down('md') {
      max-height: calc(100vh - #{$headerHeight} - 56px - 24px - 48px);
    }

    #tareWeightSubtractionTable {
      width: 100%;
      border-radius: 4px;
      border-collapse: collapse;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }

      tr {
        border-top: 1px solid $grey-primary700;
      }

      th {
        font-size: 12px !important;
        color: $grey-primary100;
        min-width: 106px;
      }

      th,
      td {
        text-align: left;
        border-right: 1px solid $grey-primary700;
        vertical-align: middle;
      }

      thead {
        position: sticky;
        top: -2px;
        z-index: 10;
        background-color: $text-one !important;
        color: $grey-primary100;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        th {
          padding: 16px !important;
          border-left: none !important;
          border-right: 1px solid $grey-primary700 !important;
          border-bottom: 1px solid $grey-primary700 !important;
        }

        th.actionField {
          width: 5%;
          min-width: 60px;
        }
      }

      tbody {
        color: $grey-primary50;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        tr {
          padding: 0 20px !important;

          .msgError {
            text-align: center;
            line-height: 26px;
            padding: 16px;
            font-size: 0.9em;
          }
        }
      }

      .date {
        width: 10%;
        min-width: 120px;

        p {
          word-break: break-all;
          padding: 12px 16px;
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .item {
        width: 10%;

        p {
          word-break: break-all;
          padding: 12px 16px;
          display: inline-block;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .actionField {
        width: 10%;
        min-width: 120px;
      }

      .actionField1 {
        width: 5%;
        min-width: 60px;
        padding: 0px !important;

        .contentAction {
          @include flex();

          svg {
            cursor: pointer;
          }

          .disabledBtn {
            cursor: not-allowed;

            svg {
              cursor: not-allowed;

              path {
                stroke: $grey-primary600 !important;
              }
            }
          }

          .actionEdit {
            padding: 16px;
            text-align: center;

            svg > path {
              stroke: $main400;
            }
          }
        }
      }
    }
  }
}
