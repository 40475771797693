// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionInput {
  min-height: calc(100vh - #{$headerHeight});
  background-color: $grey-primary900;

  .formInput {
    width: 70%;
    margin: 0 auto;
    padding-top: 100px;

    @include breakpoint-down('md') {
      width: calc(100% - 32px);
    }
  }
}

.emissionBox {
  width: 200px;
  margin: auto;

  .emissionImage {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 12px;
    object-fit: cover;
  }
}

.commonInfo {
  width: 280px;
  margin: auto;
  margin-top: 1.5rem;
  @include flex($justify: center);

  .info {
    .labelBox {
      gap: 12px;
      @include flex();

      .label {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 700;
        color: $grey-primary50;
        @include ellipseText();

        @include breakpoint-down('md') {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .type {
        min-width: 32px;
        box-sizing: border-box;

        @include breakpoint-down('md') {
          min-width: auto;
          padding: 0;
        }

        .itemType {
          background-color: $main500;
          border-radius: 4px;
          padding: 4px;

          svg {
            path {
              fill: $black;
            }
          }
        }
      }
    }

    .weightFix {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: $grey-primary50;
      margin-top: 0.25rem;
    }
  }
}

.formInput {
  margin-bottom: 50px;
}

.weightField {
  position: relative;
  width: 280px;
  margin-top: 1.5rem;

  [class^='InputDataListField'] {
    input {
      text-align: center;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      padding: 12px 16px;
      height: 48px;
      box-sizing: border-box;
      color: $grey-primary50;
      background-color: $login-input-bg;
      border: 1px solid transparent;
      border-radius: 4px;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .weightUnit {
    position: absolute;
    top: 12px;
    right: 12px;
    color: $grey-primary300;
    line-height: 1.5rem;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.weight {
  @include flex($justify: center);
}

.emissionTare {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  gap: 1rem;
  flex-wrap: wrap;
  @include flex();

  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }

  span {
    font-weight: 400;
    padding: 12px 24px;
    color: $grey-primary900;
    background: $grey-primary50;
    border-radius: 4px;
    border: 1px solid $grey-primary50;
    cursor: pointer;

    @include breakpoint-down('md') {
      padding: 12px 16px;
    }
  }

  .emissionSelected {
    text-shadow: 0.5px 0 currentColor;
    color: $grey-primary900;
    border: 1px solid $main100;
    background-color: $main100;
  }
}

.submitButton {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  height: $buttonMediumHeight;
  padding: 16px 59px;
  margin: 2rem 0;
  background-color: $main400;
  color: $grey-primary900;
  border-radius: 4px;
  border: none;

  &:focus {
    background-color: $main600;
  }

  @include breakpoint-down('md') {
    width: 280px;
  }
}

.disableButton {
  cursor: not-allowed;
  color: $main600;
  background-color: $main400;
}
