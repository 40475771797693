// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.downloadModal {
  &.open {
    display: block;
  }

  &.close {
    display: none;
  }

  [class^='modal'] {
    overflow: hidden;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    @include flex();
  }

  [class^='contents'] {
    height: calc(100vh - 48px);
    width: 628px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    position: relative;
    @include breakpoint-down('md') {
      width: 382px;
    }

    .modalContent {
      height: $headerModalHeight;
      box-sizing: border-box;
      padding: 1.5rem 1rem 1rem;

      .customTitle {
        @include flex($justify: flex-start);
        @include breakpoint-down('md') {
          @include flex($justify: space-between);
        }

        .descriptionTitle {
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 700;
          color: $grey-primary50;
          margin: auto;
          @include breakpoint-down('md') {
            margin: 0;
          }
        }

        .top {
          @include flex($justify: flex-end);
        }
      }
    }

    .body {
      height: calc(100% - 164px);
      border-top: solid 1px $grey-primary700;
      form {
        height: 100%;
      }
      .bodyContent {
        height: 100%;
        overflow: auto;
      }

      [class^='modal-bottom-field'] {
        height: $bottomModalHeight;
        width: 100%;
        padding: 1rem 1rem 1.5rem 1rem;
        box-sizing: border-box;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        box-shadow: 0 0 1px $grey-primary700;
        .submitButton {
          padding: 0;
          @include breakpoint-down('md') {
            width: 100%;
          }

          button {
            font-size: 1rem;
            line-height: 1.5rem;
            font-weight: 700;
            height: $buttonMediumHeight;
            background-color: $main400;
            color: $grey-primary900;
            border-radius: 4px;
            border: none;
            box-sizing: border-box;
            margin: 0;

            &:focus {
              background-color: $main600;
            }

            &:disabled {
              color: $main600;
              cursor: not-allowed;
              background-color: $main400 !important ;
            }
          }
        }
      }
    }
  }
}
