// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';
.tag {
  margin-right: 8px;
  @include flex();
  font-size: 12px;
  font-weight: 400;
  background-color: $primary100;
  padding: 4px 8px;
  border-radius: 4px;
  width: fit-content;
  span {
    white-space: pre;
    margin-right: 4px;
    line-height: 18px;
  }
}

.tagIcon {
  cursor: pointer;
  // margin-top: 3px;
  @include flex();
}
