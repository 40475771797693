@import 'variables.scss';

.container {
  background-color: $grey-primary900;
  min-height: calc(var(--vh) - #{$headerHeight});

  [class^='scroll-contents'] {
    max-height: calc(
      var(--vh) - #{$headerHeight}
    );
  } 
}
