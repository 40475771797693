// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.purchaseRegisResource {
  max-height: calc(var(--vh) - #{$purchaseHeaderHeight + 68px});

  .purchasePreRegister {
    .purchasePreRegisterForm {
      .inputFrame {
        flex: 1 4 0%;

        > * {
          padding-top: 16px;
        }

        .textFrame {
          gap: 16px;
          flex-wrap: wrap;
          @include flex($items: flex-start, $justify: flex-start);

          & > div {
            width: calc(25% - 6px);
          }

          [name^='siteId'] {
            background-color: $login-input-bg;
            border: 1px solid transparent;
            color: $grey-primary50;
          }
        }
      }
    }

    .preRegist {
      [class^='primary-table'] {
        background-color: $bgPurchase !important;
        max-height: calc(
          var(--vh) - #{$tabHeight + $headerHeight} - 68px - 48px - 80px - #{$bottomSmallHeight}
        );
      }

      [class^='bottom-field'] {
        width: calc(100% - 240px - 40px);
        box-sizing: border-box;
        margin-left: -16px;
      }
    }
  }
}
