// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.label-contents {
  display: flex;
  padding: 5px 10px;
}

div.label-contents {
  text-align: left;
  @include flex($items: top, $justify: top);
}

div.label-contents > div {
  line-height: 20px;
  word-break: break-all;
}

.label-contents > div:first-child {
  width: 30%;
}

.label-contents > div:last-child {
  width: 70%;
}
