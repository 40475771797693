// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';
.weight-notice-modal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }
  box-sizing: border-box;

  [class^='content'] {
    width: 80%;
    margin: auto;
    border-radius: 4px;
    height: auto;
    background-color: $grey-primary900;
    color: $grey-primary50;
  }

  .customTitle {
    padding: 32px 32px 16px;
    box-shadow: 0px 1px 0px $borderGrey;
    @include flex($justify: space-between);

    .title {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .notice-form {
    padding: 0 32px;
    text-align: left;
    font-size: 16px;
    box-sizing: border-box;

    .notice-info {
      width: 100%;
      text-align: left;

      .notice-info-row {
        padding: 5px 0;
        font-size: 14px;
        line-height: 20px;
        @include flex($items: left, $justify: center);
      }

      .notice-info-label {
        width: 20%;
      }
      .notice-info-contents {
        width: 80%;
      }
    }
    .notice-input {
      textarea {
        width: 100%;
        height: 100px;
        background-color: $login-input-bg;
        color: $grey-primary50;
        border-radius: 4px;
        caret-color: $grey-primary300;
        resize: none;
        font-size: 16px;
        line-height: 24px;
        padding: 10px;
        box-sizing: border-box;
        margin-top: 8px;
        border: 1px solid transparent;

        &:focus:not(.focus-visible) {
          border: 1px solid $main400;
          outline: none;
          box-shadow: none;
        }
      }

      .selectBox {
        margin-bottom: 16px;

        .selectLabel {
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .submit {
    margin-top: 4px;
    padding: 24px 16px 32px 16px;
    @include flex();
    .submitButton {
      width: 168px;
      height: $buttonMediumHeight;
      border-radius: 4px;
      border: none;
      background-color: $main400;
      color: $grey-primary900;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }
    }

    .submitButton:disabled {
      border-radius: 4px;
      border: none;
      color: $main600;
      font-weight: bold;
      width: 242px;
      height: 68px;
      cursor: not-allowed;
    }
  }
}
