// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.treeSelectWrapper {
  width: 100%;
  position: relative;
}

.treeSelectContainer {
  height: $selectHeight;
  background-color: $grey-primary600;
  padding: 10px 24px 10px 10px;
  border-radius: 4px;
  display: flex;
  overflow-x: auto;
  width: 100%;
  box-sizing: border-box;
  color: black;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }
}

.containerDropdownIcon {
  position: absolute;
  right: 0;
  background: $grey-primary600;
  height: -webkit-fill-available;
  top: 0;
  width: 30px;
  border-radius: 0 4px 0;

  svg {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
  }
}

.treeSelectInput {
  min-width: 250px;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  border: none;
  outline: none;
  color: white !important;
}

.treeOptionWrapper {
  border: 1px solid $grey-primary600;
  margin-top: 8px;
  border-radius: 4px;
  position: absolute;
  width: 100%;
  z-index: 10000;
  background-color: $grey-primary800;
  max-height: 464px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }
}

.optionItem {
  padding: 18px 16px;
  border: 1px solid $grey-primary600;
  cursor: pointer;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: white;
  background-color: $grey-primary800;

  &:hover {
    background-color: $grey-primary600;
  }
}

.dropdownIcon {
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownIconReverse {
  transform: rotate(-180deg);
}

.emptyListOption {
  padding: 16px;
}
