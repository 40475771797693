// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.StackedBarChart {
  padding: 14px 16px;
  background-color: #2c2c2c;
  border-radius: 12px;
  text-align: left;

  .TitleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 72px;

    @include breakpoint-down('md') {
      flex-wrap: wrap;
      gap: 16px;
    }

    & > p {
      font-size: 16;
      font-weight: 700;
      color: #e7e7e7;
    }
  }

  .Actions {
    display: flex;
    gap: 72px;
    align-items: center;

    @include breakpoint-down('md') {
      justify-content: space-between;
      width: 100%;
      gap: 16px;
    }

    button {
      margin: 0;
    }

    .DownloadButton {
      border: 1px solid #4d4d4d;
      background-color: transparent;
      border-radius: 4px;

      &:disabled {
        background-color: #9ea0a2;
        cursor: not-allowed;
      }
    }
  }

  .Chart {
    display: flex;
    align-items: center;
    justify-content: center;

    .Empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 16px;
      color: #e7e7e7;
    }
  }

  .ChartWrapper {
    margin-top: 20px;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    width: 100%;

    @include breakpoint-down('md') {
      flex-direction: column;
    }
  }

  .ChartWrapperDownload {
    flex-direction: column;
  }

  .ChartBox {
    width: calc(82% - 32px);

    @include breakpoint-down('md') {
      width: 100%;
    }
  }

  .ChartBoxDownload {
    width: 100%;
  }

  .Legends {
    width: 18%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: auto;
    @include customScroll();

    .Legend {
      display: flex;
      gap: 8px;
      color: #e7e7e7;
      font-size: 12px;
      line-height: 16px;
      word-break: break-word;
      align-items: flex-start;

      > div {
        margin-top: 2px;
      }

      .textLeft {
        text-align: left;
      }
    }
  }

  .LegendsMobile {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .Legend {
      white-space: pre-line;
    }
  }

  .LegendsDownload {
    display: grid;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row dense;
    grid-gap: 12px;
    max-width: 940px;
    width: 100%;

    .Legend > div {
      margin-top: 3px;
    }

    .Legend1 {
      grid-column-start: 1;
      flex-direction: row-reverse;
      text-align: right;
    }

    .Legend2 {
      grid-column-start: 2;
    }
  }

  .LegendsGrid {
    height: auto;
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    @include flex($direction: row, $items: flex-start);
    justify-content: flex-start;

    .Legend {
      width: calc(90% / 4);
      white-space: pre-line;
      word-break: break-word;
    }
  }
}

.TimeStackChartModalWrapper {
  overflow-y: hidden;
  display: flex;

  [class^='contents'] {
    max-width: 1600px;
    margin: auto;
  }
}

.TimeStackChartModal {
  background-color: $grey-primary900;
  margin: auto;
  max-height: 86vh;
  overflow: auto;
  @include customScroll();

  .ModalTitle {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 16px 32px;
    border-bottom: 1px solid $grey-primary700;
    margin-bottom: 16px;
    gap: 16px;

    & > p {
      color: $grey-primary50;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .ModalBody {
    padding: 8px 32px 48px;

    @include breakpoint-down('md') {
      padding: 8px 16px 16px;
    }
  }
}
