// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminConfirmInvoiceContent {
  .operatorName {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
    color: $primary600;
    text-align: left;
  }

  .siteGroup {
    @include flex($items: center, $justify: space-between);
    padding: 20px 0 8px;
    box-shadow: 0px 1px 0px $primary300;

    .siteName {
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      color: $primary600;
    }

    .editButton {
      button {
        height: 32px;
        background-color: $primary400;
        border-radius: 4px;
        border: none;
        color: $primary50;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 700;
        padding: 8px 18px;
        box-sizing: border-box;

        &:hover,
        &:active {
          background-color: $primary500;
        }
      }
    }
  }

  .table {
    .row {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $primary600;
      padding: 24px 0 20px;
      box-shadow: 0px 1px 0px $primary100;
      text-align: left;
      @include flex($items: center);

      .title {
        width: 160px;
        font-weight: 700;
      }
      .content {
        width: calc(100% - 160px);
        font-weight: 400;
        white-space: pre-wrap;
        a {
          color: $mariner;
          text-decoration: underline;
        }
        .info {
          margin-top: 1rem;

          .text {
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 400;
            color: $primary600;

            a {
              color: $mariner;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .previewPDF {
    margin: 32px auto;
    height: 750px;
  }

  .paging {
    padding: 24px 0;
  }

  .bottomButton {
    gap: 1.5rem;

    @include flex();

    .btnExport {
      height: $buttonSmallHeight;
      width: 160px;
      padding: 12px;
      box-sizing: border-box;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      border-radius: 4px;
      border: 1px solid $primary400;
      background-color: $primary50;
      color: $primary600;
    }

    .btnSend {
      height: $buttonSmallHeight;
      width: 160px;
      padding: 12px;
      box-sizing: border-box;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 700;
      border-radius: 4px;
      border: none;
      background-color: $primary400;
      color: $primary50;
      &:disabled {
        color: $primary200;
        cursor: not-allowed;
        background-color: $primary400 !important ;
      }
    }
  }
}
