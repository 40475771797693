// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyEmissions {
  background-color: $grey-primary900;

  .selectForm {
    border-bottom: solid 1px $primary500;

    .labelSelect {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: $grey-primary50;
      border: none;
      box-shadow: 0px 1px 0px $primary500;
      padding: 12px 32px;

      @include flex($justify: left, $items: center);
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
        padding: 8px 16px;
      }

      .label {
        width: 30%;
      }
    }
  }

  .siteName {
    font-weight: 400 !important;
    word-break: break-all;
  }
}
