// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.confirmProductRegistrationModal {
  position: relative;

  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }

  [class^='modal'] {
    @include flex($items: center, $justify: center);
  }

  [class^='content'] {
    width: 592px;
    margin: auto;
    background-color: $grey-primary900;
    border-radius: 4px;
    @include breakpoint-down('md') {
      width: calc(100% - 32px);
    }
  }

  .modalContent {
    .top {
      position: relative;
      padding: 2rem 2rem 1rem 2rem;
      box-shadow: 0px 1px 0px $primary600;
      @include flex($items: center, $justify: space-between);
      @include breakpoint-down('md') {
        padding: 24px 60px 16px 16px;
      }

      .title {
        font-size: 24px;
        line-height: 36px;
        font-weight: 700;
        color: $grey-primary50;
        text-align: left;
      }

      .closeIcon {
        position: absolute;
        right: 32px;
      }
    }

    .body {
      .alertContent {
        background-color: $grey-primary800;
        border-radius: 4px;
        padding: 16px;
        text-align: left;
        margin: 16px 32px 10px;

        .title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: $function400;
        }

        .content {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $function400;
          margin-top: 6px;
        }
      }

      .confirmInfo {
        width: 100%;
        margin: 0 auto;
        text-align: left;
        @include flex($items: center, $direction: column);

        .confirmInfoRow {
          width: calc(100% - 4rem);
          padding: 6px 0;
          font-size: 20px;
          line-height: 32px;
          color: $grey-primary50;
          @include flex($items: left, $justify: center);

          .confirmInfoLabel {
            width: 30%;
            text-align: left;
            padding-right: 10px;
            font-weight: 400;
          }

          .confirmInfoContents {
            width: 70%;
            text-align: left;
            font-weight: 700;
            @include truncate-oneline();
          }
        }
      }
    }

    [class^='modal-bottom-field'] {
      padding: 1.5rem 2rem 2rem;
      width: 100%;
      margin: auto;
      box-sizing: border-box;
      gap: 16px;

      @include breakpoint-down('md') {
        width: auto;
        padding: 16px 16px 24px;
      }

      .submitButton,
      .continueButton {
        padding: 0;
        width: 50%;

        button {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          height: $buttonMediumHeight;
          padding: 4px;
          margin: 0;
          background-color: $main400;
          color: $grey-primary900;
          border-radius: 4px;
          border: none;
          box-sizing: border-box;

          &:focus {
            background-color: $main600;
          }

          @include breakpoint-down('md') {
            padding: 8px;
          }
        }
      }

      .continueButton button {
        color: $grey-primary50;
        background-color: $grey-primary900;
        border-radius: 4px;
        border: 1px solid $grey-primary50;

        &:focus {
          background-color: $grey-primary900;
        }
      }
    }
  }
}
