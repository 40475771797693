// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.collectComplete {
  max-height: calc(var(--vh) - #{$headerHeight + $tabHeight});

  [class^='scroll-contents'] {
    max-height: calc(
      var(--vh) - #{$headerHeight + $tabHeight} - #{$bottomSmallHeight}
    );
  }

  .collectCompleteCard {
    display: flex;
    flex-direction: row;
    padding: 12px 32px;
    border-bottom: solid 1px $primary600;
    color: $grey-primary50;
    @include breakpoint-down('md') {
      padding: 12px 16px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .collectCompleteContentCol {
    flex: 1;
  }

  .collectCompleteContent {
    display: flex;
  }

  .collectCompleteLabel {
    width: 30%;
    margin-bottom: 8px;
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    min-height: 20px;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  [class^='bottom-field'] {
    height: $bottomSmallHeight;
    box-sizing: border-box;
    @include flex();

    [class^='bottom-btn-field'] {
      @include breakpoint-down('md') {
        width: 100%;
        margin: 24px 16px;
      }
    }
  }

  .collectCompleteActionCol {
    width: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .actionDelete {
      cursor: pointer;
      svg {
        width: 32px;
        height: 36px;
      }

      svg > path {
        stroke: $function300;
      }
    }
  }
}

.bottomBtn {
  padding: 16px;
  gap: 16px;
  @include flex($items: center, $justify: center);

  button {
    padding: 16px 40px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    border: 1px solid $grey-primary50;
    height: $buttonMediumHeight;
    box-sizing: border-box;

    @include breakpoint-down('md') {
      padding: 14px;
      width: 100%;
    }
  }

  .btnEditing {
    height: $buttonMediumHeight;
    color: $grey-primary50;
    background-color: $grey-primary900;
    border: 1px solid $grey-primary50;
    border-radius: 4px;

    &:focus {
      border: 1px solid $grey-primary600;
    }
  }

  .btnRequest {
    height: $buttonMediumHeight;
    color: $grey-primary900;
    background-color: $main400;
    border-radius: 4px;
    border: none;
    min-width: 172px;

    &:disabled {
      cursor: not-allowed;
      background-color: $main400;
      color: $main600;
      border: none;
    }

    &:focus {
      background-color: $main600;
    }
  }
}
