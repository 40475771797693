// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customModal {
  [class^='modal'] {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  [class^='contents'] {
    width: 480px;
    height: auto;
    border-radius: 4px;
  }

  .deleteItemModal {
    padding: 32px;

    .customIcon {
      position: relative;
      padding: 12px;
    }

    .top {
      position: absolute;
      top: 0;
      right: 0;
    }

    .customTitle {
      margin-top: 24px;
      color: $primary600;

      .title {
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
      }

      .msg {
        @include ellipseText(2);
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
      }
      .msgDeleteOperator {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        white-space: pre-wrap;
        word-break: break-all;
        text-align: left;
        padding: 0 24px;
      }
    }

    .iconDeleteItem {
      background-color: $primary100;
      width: 72px;
      height: 72px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;

      svg {
        width: 32px;
        height: 36px;
      }
    }

    .submit {
      margin-top: 20px;

      .submitButton {
        height: 44px;
        min-width: 240px;
        padding: 12px 16px;
        background-color: $primary400;
        border-radius: 4px;
        border: none;
        color: $primary50;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;

        &:hover,
        &:active {
          background-color: $primary500;
        }
      }
    }
  }
}
