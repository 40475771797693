// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.purchaseDashboardContainer {
  background-color: $grey-primary900;
  height: 100%;
  overflow: auto;

  .totalWeightOfEachProcessFilter {
    padding: 20px;
  }

  .generalsProcessInfo {
    box-sizing: border-box;
    padding: 0 20px 20px;
    width: 100%;
    @include flex();
    @include breakpoint-down('lg') {
      flex-wrap: wrap;
    }
  }

  .topChart {
    padding: 0 20px 20px;

    .donutChart {
      @include flex();

      .donutContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 20px;
        width: 100%;
      }
    }
  }
}
