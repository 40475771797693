// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.modalContent {
  padding: 2rem;
}

.customTitle{
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .descriptionTitle{
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: $primary600;
  }
}

.top {
  display: flex;
  justify-content: flex-end;
}
