// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tenantStatistics {
  .FilterForm {
    gap: 8px;
    @include flex($items: flex-end, $justify: flex-start);

    button {
      margin: 0;
      width: 76px;
      height: 44px;
      color: $grey-primary900;
      font-weight: 700;
      background-color: $grey-primary50;
      border: none;
      border-radius: 4px;
      font-size: 14px;
    }

    [class^='SelectField'] {
      margin-bottom: 0;
      width: 92px;

      > div {
        color: $grey-primary200;
      }
    }
  }

  .DateRangePicker {
    height: 44px;
    width: 214px;
  }

  .DonutChartsWrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 16px;

    @include breakpoint-down('md') {
      flex-direction: column;
    }

    .DonutChartItem {
      flex: 1;
      display: flex;

      [class^='DonutChart_donut'] {
        flex: 1;
      }
    }
  }

  .BarChartZone {
    margin-top: 16px;
  }

  .contentBox {
    gap: 16px;
    @include flex($items: flex-end);
    @include breakpoint-down('md') {
      @include flex($direction: column);
    }

    .costWrapper {
      width: calc((100% - 16px) / 3);

      @include breakpoint-down('md') {
        width: 100%;
      }

      .costContent {
        min-height: 148px;
        padding: 16px;
        margin-top: 16px;
        background-color: $grey-primary800;
        border-radius: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &.costStyles {
          cursor: pointer;
        }

        .title {
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: $grey-primary50;
        }

        .totalCost {
          font-size: 36px;
          font-weight: 700;
          color: $main400;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .content {
      margin-top: 16px;
      min-height: 148px;
      height: auto;
      background-color: $grey-primary800;
      border-radius: 12px;
      padding: 16px;
      @include flex();

      p {
        color: $grey-primary50;
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        word-break: break-word;
        white-space: pre-line;
        text-align: center;

        &.download {
          font-size: 18px;
        }

        @include breakpoint-down('md') {
          font-size: 12px;
          line-height: 20px;
        }

        .rateRed,
        .overallRankLow {
          color: $function400;
        }

        .textGreen,
        .rateGreen,
        .itemNameStyle,
        .overallRankHigh {
          color: $main400;
        }

        .overallRankMedium {
          color: $grey-primary50;
        }

        .textGreen,
        .rateRed,
        .overallRankLow,
        .rateGreen,
        .overallRankHigh,
        .overallRankMedium {
          font-size: 28px;
          line-height: 32px;

          &.download {
            font-size: 22px;
          }

          @include breakpoint-down('md') {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    [class^='TabExtends_tabExtends'] {
      width: 67%;
      @include breakpoint-down('md') {
        width: 100%;
      }

      [class*='tabMenu'] {
        background-color: transparent;
        border-bottom: 1px solid $grey-primary600;

        @include flex($justify: space-between);

        @include breakpoint-down('md') {
          border-bottom: none;
          height: auto;
          gap: 4px;

          @include flex($items: flex-start, $direction: column-reverse);
        }

        [class*='tabItems'] {
          @include breakpoint-down('md') {
            width: 100%;
            border-bottom: 1px solid $grey-primary600;
          }

          [class*='tabItem'] {
            font-size: 18px;
            line-height: 28px;
            width: auto;
            border-right: none;
            color: $grey-primary50;
            font-weight: 400;

            @include breakpoint-down('md') {
              font-size: 16px;
              line-height: 24px;
              height: auto;
            }
          }

          [class*='tabItemActive'] {
            color: $main400;
            font-weight: 700;
            border-bottom: 2px solid $main400;
          }
        }
      }
    }
  }

  [class^='ExtendableDonutChart_DonutExtendedModal'] {
    max-height: 100vh;

    [class^='ExtendableDonutChart_ModalTitle'] p {
      font-size: 24px;
      line-height: 32px;
    }
  }
}
