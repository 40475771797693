@import 'variables.scss';
@import 'mixin.scss';

@mixin text($size: 18px, $weight: 700, $line: 28px) {
  font-size: $size;
  font-weight: $weight;
  line-height: $line;
  letter-spacing: 0em;
}

.mapContainer {
  @include flex($direction: column);

  padding: 16px;
  gap: 16px;

  background: #2c2c2c3d;
  border-radius: 12px;

  .headerContainer {
    width: 100%;
    height: 28px;

    @include flex($justify: space-between);

    .title {
      @include text();
      color: $grey-primary50;
    }
    .buttons {
      @include flex($justify: flex-end, $items: flex-start);

      .btnHidden {
        @include text($size: 14px, $line: 20px);
        color: $mariner;
        cursor: pointer;
      }
    }
  }

  .mapView {
    width: 100%;
    height: 400px;
  }
}

.max {
  height: 476px;
}

.mix {
  height: 28px;
}

.pr {
  padding-right: 4px;
}

.tdContent {
  max-width: 220px;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.bold {
  font-size: 16px;
  font-weight: bold;
}
