// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.compression-edit {
  color: $grey-primary50;
  box-shadow: 0px 1px 0px rgba(54, 56, 60, 0.7);
  padding: 0 32px 8px 32px;
  .compression-date-info {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 12px 0;
    line-height: 24px;

    .input-label {
      @include flex($justify: space-between);

      .exceeded {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #fcaf57;
      }
    }
  }
  .compression-type {
    margin-bottom: 8px;
    text-align: left;
  }

  .compression-date {
    text-align: left;
    margin-bottom: 8px;
  }

  .compression-content {
    padding: 10px 20px;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    text-align: left;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: space-between;
    background-color: red;

    // .compression-type {
    //   width: 50%;
    //   padding-left: 10px;
    //   font-weight: bold;
    // }

    .select-label {
      width: 50%;
      height: 25px;
      display: flex;
      border: solid 1px $border-deep-color;
      margin-right: 25px;
    }

    .input-num {
      display: flex;
      border-bottom: solid 1px black;
      font-size: x-large;
      align-items: center;
      width: 50%;

      > input {
        width: 100%;
        height: 100%;
        font-size: 80%;
        font-weight: bold;
        border: none;
        outline: none;
        text-align: center;
      }
    }
  }

  .download-button {
    text-align: center;
    font-weight: bold;
    padding: 10px 24px;
    border-radius: 8px;
    white-space: nowrap;
    font-size: 20px;

    &.default {
      background-color: $primary-color;
      border: solid 1px $primary-color;

      a {
        color: white;
      }
    }
  }

  .quantity {
    display: flex;
    flex-direction: row;
    input {
      height: 48px;
      padding: 12px;
      border: 1px solid transparent;
      border-radius: 4px;
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      box-sizing: border-box;
      color: $grey-primary50;
      background-color: $login-input-bg;

      &:disabled {
        background-color: $primary200;
        color: $primary600;
      }

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }
    }
    select {
      width: 100%;
      height: 48px;
      outline: none;
      font-size: 14px;
      background-color: $login-input-bg;
      padding: 10px 24px 10px 10px;
      border-radius: 4px;
      border: none;
      color: $grey-primary50;
      border-right: 14px solid transparent;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .gap-12 {
    gap: 12px;
  }

  .pb-12 {
    padding-bottom: 12px;
  }

  .packingFieldLabel {
    display: flex;
    align-items: center;
    width: 10%;
  }

  .packingFieldSelect {
    width: 100% !important;
  }

  .quantityFieldWidth {
    width: 50%;
    [class^='text-input'] {
      margin: 0;
    }
    @include flex($direction: column, $justify: flex-start, $items: flex-start);

    .full {
      width: 100%;
    }
  }
  .space {
    margin-left: 12px;
  }
  .weightField {
    position: relative;
    [class^='text-input'] {
      margin: 0;
      input {
        padding-right: 40px;
      }
    }

    .weightUnit {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }

  select option {
    font-size: 100%;
    background-color: $grey-primary900;
    color: $grey-primary50;
    padding: 8px !important;
  }
}
