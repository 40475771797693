// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

form {
  @include breakpoint-down('lg') {
    width: 100%;
  }
}

.form-wraper {
  width: 384px;
  margin: 0 auto;
  @include breakpoint-down('lg') {
    width: 100%;
  }
  .text-input > div {
    color: $grey-primary50;
    margin-bottom: 8px;
    font-size: 16px;
  }
  button {
    height: $buttonLargeHeight;
    border-radius: 4px;
    width: 100%;
    border: none;
    background-color: $grey-primary50;
    color: $grey-primary900;
    font-size: 18px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 32px;
    cursor: pointer;
    @include breakpoint-down('md') {
      height: $buttonMediumHeight;
      font-size: 16px;
      line-height: 24px;
    }
    &:disabled {
      background-color: $grey-primary50;
      color: $grey-primary300;
      cursor: not-allowed;
    }

    &:focus {
      background-color: $grey-primary100;
    }
  }
  .errors {
    color: $function300;
    line-height: 20px;
    margin-top: 32px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    color: $grey-primary50;
    margin-bottom: 4px;
  }
  a {
    color: $link-color;
    font-size: 14px;
  }
}
.logo {
  margin: 0 auto 40px;
}
.login-selectLanguage {
  position: relative;
  width: 125px;

  .icon-language {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    z-index: 1;
    height: 20px;
  }
  input {
    background-color: transparent !important;
    padding-left: 26px !important;
    height: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
  svg {
    right: 0;
  }
}

.login-language {
  margin-top: 32px;
  color: $grey-primary50;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  .login-selectLanguage ul {
    top: unset !important;
    bottom: calc(100% + 4px);
  }
}
