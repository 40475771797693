@import 'variables.scss';
@import 'mixin.scss';

.truck-modal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }
  box-sizing: border-box;

  [class^='content'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;
    height: auto;
    background-color: $grey-primary900;
    color: $grey-primary50;
  }

  .customTitle {
    padding: 32px 32px 16px;
    box-shadow: 0px 1px 0px $borderGrey;
    @include flex($justify: space-between);

    .title {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .form-body {
    padding: 16px;
    font-size: 16px;
    gap: 16px;

    @include flex($direction: column, $items: stretch);

    .selectBox {
      @include flex($direction: column, $items: stretch);

      gap: 8px;
    }
  }

  .submit {
    @include flex();
    .submitButton {
      width: 168px;
      height: $buttonMediumHeight;
      border-radius: 4px;
      border: none;
      background-color: $main400;
      color: $grey-primary900;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }
    }

    .submitButton:disabled {
      border-radius: 4px;
      border: none;
      color: $main600;
      font-weight: bold;
      width: 168px;
      height: $buttonMediumHeight;
      cursor: not-allowed;
    }
  }
}
