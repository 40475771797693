// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customTitle {
  padding: 32px 32px 12px 32px;
  @include breakpoint-down('md') {
    padding: 16px;
  }
  @include flex($justify: space-between);
  .descriptionTitle {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: $grey-primary50;
  }
  border-bottom: 1px solid $grey-primary700;
  margin-bottom: 8px;
}

.tareModal {
  [class^='modal'] {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    max-width: 800px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
  }
}

.top {
  @include flex($items: flex-end);
}

.body {
  padding: 11px 32px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.descriptionContainer {
  .description {
    padding: 16px;
    background: #2c2c2c3d;

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: $grey-primary50;
      text-align: left;
    }
  }
}
