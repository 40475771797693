// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.inputFrame {
  flex: 1 1 0%;

  .textFrame {
    gap: 12px 12px;
    flex-wrap: wrap;
    @include flex($items: start, $justify: start);

    & > div {
      width: calc(50% - 6px);
    }

    .address {
      width: 100%;
    }
  }
}

.loadingSpinner {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
