// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.collect-received {
  &.scroll-contents {
    max-height: calc(var(--vh) - #{$header-height + $tab-height});
    width: 100%;
  }
}

.collect-area-card {
  display: flex;
  padding: 12px 32px;
  border-bottom: solid 1px $primary600;
  color: $grey-primary50;
  @include breakpoint-down('md') {
    padding: 12px 16px;
  }

  &:last-child {
    border-bottom: none;
  }
}

.collect-area-card > div {
  width: 25%;
  @include breakpoint-down('md') {
    width: 50%;
  }
}

.area-info {
  height: auto;
}

.area-info > div {
  text-align: left;
}

.area-info .area-name {
  padding: 10px 0px;
  line-height: 20px;
  font-size: 14px;
  color: $grey-primary50;
  margin-top: 4px;
  word-break: break-word;
  word-wrap: break-word;
}

.area-map {
  margin-top: 4px;
  a {
    color: $grey-primary50;
    text-decoration: underline;
    line-height: 20px;
    font-size: 14px;
  }
}

.collect-button {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.collect-area-card .icon {
  border: solid 1px $doveGray;
  padding: 5px;
  color: $doveGray;
}

.collect-area-card .icon.status-collect-requested {
  background-color: $grey-primary500;
  color: $grey-primary100;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  width: fit-content;
  border: none;
}

a.collect-area-button {
  text-align: center;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
  color: $grey-primary900;
  background-color: $main400;
  cursor: pointer;

  &:focus {
    background-color: $main600;
  }
}
