// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.calendar-text-field {
  border: solid 1px $text-four;
  padding: 5px;
  padding-right: 35px;
  position: relative;
  
  .icon {
    position: absolute;
    right: 3px;
  }
}
