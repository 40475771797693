// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
.custom-radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.custom-radio input[type='radio'] {
  display: flex;
  flex-direction: row;
  align-items: center;

  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #878787;
  border-radius: 50%;
  outline: none;
  transition: box-shadow 0.3s ease;
  margin: 0;
}

.custom-radio input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.custom-radio input[type='radio']:checked:before {
  background: #86ea5c;
}

.custom-radio input[type='radio']:checked {
  border: 1px solid #86ea5c;
}
