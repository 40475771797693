// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

#admin-main.weight-notices-page {
  .scroll-wrapper {
    overflow: hidden;
  }

  .scroll-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }

  .scroll-wrapper::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .countList {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: $black;
    margin-bottom: 20px;
    text-align: left;

    span {
      font-weight: 700;
    }
  }

  table#notices-table {
    min-width: 1200px;
    max-width: 100%;
    max-height: calc(100vh - 314px);
    height: calc(100vh - 314px) !important;
    overflow: auto;

    thead,
    tbody {
      min-width: 1500px;
    }

    tbody {
      tr {
        &:hover {
          opacity: 0.7;
        }
      }
    }

    td,
    th {
      &.c {
        &--date {
          width: 10%;
        }

        &--staff {
          width: 13%;
        }

        &--area {
          width: 14%;
        }

        &--description {
          width: 15%;
        }

        &--type {
          width: 12%;
        }

        &--collect {
          width: 10%;
        }

        &--note {
          width: 9%;
        }
      }
    }
  }
}

table#notices-table::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#notices-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
