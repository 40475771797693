// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.valueWrapper {
  background-color: #ffffff1f;
  min-height: 40px;
  width: 100%;
  border-radius: 4px;
  color: white;
  text-align: left;
  padding: 2px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 100%;

  .value {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    font-size: 14px;
    color: #9ea0a2;
  }

  & > svg {
    min-width: 24px;
  }
}