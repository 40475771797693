// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.dashboard_calendar {
  .calendar_custom {
    background-color: $grey-primary800;
    border-color: $grey-primary700;
    padding: 16px;
    .react-calendar__tile {
      color: $grey-primary50 !important;
      font-size: 16px;
      font-weight: 700;

      &--hover,
      &--range {
        border-radius: 0;
        background: $grey-primary500;
      }
      &--now {
        border-radius: 4px;
        color: $grey-primary900 !important;
        background-color: $grey-primary50 !important;
      }
      &--rangeEnd,
      &--rangeStart {
        background: $primary300;
      }
      &--rangeStart {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &--rangeEnd {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .react-calendar__tile:disabled {
      background-color: $grey-primary800 !important;
      color: $primary300 !important;
      cursor: not-allowed !important;
    }
    .react-calendar__month-view__weekdays {
      padding: 3px 0;
      border-top: 1px solid $grey-primary700;
      border-bottom: 1px solid $grey-primary700;
    }
    .react-calendar__month-view__weekdays__weekday {
      color: $grey-primary300;
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize !important;
    }
    .react-calendar__navigation__label__labelText {
      font-weight: 700;
      font-size: 14px;
      color: $grey-primary50;
    }
    .react-calendar__navigation__arrow {
      background: transparent;
      color: $grey-primary50;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: transparent !important;
    }
    .react-calendar__navigation button:disabled {
      background-color: $grey-primary800;
      cursor: not-allowed;

      svg {
        path {
          stroke: $grey-primary400;
        }
      }
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: transparent !important;
    }
  }
}
