// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.createBody {
  display: flex;
  justify-items: center;
  margin: auto;
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.inputFrame {
  max-height: calc(100vh - 345px);
  margin: 24px -16px;
  padding: 0 16px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }
  > * {
    margin: 12px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.line {
  width: 480px;
  height: 1px;
  background-color: $primary100;
  margin-left: -32px;
}

.submit {
  margin-top: 24px;
}

.submitButton {
  height: 44px;
  width: 100%;
  padding: 12px 16px;
  background-color: $primary500;
  border-radius: 4px;
  border: none;
  color: $background-white;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;

  &:hover,
  &:active {
    background-color: $primary500;
  }

  &:disabled {
    cursor: not-allowed;
    color: $primary200;
    background-color: $primary400 !important ;
  }
}

.weightField {
  position: relative;
  [class^='text-input'] {
    margin: 0;

    input {
      padding-right: 40px;
    }
  }

  .weightUnit {
    position: absolute;
    top: 40px;
    right: 10px;
  }
}
.quantity {
  display: flex;
  flex-direction: row;
}

.quantityFieldWidth {
  width: 50%;
  [class^='text-input'] {
    margin: 0;
  }
}

.space {
  margin-left: 12px;
}

.convertKg {
  padding-right: 40px;
}

.edi {
  display: flex;
  text-align: center;
  padding: 8px 0;

  .label {
    margin-right: 12px;
  }
}
