// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.badgeWrapper {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 12px;
  padding: 0px 6px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 700;
}

.right {
  right: -8px;
  top: 50%;
  transform: translate(100%, -50%);
}

.left {
  left: -8px;
  top: 50%;
  transform: translate(-100%, -50%);
}

.bottom {
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 100%);
}

.top {
  top: -8px;
  left: 50%;
  transform: translate(-50%, 100%);
}
