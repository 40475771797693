// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.selectMultipleField {
  text-align: left;
  position: relative;
  font-size: 14px;

  & > div {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }

  select {
    width: 100%;
    height: 44px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    background-color: $background-white;
    padding: 10px 24px 10px 10px;
  }

  .select {
    position: relative;

    & > svg {
      position: absolute;
      top: 55%;
      right: 10px;
      margin-top: -5px;
    }

    .all {
      font-weight: 700;
      color: $primary600;
    }
  }

  .input {
    caret-color: transparent;
    width: 100%;
    height: 44px;
    padding: 8px 24px 8px 12px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    color: $primary600;
    background-color: $background-white;
    border: 1px solid $primary200;
    font-weight: 400;
    border-radius: 4px;
    @include flex($justify: start);
    position: relative;

    .wrapSelected {
      overflow-x: auto;
      width: 100%;
      @include flex($justify: start);
    }

    &.inputError {
      border-color: $error-border-color;
    }

    &:hover {
      cursor: pointer;
    }

    &.inputDisable {
      border: 1px solid $primary200;
      background-color: $primary200;
      cursor: not-allowed;

      .wrapSelected {
        color: $primary300;
      }
    }

    &.placeHolder {
      color: $primary300;
    }
  }

  .searchInput {
    display: flex;
    width: 100%;
    position: relative;
    padding: 0;

    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $grey-primary100;
      padding: 6px 15px;
      font-size: 14px;
      line-height: 20px;
      color: $grey-primary600;
      outline: none;
      padding-right: 36px;

      &:focus {
        border: 1px solid $grey-primary600;
      }
    }

    .icon {
      text-align: right;
      position: absolute;
      top: calc(50% - 8px);
      right: 16px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $primary300;
        }
      }
    }
  }

  ul {
    width: 100%;
    position: absolute;
    background-color: $background-white;
    z-index: 10;
    top: calc(100% + 4px);
    border: 1px solid $iron;
    max-height: 210px;
    overflow-y: scroll;
    cursor: pointer;
    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 8px;
    font-size: 14px;
    background: $primary75;

    li {
      padding: 10px 8px;
      border-bottom: 1px solid $iron;
      overflow-wrap: break-word;
      color: $primary600;
      font-weight: 400;
      font-size: 14px;
      @include flex($justify: start);
      &:hover {
        background-color: $primary100;
      }
      & > div {
        padding: 0 0 0 10px;
        width: unset;
      }
      & > span {
        @include ellipseText(2);
      }
      input:checked + label:after {
        top: 6px;
      }
    }

    li:last-child {
      border-bottom: 0px;
    }
  }

  .wrapSelected::-webkit-scrollbar,
  ul::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  .wrapSelected,
  ul {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .error {
    color: $error-border-color;
    font-size: 14px;
  }

  .darkModeStyle {
    background-color: $login-input-bg !important;
    border: 1px solid #ffffff1f;
    width: auto;
    box-sizing: border-box;

    &.inputDisable {
      border: 1px solid #6f6f6f;
      background-color: #6f6f6f !important;
      cursor: not-allowed;

      .wrapSelected {
        color: $primary300;
      }
    }

    &.inputError {
      border: 1px solid  $error-border-color !important;
    }

    &:focus:not(.focus-visible) {
      border: 1px solid $main400 !important;
    }
  }

  ul.darkModeList {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    background-color: $grey-primary600;
    z-index: 100;
    top: calc(100% + 4px);
    border: 1px solid $grey-primary500;
    max-height: 210px;
    overflow-y: scroll;
    cursor: pointer;
    box-shadow: 0px 1px 2px $box-shadow;
    border-radius: 4px;
    font-size: 14px;

    li.darkModeItem {
      padding: 10px;
      border-bottom: 1px solid $grey-primary500;
      overflow-wrap: break-word;
      color: $grey-primary50;

      &:hover {
        background-color: $grey-primary400;
      }

      &.selected {
        background-color: $grey-primary400;
      }

      .darkModeLabel {
        color: $grey-primary50;
        width: 100%;
        text-align: left;
        padding-left: 8px;
        font-weight: 700;
      }
    }

    li.darkModeItem:last-child {
      border-bottom: 0px;
    }
  }
}
