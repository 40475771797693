// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.floor {
  border-radius: 4px;
  margin: 0.5rem;
  cursor: pointer;
  background-color: $grey-primary700;
  display: inline-block;
}
.selected {
  font-weight: bold;
  background-color: $main400 !important;
  div {
    p {
      color: $grey-primary900;
    }
  }
}
.floorItem {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 125px;
  height: 44px;
  min-width: 65px;
  p {
    @include truncate-oneline();
    padding: 0px 3px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $grey-primary100;
  }
}
