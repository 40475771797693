// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.sortEmissionsDragDropSection {
  background-color: $grey-primary900;
  padding: 24px 0 0;
  height: calc(100% - 24px);
  min-width: 900px;

  &Header {
    padding: 0 32px;

    &Description {
      font-size: 14px;
      line-height: 20px;
      color: $grey-primary300;
      text-align: right;
    }
  }

  &Content {
    margin-top: 16px;
    padding: 0 32px;
    height: calc(100% - 124px);
  }

  &Footer {
    margin: 0 auto;
    padding: 16px;
    background-color: $grey-primary900;
    box-shadow: 0px -1px 12px $primary900;

    &SubmitButton {
      background-color: $main400;
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 17px 31px 13px;
      min-width: 170px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $grey-primary600;

      &:disabled {
        background-color: $main400;
        border: 1px solid $main400;
        color: $main600;
        cursor: not-allowed;
      }

      &:focus {
        background-color: $main600;
      }
    }
  }

  .emissionsDragDropContext {
    @include flex($justify: space-between, $items: start);

    gap: 32px;
    height: 100%;
  }

  .emissionsDroppable {
    flex: 1;
    height: 100%;
    min-width: 250px;

    &Header {
      @include flex();

      &Line {
        flex: 1;
        height: 1px;
        background-color: $primary600;
      }

      &Title {
        font-size: 18px;
        line-height: 28px;
        color: $grey-primary50;
        margin: 0 8px;
      }
    }

    &Content {
      margin-top: 24px;
      height: calc(100% - 52px);
    }
  }

  .emissionsDraggable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
      display: none;
    }

    &Item {
      @include flex();

      background-color: $bgGrey;
      border-radius: 12px;
      padding: 12px;
      cursor: pointer;
      user-select: none;
      -moz-user-select: none;

      &:not(:first-child) {
        margin-top: 12px;
      }

      &Image {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 12px;
      }

      &Content {
        flex: 1;
        text-align: left;
        padding: 10px 0;
        margin-left: 20px;
      }

      &Name,
      &WasteUnitInfo {
        font-size: 16px;
        line-height: 24px;
      }

      &Name {
        font-weight: bold;
        color: $grey-primary50;
      }

      &WasteUnitInfo {
        margin-top: 4px;
        color: $grey-primary50;
      }

      &TypeOptional,
      &TypeFixed {
        align-self: flex-start;
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        color: $grey-primary50;
        margin-left: 4px;
        min-width: 28px;
      }

      &TypeOptional {
        background-color: $second400;
      }

      &TypeFixed {
        background-color: $main400;
        width: 32px;
        height: 32px;
        @include flex();
        svg {
          path {
            fill: $black;
          }
        }
      }
    }
  }

  .ellipseText {
    @include ellipseText();
  }

  .ellipseTextTwoLine {
    @include ellipseText(2);
  }
}
