// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.warningModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    @include flex();
  }

  [class^='content'] {
    width: 480px !important;
    margin: auto;
    border-radius: 4px !important;

    .modalContent {
      padding: 32px;

      .modalHeader {
        @include flex($justify: flex-end);

        .modalTitle {
          margin: auto;
          font-size: 20px;
          line-height: 32px;
          font-weight: 700;
        }

        .buttonClose {
          @include flex($justify: flex-end);
        }
      }

      .modalBody p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        margin: 20px;
        white-space: pre-line;
      }

      .modalFooter .submitButton {
        width: 180px;
        height: $buttonMediumHeight;
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        background-color: $grey-primary600;
        color: $primary50;
        border-radius: 4px;
        border: none;
      }
    }
  }

  &.darkMode {
    [class^='contents'] {
      background-color: $grey-primary900;

      p {
        color: $grey-primary50;
      }

      .modalFooter .submitButton {
        height: $buttonMediumHeight;
        font-size: 16px;
        line-height: 24px;
        background-color: $main400;
        color: $grey-primary900;
      }
    }
  }
}
