// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.container {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .children {
    font-size: 14px;
    font-weight: 700;
    color: $primary600;
  }
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $primary75;
  border-radius: 50%;
  border: 0.5px solid $primary200;
}

.container:hover input ~ .checkmark {
  background-color: $primary600;
  border: none;
}

.container input:checked ~ .checkmark {
  background-color: $primary600;
  border: none;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.container:hover input ~ .checkmark::after,
.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}

.containerDisabled {
  cursor: not-allowed !important;

  .children {
    color: #CFCFCF;
  }
}

.checkmarkDisabled, checkmarkDisabled:after {
  background-color: #CFCFCF !important;
}
.containerDisabled:hover .checkmark {
  background-color: #CFCFCF;
  border: none;
}
