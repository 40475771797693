// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.checkbox input[type='checkbox'] {
  display: none;
}
/* チェックボックスの代わりを成すラベル */
.checkbox input[type='checkbox'] + label {
  display: none;
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding-left: 25px;
  padding-right: 10px;
}
/* ラベルの左に表示させる正方形のボックス□ */
.checkbox input[type='checkbox'] + label::before {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 25px;
  height: 25px;
  margin-top: -18px;
  left: 0;
  top: 50%;
  border: 1px solid;
  border-color: $border-deep-color; /* 枠の色変更 お好きな色を */
  background-color: $primary500; /* 背景の色変更 お好きな色を */
  border-radius: 50%;
}

.disabledLabel::before {
  cursor: not-allowed;
}
/* チェックが入った時のレ点 */
.checkbox input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  width: 13px;
  height: 9px;
  margin-top: -12px;
  top: 50%;
  left: 5px;
  transform: rotate(-50deg);
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: $primary100; /* チェックの色変更 お好きな色を */ // color of tick icon
}
