// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.updateMailTemplateForm {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0 0;
  width: 100%;
}
.textAreaField {
  textarea {
    resize: none;
    height: 240px !important;
  }
}
.previewBody {
  min-height: 230px;
  margin-top: 8px;
  border: 1px solid $primary200;
  border-radius: 4px;
  width: 100%;
  color: $primary600;
  padding: 12px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  white-space: pre-wrap;
  text-align: left;
  word-break: break-all;
  cursor: pointer;
  a {
    color: $mariner;
    text-decoration: underline;
  }
}
.submit {
  padding: 1.5rem 2rem 0;
  border-top: 1px solid $primary100;
  margin: 1.5rem -2rem 0;

  button {
    margin: 0 -32px;
    width: 100%;
  }

  .submitButton {
    height: 44px;
    background-color: $primary400;
    border-radius: 4px;
    border: none;
    color: $primary50;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: bold;

    &:hover, &:active {
      background-color: $primary500;
    }
    &:disabled {
      cursor: not-allowed;
      color: $primary200;
      background-color: $primary400 !important;
    }
  }
}

.previewError {
  border-color: $function300;
}

.hidden {
  display: none;
}

.error {
  font-size: 14px;
  color: $error-border-color;
  text-align: left;
  margin-top: 8px;
}
