// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.traceDataDetail {
  background-color: $bgPurchase;
  padding: 16px;
  margin: 20px;
  height: calc(100% - 40px);
  box-sizing: border-box;

  .header {
    height: 48px;
    padding-bottom: 16px;
    @include flex($justify: space-between);

    .heading {
      font-size: 20px;
      font-weight: 700;
      color: $grey-primary50;
    }

    .actions {
      gap: 4px;
      @include flex($items: center, $justify: space-between);

      a,
      .downloadCertificate {
        background-color: $bgPurchase;
        padding: 12px 24px;
        font-size: 14px;
        font-weight: 700;
        color: $grey-primary50;
        border: none;
        box-sizing: border-box;
        margin: 0;
        @include flex($items: center);

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  .wrapper {
    height: calc(100% - 64px);
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $crollColor;
    }

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  }
}
