// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customModal {
  [class^='modal'] {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  [class^='contents'] {
    width: 448px;
    height: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: white;
  }

  .deleteItemModal {
    padding: 32px;

    .customIcon {
      display: flex;
    }

    .top {
      display: flex;
      justify-content: flex-end;
    }

    .customTitle {
      p {
        font-size: 16px;
        line-height: 1.5rem;
        font-weight: 400;
        color: $grey-primary50;
        margin-bottom: 16px;
        word-break: break-word !important;
        white-space: pre-line;
      }

      .title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: $grey-primary50;
        margin: 16px 0px;
      }
    }

    .iconDeleteItem {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-left: 40%;
    }

    .submit {
      padding: 15px 16px 10px 16px;

      .submitButton {
        height: $buttonMediumHeight;
        background-color: $function300;
        border-radius: 4px;
        border: none;
        color: $grey-primary50;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        padding: 16px 52px;
        box-sizing: border-box;

        &:focus {
          background-color: $function500;
        }
      }
    }
  }
}
