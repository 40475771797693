// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionConfirmLeavePageModal {
  [class^='modal'] {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $dark400;
  }

  [class^='contents'] {
    width: 680px;
    height: auto;
    background-color: $grey-primary900;
    border-radius: 12px;
    color: $primary50;
    padding: 48px;
    margin: 0;
  }

  &Content {
    position: relative;
    text-align: center;
    color: $grey-primary50;

    &IconClose {
      position: absolute;
      top: 10px;
      right: 0;
    }

    &Title {
      @include fontSizeText();
    }

    &Description {
      font-size: 36px;
      line-height: 56px;
      margin-top: 56px;
    }
  }

  &Footer {
    margin: 32px auto 0;

    &SubmitButton {
      height: 80px;
      background-color: $function300;
      border-radius: 4px;
      border: none;
      padding: 20px 40px;
      min-width: 168px;
      color: $grey-primary50;

      @include fontSizeText();

      &:focus {
        background-color: $function500;
      }
    }
  }
}
