// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.section {
  padding: 12px 24px;
  height: $buttonSmallHeight;
  width: 152px;
  border-radius: 4px;
  cursor: pointer;
  background-color: $grey-primary700;
  box-sizing: border-box;
  @include breakpoint-down('md') {
    width: calc(50% - 8px);
  }
  p {
    @include truncate-oneline();
    padding: 0px 3px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $grey-primary100;
  }
}

.selected {
  font-weight: bold;
  background-color: $main400 !important;
  p {
    color: $grey-primary900 !important;
  }
}
