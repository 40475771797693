// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.importCSV {
  @include flex($items: flex-start);
  min-height: calc(100vh - 65px);
  background-color: $grey-primary900;
  padding-bottom: 32px;
  border-radius: 4px;

  .modalContent {
    width: 50%;
    margin: auto;
    @include breakpoint-down('md') {
      width: calc(100% - 16px);
      margin: auto 16px;
    }

    .description {
      padding: 48px;
      background: $grey-primary800;
      border: 1px dashed $grey-primary500;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      @include breakpoint-down('md') {
        padding: 32px 16px;
      }

      .uploadFileIcon {
        margin-bottom: 21px;
      }

      span {
        color: $grey-primary50;
        font-weight: 700;
      }

      p {
        margin: 16px 0;
        color: $grey-primary300;
        font-weight: 400;
      }

      .uploadFileButton {
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 700;
        color: $mariner;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin: 0;
      }
    }
    .uploadFileInfo {
      @include flex($items: center, $justify: space-between);
      border-radius: 4px;
      padding: 32px 0;

      .uploadFileName {
        width: 70%;
        @include flex($items: center, $justify: start);
        @include breakpoint-down('md') {
          width: 70%;
        }

        span {
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-weight: 500;
          color: $grey-primary100;
          text-align: left;
          display: inline;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }

      .uploadFileAction {
        width: 30%;
        @include flex($items: center, $justify: flex-end);
        @include breakpoint-down('md') {
          width: 30%;
        }

        .uploadFileSize {
          font-size: 0.875rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: $grey-primary100;
          margin-right: 2.25rem;
          @include breakpoint-down('md') {
            margin-right: 16px;
          }
        }

        .deleteIcon {
          cursor: pointer;
          path {
            stroke: $function300 !important;
          }
        }
      }
    }

    .error {
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-align: left;
      margin-top: 0.5rem;
      padding: 0 32px;
      p {
        color: $function300;
        width: 250px;
      }
    }

    .uploadFileButtonSubmit {
      .submitButton {
        height: 56px;
        background-color: $main400;
        border: none;
        border-radius: 4px;
        border: none;
        color: $grey-primary900;
        font-size: 16px;
        font-weight: 700;
        margin: 32px 0 2rem 0 !important;
        padding: 12px 40px;
        box-sizing: border-box;

        &:focus {
          background-color: $main600;
        }
      }

      .submitButton:disabled {
        color: $main600;
        cursor: not-allowed;
      }
    }
  }
}
