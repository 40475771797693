// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tableList {
  .titleTable {
    @include flex($justify: space-between);
    color: $primary600;

    .customIcon {
      @include flex();

      .buttonFileImportIcon {
        @include flex();

        font-weight: bold;
        font-size: 14px;
        cursor: pointer;
        margin-right: 60px;

        span {
          margin-left: 11px;
        }
      }

      .buttonAdd {
        @include flex();

        font-weight: bold;
        font-size: 14px;
        cursor: pointer;

        span {
          margin-left: 6px;
        }
      }
    }
  }

  .searchInput {
    display: flex;
    margin-top: 16px;
    min-width: 320px;
    width: 30%;
    position: relative;

    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $grey-primary100;
      padding: 11px 15px;
      font-size: 14px;
      line-height: 20px;
      color: $grey-primary600;
      outline: none;
      padding-right: 36px;

      &:focus {
        border: 1px solid $grey-primary600;
      }
    }

    .icon {
      text-align: right;
      position: absolute;
      top: calc(50% - 8px);
      right: 16px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $primary300;
        }
      }
    }
  }

  .scrollWrapper {
    overflow: hidden;
  }
}

table#customizeItemTable {
  max-width: 100%;
  max-height: calc(100vh - 304px);
  overflow: auto;

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  tr {
    .no {
      width: 10%;
    }

    .discharger,
    .dischargerBase {
      width: 70%;
    }

    .actionField {
      width: 20%;
    }

    td {
      &.actionField {
        vertical-align: middle;
        padding: 8px 0 0 !important;

        .contentAction {
          @include flex($justify: flex-start);

          > div {
            cursor: pointer;
            height: 24px;

            &.actionEdit, &.actionDelete {
              margin-right: 24px;
            }
          }
        }
      }

      &.discharger,
      &.dischargerBase {
        text-align: left;
        word-break: break-all;

        p {
          @include ellipseText(2);
        }
      }

      &.discharger {
        p {
          line-height: 20px;
        }
      }

      &.dischargerBase {
        p {
          line-height: 24px;
        }
      }
    }
  }
}

table#customizeItemTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#customizeItemTable::-webkit-scrollbar {
  width: 5px;
}
