// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 1116px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;
    @include breakpoint-down('sm') {
      height: auto;
    }
  }

  .customTitle {
    padding: 32px 32px 16px 32px;
    border-bottom: 1px solid $primary600;
    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      padding: 24px 16px 16px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .inputForm {
    margin: auto;

    .textFrame {
      gap: 16;
      margin: 16px;
      display: flex;

      .time {
        width: calc(50% - 16px);
        margin-left: 16px;

        .inputFrame {
          display: flex;
          margin-top: 8px;
          
  
          [class^='select-date'] {
            min-width: calc(50% - 8px) !important;

            input {
              background-color: $login-input-bg;
              border: none !important;
            }
          }
  
          .timeFrame {
            width: calc(50% - 32px);
            margin-left: 16px !important;
          }
        }
      }
    }
  }

  .body {
    margin: 0px 18px 0px 32px;
    overflow: auto;
    border: 1px solid $grey-primary700;
    border-radius: 4px;
    margin-bottom: 16px;

    .primaryTable {
      background-color: $grey-primary900 !important;
      color: $grey-primary50;
      width: 100%;
      max-height: 285px;
  
      thead {
        background-color: $grey-primary800 !important;
      }
  
      th {
        font-size: 12px !important;
        color: $grey-primary100;
        border-left: none !important;
        border-right: 1px solid $grey-primary700 !important;
        border-bottom: 1px solid $grey-primary700 !important;
  
        @include breakpoint-down('md') {
          font-size: 14px !important;
          line-height: 20px;
        }
      }
  
      td {
        border: 1px solid $grey-primary700;
        border-left: none !important;
        border-top: none !important;

        &:last-child {
          border-right: none !important;
        }

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .label {
      width: 19%;
      font-size: 14px;
      text-align: left;
      line-height: 24px;
      padding: 8px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  
    .type {
      width: 13%;
      font-size: 14px;
      text-align: left;
      line-height: 24px;
      padding: 8px 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .weight {
      width: 8%;
      line-height: 24px;
      font-size: 14px;
      text-align: right;
      padding-right: 16px;
    }

    .total {
      color: $grey-primary50;
      text-align: right;
      padding: 16px;
  
      p {
        font-size: 18px;
        line-height: 28px;
  
        span {
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }

  .submit {
    margin-bottom: 24px;

    .submitButton {
      width: 160px;
      height: 44px;
      background-color: $main400;
      border-radius: 4px;
      font-family: 'Noto Sans JP';
      border: none;
      color: $grey-primary900;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }

      &:hover,
      &:active {
        background-color: $main500;
      }

      &:disabled {
        cursor: not-allowed;
        color: $main600;
        background-color: $main300 !important;
      }
    }
  }
}
