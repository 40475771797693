// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.PoolDashboardResourceData {
  background-color: #2c2c2c;
  padding: 16px;
  border-radius: 12px;

  .Header {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;

    & > p {
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: #e7e7e7;

      @include breakpoint-down('xl') {
        font-size: 16px;
      }
    }

    .DownloadButton {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #4d4d4d;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

.PoolDashboardResourceTable {
  width: 100%;
  overflow: auto;
  display: block;
  @include customScroll();
  max-height: 700px;
  border-radius: 4px;
  cursor: pointer;

  th {
    padding: 16px 12px;
  }

  td {
    padding: 8px 12px;
  }

  .StationCol {
    width: 18%;
    min-width: 150px;
  }

  th,
  td {
    color: $grey-primary50;
    width: 15%;
    min-width: 140px;
    height: auto;
    text-align: left;
    border: 1px solid $grey-primary700;
    vertical-align: top;
  }

  .SmallCol {
    width: 10%;
    min-width: 100px;
  }

  thead {
    position: sticky;
    top: -1px;
    background-color: #4d4d4d !important;

    tr {
      height: $theadTableHeight;
      box-sizing: border-box;

      th {
        color: $grey-primary100;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  tbody {
    tr {
      box-sizing: border-box;

      td {
        &.noData {
          color: $grey-primary50;
          text-align: center;
          line-height: 56px;
          padding: 0 16px;
          font-size: 0.9em;
        }

        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $grey-primary50;
          display: inline-block;
          word-break: break-all;
        }

        &.Weight {
          text-align: right;
        }
      }
    }
  }
}

.PoolDashboardResourceTableModalWrapper {
  overflow-y: hidden;
  display: flex;

  [class^='contents'] {
    max-width: 1600px;
    margin: auto;
    background: none;
    border-radius: 8px;
  }

  .PoolDashboardResourceTableModal {
    background-color: $grey-primary900;
    margin: auto;
    border-radius: 8px;

    th {
      background-color: $grey-primary800;
    }

    .ModalTitle {
      display: flex;
      justify-content: space-between;
      padding: 24px 24px 16px 24px;
      border-bottom: 1px solid $grey-primary700;
      margin-bottom: 16px;
      gap: 16px;

      & > p {
        color: $grey-primary50;
        font-weight: 700;
        font-size: 20px;
      }
    }

    .ModalBody {
      padding: 0 24px 24px;
      margin-top: 8px;

      @include breakpoint-down('md') {
        padding: 8px 16px 16px;
      }

      .PoolDashboardResourceTable {
        max-height: 80vh;
        cursor: default;

        th,
        td {
          min-width: 100px;
        }
      }
    }
  }
}
