@import 'variables.scss';
@import 'mixin.scss';

.menu-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.child {
  padding: 5px 32px 4px 48px;
  text-align: left;
  margin: 7px 0;

  @include ellipseText(2);
}

.parent {
  padding: 16px 32px;
  @include flex($direction: row, $justify: space-between);
}

.active {
  color: $grey-primary50;
}
