// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.SiteManagement {
  padding: 24px 32px;

  @include breakpoint-down('md') {
    padding: 12px 16px;
  }

  .CountList {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $grey-primary50;
    text-align: left;
    margin-bottom: 24px;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    span {
      font-weight: 700;
    }
  }

  .SiteManagementTable {
    width: 100%;
    max-height: calc(var(--vh) - #{$headerHeight} - 24px - 24px - 48px);
    border-radius: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;

    @include breakpoint-down('md') {
      overflow: auto;
      max-height: calc(var(--vh) - #{$headerSmallHeight} - 12px - 12px - 24px);
      display: block;
    }

    .SiteName {
      width: 20%;
    }

    .Station {
      width: 70%;
      min-width: 200px;
    }

    .Action {
      width: 10%;
    }

    th,
    td {
      color: $grey-primary50;
      min-width: 160px;
      padding: 6px 16px;
      height: auto;
      text-align: left;
      border: 1px solid $grey-primary700;
      vertical-align: middle;

      @include breakpoint-down('md') {
        padding: 6px;
      }
    }

    thead {
      position: sticky;
      top: -2px;
      background-color: $grey-primary800 !important;

      tr {
        height: $theadTableHeight;
        box-sizing: border-box;

        th {
          color: $grey-primary100;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    tbody {
      background: $grey-primary900;

      tr {
        height: 56px;
        box-sizing: border-box;

        td {
          .StationTags {
            gap: 4px;
            flex-wrap: wrap;
            @include flex($justify: flex-start);

            .StationTagItem {
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              color: $grey-primary900;
              padding: 2px 8px;
              background-color: $main75;
              border-radius: 4px;
            }
          }

          &.NoData {
            color: $grey-primary50;
            text-align: center;
            line-height: 56px;
            padding: 0 16px;
            font-size: 14px;
          }

          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $grey-primary50;
            display: inline-block;
            word-break: break-all;
          }

          button {
            margin: 0;
            padding: 12px 20px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 700;
            border: 1px solid $grey-primary50;
            box-sizing: border-box;
            width: 100%;

            @include breakpoint-down('md') {
              padding: 6px;
              font-size: 12px;
              line-height: 16px;
            }

            &.BtnDashboard {
              height: $buttonSmallHeight;
              color: $grey-primary50;
              background-color: $grey-primary900;
              border: 1px solid $grey-primary50;
              border-radius: 4px;

              &:focus {
                border: 1px solid $grey-primary600;
              }
            }
          }
        }
      }
    }
  }
}
