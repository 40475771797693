// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.productRegistrationForm {
  @include flex();

  .inputFrame {
    > * {
      margin: 16px 0;
    }

    .content {
      color: $grey-primary50;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      margin-top: 48px;
    }

    .textFrame {
      gap: 16px;
      flex-wrap: wrap;
      @include flex($items: flex-start, $justify: flex-start);

      & > div {
        min-width: 330px;
        width: calc(50% - 8px);

        & > div {
          color: $grey-primary50;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 8px;
        }
      }
    }
  }

  .perField {
    [class^='text-input'] {
      margin: 0;

      input {
        padding-right: 40px;
      }
    }
  }

  .submit {
    button {
      margin: 0 8px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }

    .backButton {
      height: 44px;
      width: 160px;
      padding: 12px 16px;
      color: $grey-primary50;
      border-radius: 4px;
      border: 1px solid $grey-primary50;
      background-color: $grey-primary900;
      box-sizing: border-box;
    }

    .submitButton {
      height: 44px;
      width: 160px;
      padding: 12px 16px;
      border-radius: 4px;
      border: none;
      background-color: $main400;
      color: $grey-primary900;

      &:focus {
        background-color: $main500;
      }

      &:disabled {
        color: $main600;
        cursor: not-allowed;
        border: none;
      }
    }
  }
}
