// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.CO2ReductionCost {
  background-color: $grey-primary800;
  border-radius: 8px;
  padding: 24px 16px;
  box-sizing: border-box;

  .heading {
    padding-bottom: 24px;
    @include flex($justify: space-between);

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: $grey-primary50;
    }

    .actions {
      @include flex();

      .filter {
        gap: 8px;
        @include flex($justify: space-between);

        button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
        }

        .filterButtonSelected {
          svg path {
            stroke: $main400;
          }
        }
      }
    }
  }

  .CO2ReductionCostInfo {
    width: 100%;
    gap: 20px;
    @include flex($justify: start, $direction: column);

    .generalItem {
      height: 100%;
      width: 100%;

      .generalItemWrapper {
        height: 100%;
        width: 100%;
        background-color: $grey-primary700;
        border-radius: 12px;
        color: $primary50;
        text-align: center;
        padding: 21px 16px;
        box-sizing: border-box;
        @include flex($direction: column);

        .label {
          font-size: 14px;
          line-height: 20px;
          padding-bottom: 16px;
        }

        .quantity {
          font-size: 36px;
          font-weight: 700;
          line-height: 42px;
          color: $main400;
          padding-bottom: 8px;
          word-break: break-all
        }

        .labelType {
          font-size: 20px;
          font-weight: 700;
          line-height: 32px;
          color: $main400;
        }
      }
    }
  }
}
