// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.downloadModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 90%;
    margin: auto;
    border-radius: 4px;
    height: auto;
    background-color: $grey-primary900;
    color: $grey-primary50;
  }

  .customTitle {
    margin-top: 10px;
    padding: 16px 32px;
    box-shadow: 0px 1px 0px $primary600;
    @include flex($justify: space-between);

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .body {
    max-height: 544px;
    overflow: auto;
    border: 1px solid $dark100;
    margin: 16px 32px 0px 32px;
  }

  .totalRecovery {
    text-align: right;
    padding: 24px 32px;
    font-size: 18px;
    line-height: 28px;

    p > span {
      font-weight: bold;
    }
  }

  .submit {
    margin-top: 4px;
    padding: 24px 16px 32px 16px;

    .submitButton {
      width: 242px;
      height: 68px;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      color: $grey-primary900;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }
    }

    .submitButton:disabled {
      border-radius: 4px;
      border: none;
      color: $main600;
      font-weight: bold;
      width: 242px;
      height: 68px;
      cursor: not-allowed;
    }
  }
}
