// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.purchaseTraceDataDetailOrganism {
  gap: 16px;
  @include flex($items: start, $justify: start);

  .purchaseTraceDataDetailWrapper {
    flex: 1 1;
    max-width: 20%;
    min-width: 270px;

    .processBox {
      background-color: $grey-primary800;
      padding: 16px;
      text-align: left;
      border-radius: 4px;
      box-sizing: border-box;

      .processName {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        color: $grey-primary50;
      }

      .processWeight {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: $grey-primary50;
      }
    }
  }

  .processDetail {
    background-color: $grey-primary800;
    padding: 16px;
    margin-top: 4px;
    border-radius: 4px;
    box-sizing: border-box;

    .processDetailHeader {
      border-bottom: 1px solid $grey-primary700;
      padding-bottom: 8px;
      text-align: left;

      > div {
        color: $main400;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        @include truncate-oneline();
      }
    }

    .processDetailContent .processDetailItem {
      color: $grey-primary50;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 8px;
      @include flex($justify: space-between, $items: start);

      .registerId {
        word-break: break-word;
        text-align: left;
      }

      .weight {
        padding-left: 16px;
        gap: 6px;
        @include flex();

        span {
          @include truncate-oneline();
        }
      }
    }
  }
}
