// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.admin-content-header {
  @include flex($items: center, $justify: space-between);

  padding: 12px 16px;
  background-color: $background-white;
  border: none;
  box-shadow: 0px 1px 0px $primary200;
  font-size: 12px;
  line-height: 16px;
  color: $primary600;

  &__breadcrumb {
    width: 100%;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    a {
      color: $primary300;
      font-weight: 400;
      word-break: break-all;
    }

    svg {
      padding: 0 14px;
      width: 4px;
      height: 10px;

      path {
        stroke: $primary400;
      }
    }
  }

  &__avatar {
    background-color: $grey-primary900;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-weight: 700;
    font-size: 8px;
    line-height: 32px;
    img {
      width: 28px;
      height: 8.54px;
      object-fit: cover;
    }
  }

  &__dropdown-container {
    position: relative;
    margin-left: 32px;
  }

  &__dropdown-toggle {
    @include flex($items: center, $justify: left);

    height: 32px;
    cursor: pointer;

    p {
      @include truncate-oneline();

      text-align: left;
      margin: 8px 12px;
      flex: 1;
    }
  }

  &__dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: $primary100;
    box-shadow: 0px 1px 2px $primary100;
    border-radius: 4px;
    width: 120px;
  }

  &__dropdown-item {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 14px 25px;
  }
}
