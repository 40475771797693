@import 'variables.scss';
@import 'mixin.scss';

.purchase-template {
  width: 100vw;
  background: $grey-primary900;
 overflow: hidden;

  @include flex($direction: row, $items: flex-start, $justify: left);

  .content {
    width: calc(100vw - 240px);

    .main {
      height: calc(var(--vh) - 68px);
      overflow: hidden !important;
    }
  }

  .select-date {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 44px;
    border: none;
    padding: 0;
    min-width: 248px;
    width: 30%;
    position: relative;

    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $primary200;
      padding: 11px 15px;
      font-size: 14px;
      line-height: 20px;
      color: $primary300;

      &:focus {
        border: 1px solid $grey-primary600;
        outline: none;
      }
    }

    .icon {
      text-align: right;
      position: absolute;
      top: calc(50% - 8px);
      right: 16px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $primary300;
        }
      }
    }

    .calender-datepick {
      z-index: 100;
      position: absolute;
      top: 40px;
      right: -5px;

      .calender {
        padding-left: 30px;
        padding-right: 30px;
        width: 320px;
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}


