// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.chainTag {
  background-color: $main50;
  color: black;
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 4px;
  word-break: break-all;

  @include truncate-multiline(3);
}

.noChain {
  background-color: #5E5E5E;
  color: #E7E7E7;
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 4px;
  word-break: break-all;

  @include truncate-multiline(3);
}
