// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.externalScaleConnectionsHistory {
  height: calc(var(--vh) - #{$headerHeight});
  background-color: $grey-primary900;
  padding: 24px 32px;
  box-sizing: border-box;

  @include breakpoint-down('md') {
    padding: 24px 16px;
  }

  .countList {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $grey-primary50;
    text-align: left;
    padding-bottom: 16px;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .externalScaleConnectionsHistoryTable {
    max-height: calc(100% - 40px);
    overflow-y: auto;

    table {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      table-layout: auto;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }

      th,
      td {
        border: 1px solid $grey-primary700;
        min-width: 120px;
        width: 22%;
        text-align: center;
        padding: 8px;
        box-sizing: border-box;

        .buttonDownload button {
          width: 12%;
        }
      }

      thead {
        position: sticky;
        top: -2px;
        z-index: 10;
        background-color: $grey-primary800 !important;
        color: $grey-primary100;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        th {
          padding: 16px 8px;
        }
      }

      tbody {
        color: $grey-primary50;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        tr {
          .buttonDownload button {
            background-color: transparent;
            border: none;
            margin: auto;
          }

          .msgError {
            text-align: center;
            line-height: 26px;
            padding: 16px;
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
