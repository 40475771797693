// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tenantAuthen {
  width: 100%;
  height: calc(100vh - 65px);
  background-color: $grey-primary900;
  @include flex();
  .buttonArea {
    @include flex($direction: column);

    p {
      color: $grey-primary100;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 64px;
    }
    button {
      box-sizing: border-box;
      border-radius: 4px;
      background: $main400;
      color: $grey-primary900;
      height: $buttonMediumHeight;
      width: 300px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      border: none;
      @include flex();

      &:focus {
        background-color: $main600;
      }
    }
  }
}
