@import 'variables.scss';
@import 'mixin.scss';

@mixin text {
  color: $grey-primary300;
  font-size: 14px;
  line-height: 20px;
}

@mixin active {
  color: $grey-primary50;
}

.purchase-side-nav {
  width: 240px;
  height: calc(100vh - 176px);
  overflow-y: auto;
  background: #2c2c2c3d;
  border-right: 1px solid $grey-primary800;
  padding: 88px 0;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 5px !important;
  };

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background-color: $grey-primary700;
  }

  .purchase-logo {
    height: 48px;
    margin-bottom: 56px;
  }

  .purchase-menu {
    display: flex;
    flex-direction: column;

    gap: 8px;

    .menu-item {
      @include text();
      cursor: pointer;
    }

    .childrens {
      @include flex($direction: column, $justify: space-between);
      gap: 8px;

      .children {
        padding: 12px 48px;
        @include text();
        cursor: pointer;
      }

      .active {
        @include active();
      }
    }

    .menu-item:hover {
      color: $grey-primary50;
    }

    .active {
      @include active();
    }
  }
}
