// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customPagination {
  list-style-type: none;
  gap: 8px;
  @include flex($items: center, $direction: row);

  li {
    &.selected a {
      background-color: $grey-primary800;
      border-color: $main400;
      color: $main400;
    }

    &.disabled a {
      cursor: default;

      svg path {
        stroke: $grey-primary300;
      }
    }

    &.next a,
    &.previous a {
      @include flex();
    }

    a {
      border-radius: 4px;
      padding: 9px 16px;
      background-color: $grey-primary900;
      border: 1px solid $grey-primary700;
      color: $grey-primary50;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      cursor: pointer;

      svg path {
        stroke: $grey-primary50;
      }
    }
  }
}
