// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.unCollectList {
  position: relative;
  padding: 0 32px;
  @include breakpoint-down('md') {
    padding: 0 1rem;
  }
  .tableUnCollectList {
    height: calc(
      var(--vh) - #{$headerHeight} - 120px - #{$bottomSmallHeight}
    ) !important;

    @include breakpoint-down('md') {
      overflow: auto;
    }

    @include breakpoint-down('md') {
      height: calc(
        var(--vh) - #{$headerHeight} - 112px - #{$bottomSmallHeight}
      ) !important;
    }
  }

  thead {
    background-color: $grey-primary800 !important;
    border-right: 3px solid $grey-primary700;

    tr {
      height: $theadTableHeight;

      th {
        height: auto;
        padding: 16px;
        color: $grey-primary100;
        text-align: right;
        border: 1px solid $grey-primary700 !important;

        @include breakpoint-down('md') {
          padding: 4px 16px;
        }
      }
    }
  }

  tbody {
    background: $grey-primary900;
    border-right: 3px solid $grey-primary700;

    tr {
      height: $tbodyTableHeight;

      td {
        height: auto;
        padding: 16px;
        color: $grey-primary50;
        text-align: right;
        border: 1px solid $grey-primary700;
      }
    }
  }

  tr {
    th {
      border: 1px solid $grey-primary700 !important;
      font-size: 0.875rem;
      font-weight: 700;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }

      .checkAll {
        color: $grey-primary100;
        cursor: pointer;
      }
    }

    td {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 400;
      padding: 0.25rem;
      vertical-align: middle;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .selection {
      min-width: 70px;
      text-align: center;
      cursor: pointer;
    }

    .description {
      position: relative;
      width: 40%;
      min-width: 200px;
      text-align: left;
      flex-grow: 1;
    }

    .descriptionContainer {
      display: flex;
      align-items: center;
    }

    .itemName {
      text-align: left;
      flex-grow: 1;
    }

    .dropDownBntContainer {
      position: relative;
      text-align: right;
      flex-grow: 1;
    }

    .checkAllContainer {
      padding-left: 7px;
      padding-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .weight {
      width: 15%;
      min-width: 100px;
      word-break: break-all;
    }

    .tenant {
      width: 30%;
      min-width: 100px;
    }

    .datetime {
      width: 20%;
      min-width: 100px;
    }

    .msgError {
      text-align: center;
      line-height: 26px;
      padding: 0 16px;
      font-size: 0.9em;
    }

    .unCollectContent {
      display: flex;
      gap: 8px 16px;
      flex-wrap: wrap;

      & > p {
        line-height: 1.5rem;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }

  .unCollectFilter {
    padding: 0 0 8px;
    display: flex;
    gap: 4px 8px;

    @include breakpoint-down('md') {
      padding: 0;
      flex-wrap: wrap;
    }
  }

  .filterWrapper {
    display: flex;
    gap: 4px 8px;
    flex-wrap: wrap;
    width: calc(100% - 120px - 8px);

    @include breakpoint-down('md') {
      width: 100%;
    }
  }

  .DateRangePicker {
    height: 44px;
    margin-bottom: 12px;

    @include breakpoint-down('md') {
      height: $buttonTinyHeight;
      margin-bottom: 8px;
    }
  }

  [class^='SelectFieldMultipleChoise_selectMultipleField'],
  div:has(> .DateRangePicker) {
    [class^='SelectFieldMultipleChoise_input'] {
      border: none;

      @include breakpoint-down('md') {
        height: $buttonTinyHeight;
      }
    }

    [class^='styles_valueWrapper'] {
      min-height: 0;
    }

    [class^='SelectFieldMultipleChoise_select'] {
      @include breakpoint-down('md') {
        margin-bottom: 6px;
      }
    }
  }

  div:has(> .DateRangePicker) {
    @include breakpoint-down('sm') {
      margin-right: 0;
    }
  }

  .unCollectFilterBox {
    flex: 1;
    max-width: calc((100% - 24px) / 4);
    width: 100%;

    @include breakpoint-down('md') {
      flex: unset;
      max-width: calc((100% - 8px) / 2);
    }
  }

  .buttonApply {
    min-width: 120px;
    height: 44px;
    background-color: $main400;
    color: $grey-primary900;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    font-weight: bold;
    margin: 0;

    &:active {
      background-color: $main600;
    }

    @include breakpoint-down('md') {
      height: $buttonTinyHeight;
    }

    @include breakpoint-down('sm') {
      width: 100%;
      margin-bottom: 8px;
    }
  }
  .buttonApply:disabled {
    color: $main600;
    cursor: not-allowed;
    background-color: $main400 !important ;
  }
}
