// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

@mixin container() {
  padding: 24px 16px;
  border-radius: 12px;
}

[class^='main'] {
  overflow: auto !important;
}

.wrapper {
  height: calc(var(--vh) - 68px);
  overflow-y: auto;
  overflow-x: hidden;
}

.purchaseResourceSearch {
  @include flex($direction: column);
  padding: 20px 20px 42px;
  gap: 20px;

  .content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
    color: $grey-primary50;
    text-align: start;
    align-self: normal;
    white-space: pre;
  }

  .analysis {
    width: 100%;
    @include flex($direction: row, $items: flex-start);
    gap: 20px;

    &_left {
      @include flex($direction: column);
      width: calc(100% - 373px);
      gap: 19px;

      &_top {
        width: 100%;
        @include flex($direction: row);
        gap: 20px;

        &_label {
          @include flex($direction: column);
          gap: 16px;

          &_container {
            width: 177px;
            height: 152px;
            background: $grey-primary800;
            word-break: break-all;
            
            @include container();
          }
        }
      }

      &_bottom {
        @include flex($direction: column, $justify: flex-start);
        @include container();
        box-sizing: border-box;
        width: 100%;
        min-height: 402px;
        gap: 16px;
        background: $grey-primary800;

        &_header {
          @include flex($justify: space-between);
          width: 100%;
          height: 40px;

          &_text {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            color: $grey-primary50;
          }

          &_description {
            @include flex($direction: row, $items: flex-start);
            gap: 10px;

            .unit {
              font-size: 12px;
              font-weight: 700;
              line-height: 16px;
              color: #e7e7e7;
            }

            &_space {
              @include flex($justify: space-between);
              padding: 0 2px;
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              color: #e7e7e7;
            }
          }
        }

        &_heatmap {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  input {
    background: transparent;
    color: $grey-primary300;
  }

  [class^='InputTagField_mainFrame'] {
    background: $login-input-bg;
    border: none;
  }
}

.modal {
  @include flex($direction: column);
  padding: 16px 32px 34px;
  gap: 16px;

  .selectContainer {
    @include flex($justify: flex-end);
    width: 100%;

    .select {
      width: 188px;

      [class^='SelectField_select'] {
        margin-bottom: 0 !important;
      }
    }
  }
}
