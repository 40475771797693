// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.PoolDashboardFilter {
  .Form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;

    & > div {
      width: calc((100% - 24px) / 4);
      border: none;
    }
  }

  .Submit {
    background-color: #E7E7E7;
    border-radius: 4px;
    height: 44px;
    margin: 0;
    min-width: 80px;
    padding: 0 24px;
    border: none;
    font-weight: 700;
    font-size: 14px;
  }

  .DateRangeField > div {
    height: 44px;
  }

  [class^='SelectField_select'],
  [class^='SelectFieldMultipleChoise_select'] {
    margin-bottom: 0;
  }

  [class^='SelectFieldMultipleChoise_input'] {
    border: none;
  }
}
