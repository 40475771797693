// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.bottom-field {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: $grey-primary900;
  box-shadow: 0px -1px 12px $primary900;
}
