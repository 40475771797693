// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

#admin-main {
  width: 100%;
  display: -webkit-flex;
  -webkit-align-items: top;
  display: flex;
  align-items: top;
  -webkit-justify-content: left;
  justify-content: left;
  height: 100vh;
  border: none;

  .side-nav {
    width: 270px !important;
    max-height: 100vh;
    overflow-y: auto;
    background-color: $primary400;
  }

  .admin-contents {
    flex: 1;
    background-color: $primary100;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $crollColor;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &__header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: $main-content-header-z-index;
    }

    &__main {
      margin: 16px;
      padding: 32px 24px;
      background-color: $background-white;

      &__header {
        @include flex($items: center, $justify: space-between);

        &__title {
          font-size: 20px;
          line-height: 28px;
          color: $primary600;
          text-align: left;
          word-break: break-all;
        }
      }

      &__body {
        margin-top: 20px;
      }
    }

    &__footer {
      a.back-link {
        font-size: 14px;
        line-height: 20px;
        text-decoration-line: underline;
        color: $primary600;
      }
    }
  }

  .flex {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .download {
    position: absolute;
    top: 30px;
    left: 10px;
    display: flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
    z-index: 90;
    cursor: pointer;
  }

  .search-area {
    @include flex($justify: space-between);

    width: 100%;
    height: 44px;

    .search-input {
      display: flex;
      min-width: 320px;
      width: 30%;
      position: relative;

      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid $primary200;
        padding: 11px 15px;
        font-size: 14px;
        line-height: 20px;
        color: $primary500;
        outline: none;
        padding-right: 36px;

        &:focus {
          border: 1px solid $primary600;
        }
      }

      .icon {
        text-align: right;
        position: absolute;
        top: calc(50% - 8px);
        right: 16px;
        width: 16px;
        height: 16px;

        svg {
          width: 100%;
          height: 100%;

          path {
            fill: $primary300;
          }
        }
      }
    }

    .button {
      @include flex();

      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      color: $primary600;

      span {
        margin-left: 8px;
      }
    }
  }

  .csv-button {
    @include flex($items: center);

    background-color: $primary50;
    border: none;
    color: $primary600;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    padding: 0;
    margin: 0;

    &:disabled {
      border: solid 1px $disabled-color;
      background-color: $disabled-color;
    }

    svg {
      margin-right: 8px;

      > path {
        stroke: $primary600;
      }
    }
  }

  .count-list {
    font-size: 1rem;
    line-height: 1.5em;
    text-align: left;
  }

  div.scroll-wrapper {
    width: 100%;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    table-layout: fixed;
    margin-top: 20px;

    table {
      border-collapse: collapse;
      table-layout: fixed;
      display: block;
      width: 100%;
      height: auto;
      overflow: auto;

      thead {
        display: table;
        width: 100%;
        background-color: $background-white;
        font-weight: bold;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 10;

        tr {
          border-bottom: 1px solid $primary300;

          th {
            padding: 11px 0 12px;
            font-size: 14px;
            line-height: 20px;
            color: $primary600;
          }
        }
      }

      tbody {
        width: 100%;
        display: table;
        background-color: $background-white;
        font-size: 15px;
        overflow: auto;

        tr {
          border-bottom: 1px solid $primary100;

          td {
            padding: 19px 20px 12px 0;
            font-size: 14px;
            line-height: 20px;
            color: $primary600;

            &.msg-error {
              width: 100%;
              text-align: center;
              padding: 20px !important;
            }
          }
        }
      }

      td,
      th {
        word-wrap: break-word;
        text-align: left;
      }
    }
  }

  div.admin-children {
    margin-top: 16px;
    padding: 0px 24px 24px 24px;
    background-color: $background-white;
  }

  &.recycle_chain_page {
    .admin-contents__main {
      min-height: calc(100vh - 88px);
      box-sizing: border-box;

      .admin-contents__main__body {
        height: calc(100% - 60px);
      }
    }
  }
}
