// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.text-button {
  text-align: center;
  font-weight: bold;
  padding: 7px 24px;
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  font-size: 20px;
  margin: 20px 0;
}

.text-button {
  text-align: center;
  font-weight: bold;
  padding: 7px 24px;
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  font-size: 20px;
  margin: 15px 0;
}

button.text-button.default {
  color: white;
  background-color: $primary-color;
  border: solid 1px $primary-color;
}
button.text-button.default:disabled {
  border: solid 1px $disabled-color;
  background-color: $disabled-color;
}
button.text-button.white {
  color: black;
  background-color: white;
  border: solid 1px $border-deep-color;
  margin-top: 0px;
}

button.text-button.white:disabled {
  border: solid 1px $disabled-color;
}

#scale-contents {
  .text-button {
    padding: 10px 0;
    border-radius: 45px;
    font-size: 20px;
  }
}
