// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
#scale-login {
  .logo {
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: solid 8px $primary-color;
    color: $primary-color;
    font-size: 40px;
    font-weight: bold;
  }

  .login-form {
    padding-top: 5%;
    .text-button {
      margin-top: 12%;
    }
  }

}

