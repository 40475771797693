// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.tab-menu {
  overflow: hidden;
  border-bottom: 1px solid $primary300;

  .tab-item {
    border-bottom: none !important;
  }
}

.tab-items {
  display: flex;

  .tab-item {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 16px 16px 15px;
    color: $primary300;
    background-color: $background-white;
    min-width: 120px;
    border: 1px solid $primary300;

    &:not(:first-child) {
      margin-left: 4px;
    }
  
    &.active {
      color: $background-white;
      background-color: $primary400;
    }
  }
}

.hidden {
  display: none;
}
