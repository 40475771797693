// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.options {
  @include flex($justify: start, $items: start);
  &.optionsVertical {
    flex-direction: column;
    .radioItem {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
  &.optionsHorizontal {
    flex-direction: row;
    margin-top: 12px;
    .radioItem {
      margin-right: 12px;
    }
  }
}
