// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.invoiceEditMailForm {
  display: flex;
  justify-items: center;
  margin: auto;

  .formInput {
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }
  }
  
  .msgError {
    text-align: center;
    line-height: 26px;
    padding: 0 16px;
    font-size: 0.9em;
    color: $redRequired;
  }
  
  .inputFrame {
    flex: 1 1 0%;
    > * {
      margin: 12px 0;
    }

    .required {
      text-align: left;
      font-weight: bold;
      font-size: 14px;
      span {
        color: $redRequired;
      }
    }
  }

  .emailField {
    input {
      padding: 12px 30px 12px 12px;
    }
    width: 100%;
    position: relative;
    .deleteIcon {
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
    }
    margin-bottom: 8px;
  }
  .email {
    @include flex($justify: center);
  }
  
  .addEmailField {
    div {
      @include flex($justify: end);
      div {
        cursor: pointer;
        p {
          margin-left: 8px;
          color: $primary600;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }

  .addEmailFieldDisabled {
    div {
      @include flex($justify: end);
      div {
        svg>path {
          stroke: $primary200;
        }
        cursor: no-drop;
        p {
          margin-left: 8px;
          color: $primary200;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }

  .textAreaField {
    textarea {
      resize: none;
      height: 240px !important;
    }
  }
  .previewBody {
    min-height: 230px;
    margin-top: 8px;
    border: 1px solid $primary200;
    border-radius: 4px;
    width: 100%;
    color: $primary600;
    padding: 12px;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    white-space: pre-wrap;
    text-align: left;
    word-break: break-all;
    cursor: pointer;
    a {
      color: $mariner;
      text-decoration: underline;
    }
    
  }
  .submit {
    margin-top: 4px;
    padding: 10px 0;
  }
  
  .submitButton {
    height: 44px;
    width: 100%;
    background-color: $primary400;
    border-radius: 4px;
    border: none;
    color: white;
    font-weight: bold;

    &:disabled {
      border-radius: 4px;
      border: none;
      color: $primary200;
      font-weight: bold;
      height: 44px;
      width: 100%;
      cursor: not-allowed;
      background-color: $primary400 !important ;
    }
  }
}


.previewError {
  border-color: $function300 !important;
}

.hidden {
  display: none;
}

.error {
  font-size: 14px;
  color: $error-border-color;
  text-align: left;
  margin-top: 8px;
}
