// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.textInput {
  position: relative;
  // margin: 2em auto;
  > div {
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
  .textInputWrap {
    position: relative;
    &.textInputWrapHasSubfix input {
      padding-right: 34px;
    }
  }
  input {
    height: 44px;
    padding: 12px;
    border: 1px solid $grey-primary100;
    border-radius: 4px;
    width: 100%;
    color: $grey-primary600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    box-sizing: border-box;

    &:disabled {
      background-color: $grey-primary75;
      color: $grey-primary600;
    }

    &:focus:not(.focus-visible) {
      border: 1px solid $grey-primary600;
      outline: none;
      box-shadow: none;
    }

    &.endExtraTextInput {
      padding-right: 67px;
    }
  }
  p {
    margin-top: 4px;
    color: #e9081e;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
  }
  .error {
    font-size: 14px;
    color: #e9081e;
    font-weight: 400;
  }

  &.darkMode {
    input {
      padding: 10px 24px 10px 10px;
      background-color: $login-input-bg;
      border: 1px solid transparent;
      color: $grey-primary50;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }

      &:disabled {
        border-color: #6f6f6f !important;
        background-color: #6f6f6f !important;
        color: #b7b7b7 !important;
        cursor: not-allowed;
      }

      &::placeholder {
        color: #b7b7b7 !important;
      }
    }
  }
}
.description {
  font-size: 12px !important;
  color: $grey-primary300;
  font-weight: 400 !important;
}

.endExtraText {
  position: absolute;
  right: 12px;
  bottom: 12px;
  font-weight: 400;
}

.endExtraTextDarkMode {
  color: $grey-primary300;
}
