// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionType {
  padding-bottom: 10px;
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px 20px 0px;
}

.emissionContent {
  @include flex($direction: column);
  
  @include breakpoint-down('md') {
    align-items: start !important;
  }

  height: 100%;

  p {
    font-weight: 700;
    line-height: 40px;
    color: $grey-primary50;
    @include breakpoint-down('md') {
      text-align: left;
    }
  }

  .customizeItemQuantity {
    font-size: 24px;
    line-height: 40px;
    text-align: start;
    color: $grey-primary50;
    @include ellipseText(1);

    @include breakpoint-down('md') {
      font-size: 18px;
      line-height: 28px !important;
      font-weight: 400;
      color: $grey-primary50;
    }
  }

  .ellipseText1 {
    font-size: 18px;
    line-height: 28px !important;
  }
}

p.error {
  text-align: right;
  padding-right: 30px;
}

.item {
  padding: 16px;
  box-sizing: border-box;
  transition: all 300ms ease;
  width: 25%;
  aspect-ratio: 1 / 1;
  @include breakpoint-down('lg') {
    width: 33.33333%;
  }
  @include breakpoint-down('md') {
    width: 100%;
    padding: 0;
    aspect-ratio: auto;
  }

  &.itemBig {
    p {
      @include ellipseText(2);
      @include breakpoint-down('lg') {
        @include ellipseText(2);
      }
      @include breakpoint-down('md') {
        font-size: 18px !important;
        line-height: 28px !important;
      }

      font-size: 30px;
      line-height: 44px;
      word-break: break-word !important;
    }
  }

  &.itemMedium {
    aspect-ratio: 2 / 1;
    width: 100%;

    @include breakpoint-down('md') {
      aspect-ratio: auto;
    }
  }

  &.itemSmall {
    width: 50%;

    @include breakpoint-down('md') {
      width: 100%;
    }
  }

  &.itemSmall,
  &.itemMedium {
    p {
      @include ellipseText(2);
      @include breakpoint-down('md') {
        @include ellipseText(2);
        font-size: 18px;
        line-height: 28px;
      }

      font-size: 20px;
      line-height: 32px;
    }
  }

  &_content {
    @include disable-select();

    height: 100%;
    position: relative;
    border-radius: 12px;
    padding: 16px;
    box-sizing: border-box;
    background-color: $bgGrey;
    cursor: pointer;
    
    @include breakpoint-down('lg') {
      padding: 8px;
    }
    @include breakpoint-down('md') {
      height: 81px;
      padding: 12px;
    }
  }

  .buttonToggleSelectSize {
    position: absolute;
    top: 10px;
    right: 16px;

    @include breakpoint-down('lg') {
      top: 8px;
      right: 8px;
    }

    .buttonClick {
      @include flex();

      width: 16px;
      height: 16px;
    }
  }

  .selectSize {
    position: absolute;
    z-index: 2;
    top: calc(100% + 8px);
    right: 0;
    background: white;
    border: 0.5px solid rgba(209, 209, 209, 0.5);
    box-shadow: 2px 2px 8px rgba(74, 119, 140, 0.1);
    border-radius: 4px;

    .selectSizeList {
      min-width: 180px;
    }

    .selectSizeItem {
      padding: 18px 24px;
      @include flex($justify: start);

      span {
        margin-left: 8px;
      }

      &.active {
        background: $background-primary;
      }
    }
  }

  .optionsArea {
    position: absolute;
    z-index: 0;
    top: 12px;
    right: 12px;

    .itemType {
      color: $grey-primary50;
      font-size: 14px;
      line-height: 20px;
      background-color: $main500;
      border-radius: 4px;
      padding: 5px;

      @include flex();
      
      @include breakpoint-down('md') {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
      }

      svg path {
        fill: $black;
      }
    }
  }

}
