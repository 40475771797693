// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyCollected {
  max-height: calc(var(--vh) - #{$headerHeight + $tabHeight});

  .historyCollectedCard {
    display: flex;
    padding: 12px 0;
    box-shadow: 0px 1px 0px $primary500;

    [class^='label-contents'] {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      padding: 4px 16px;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .historyCollectedContentCol {
    flex: 1;
  }


  .historyCollectedActionCol {
    width: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
