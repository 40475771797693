// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.confirmModal {
  position: relative;

  [class^='modal'] {
    @include flex($items: center, $justify: center);
    overflow: auto;
  }

  [class^='content'] {
    width: 592px;
    margin: auto;
    background-color: $grey-primary900;
    border-radius: 4px;
    @include breakpoint-down('md') {
      width: calc(100% - 32px);
    }
  }

  .modalContent {
    .top {
      position: relative;
      padding: 2rem 2rem 1rem 2rem;
      box-shadow: 0px 1px 0px $primary600;
      @include flex($items: center, $justify: space-between);
      @include breakpoint-down('md') {
        padding: 24px 60px 16px 16px;
      }

      .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
        color: $grey-primary50;
        text-align: left;
        @include breakpoint-down('md') {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .closeIcon {
        position: absolute;
        right: 32px;
      }
    }

    .body {
      .emissionBox {
        position: relative;
        width: 200px;
        margin: 16px auto;

        .emissionImage {
          width: 100%;
          aspect-ratio: 1/1;
          border-radius: 12px;
          object-fit: cover;
        }

        .type {
          font-size: 0.875rem;
          line-height: 1.25rem;
          font-weight: 700;
          color: $grey-primary50;
          box-shadow: 2px 2px 8px $dark300;
          border-radius: 4px;
          padding: 2px 8px;
          min-width: 44px;
          height: 24px;
          box-sizing: border-box;
          position: absolute;
          top: 12px;
          left: 12px;
          @include flex();
        }

        .emissionType {
          color: $grey-primary50;
          font-size: 14px;
          line-height: 20px;
          background-color: $main400;
          border-radius: 4px;
          padding: 5px;
          width: 25px;
          @include flex();
          svg {
            path {
              fill: $black;
            }
          }
        }
      }

      .confirmInfo {
        width: 100%;
        margin: 0 auto;
        text-align: left;
        @include flex($items: center, $direction: column);

        .confirmInfoRow {
          width: 50%;
          padding: 6px 0;
          font-size: 16px;
          line-height: 24px;
          color: $grey-primary50;
          box-sizing: border-box;

          @include flex($items: left, $justify: center);
          @include breakpoint-down('md') {
            font-size: 14px;
            line-height: 20px;
            width: 100%;
            padding: 6px 16px;
          }

          .confirmInfoLabel {
            min-width: 25%;
            text-align: left;
            padding-right: 10px;
            font-weight: 400;
            word-break: break-word;

            @include breakpoint-down('md') {
              width: 50%;
            }
          }
          .confirmInfoContents {
            width: 75%;
            text-align: right;
            font-weight: 400;
            @include truncate-oneline();

            @include breakpoint-down('md') {
              text-align: left;
              width: 50%;
            }
          }
        }
      }
    }

    [class^='modal-bottom-field'] {
      padding: 1.5rem 2rem 2rem;
      width: 50%;
      margin: auto;
      box-sizing: border-box;
      gap: 16px;

      &.fullWidth {
        width: 100%;
      }

      @include breakpoint-down('md') {
        width: auto;
        padding: 16px 16px 24px;
        flex-direction: column-reverse;
      }

      .submitButton,
      .finishButton {
        padding: 0;
        width: 50%;

        @include breakpoint-down('md') {
          width: 100%;
        }

        button {
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          height: $buttonMediumHeight;
          padding: 4px;
          margin: 0;
          background-color: $main400;
          color: $grey-primary900;
          border-radius: 4px;
          border: none;
          box-sizing: border-box;

          &:focus {
            background-color: $main600;
          }

          @include breakpoint-down('md') {
            padding: 8px;
          }
        }
      }

      .finishButton button {
        color: $grey-primary50;
        background-color: $grey-primary900;
        border: 1px solid $grey-primary50;

        &:focus {
          background-color: $grey-primary900;
        }
      }
    }
  }

  .alertInfo {
    margin: 20px auto;

    &.errors {
      line-height: 24px;
      color: $function300;
    }
  }
}
