// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.DonutExtendedModalWrapper {
  overflow-y: hidden;
  display: flex;

  [class^='contents'] {
    max-width: 1200px;
    margin: auto;
  }
}

.DonutExtendedModal {
  background-color: $grey-primary900;
  margin: auto;
  max-height: 86vh;
  overflow: auto;
  @include customScroll();

  .ModalTitle {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 16px 32px;
    border-bottom: 1px solid $grey-primary700;
    margin-bottom: 16px;
    gap: 16px;

    & > p {
      color: $grey-primary50;
      font-weight: 700;
      font-size: 20px;
    }
  }

  .ModalBody {
    padding-bottom: 48px;

    @include breakpoint-down('md') {
      padding-bottom: 16px;
    }
  }

  .Charts {
    gap: 32px;
    @include flex($justify: space-between, $items: flex-start);
    align-items: stretch;
    padding: 16px 32px 0;

    @include breakpoint-down('md') {
      @include flex($direction: column);
      gap: 16px;
      align-items: unset;
      padding: 0;
    }

    .Donut {
      flex: 1;

      @include breakpoint-down('md') {
        width: 100%;
      }
    }

    .Bar {
      flex: 1;
      padding-right: 32px;

      @include breakpoint-down('md') {
        width: calc(100% - 16px);
        padding: 0 8px;
      }
    }

    .DonutBox,
    .BarBox {
      display: flex;
      flex-direction: column;
      background-color: $grey-primary800;
      padding: 16px;
      border-radius: 12px;

      p {
        font-size: 22px;
        line-height: 28px;
        font-weight: 700;
        color: $grey-primary50;
        text-align: left;
      }

      .heading {
        @include flex($justify: space-between);
        margin-bottom: 16px;

        @include breakpoint-down('md') {
          padding: 0 8px;
        }

        &.noHeading {
          @include flex($justify: flex-end);
        }

        .headingBox {
          text-align: left;
        }

        .buttonDownload {
          cursor: pointer;
          width: 36px;
          height: 36px;
          border-radius: 4px;
          box-sizing: border-box;
          border: 1px solid $grey-primary600;
          @include flex();
        }
      }

      @include breakpoint-down('md') {
        width: 100%;
        padding: 0;
        background-color: unset;
      }
    }
  }

  .LegendArea {
    display: grid;
    margin: 32px auto 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row dense;
    grid-gap: 12px;
    max-width: 740px;
    padding: 0 16px;

    @include breakpoint-down('md') {
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0 8px;
    }
  }

  .Legend {
    display: flex;
    grid-column-start: 1;
    align-items: flex-start;

    div {
      height: 100%;
    }
  }

  .Legend2 {
    grid-column-start: 2;
  }

  .LegendColor {
    border-radius: 2px;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    min-width: 12px;
    min-height: 12px;
    max-height: 12px;
    margin-top: 3px;
  }

  .LegendLabel {
    font-size: 16px;
    text-align: left;
    @include truncate-multiline(1);
    color: $primary50;
    word-break: break-all;

    @include breakpoint-down('md') {
      font-size: 12px;
    }
  }
}
