// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

#scale-contents {
  margin: 0 auto;
  width: 70%;

  .logo {
    &.h1 {
      width: 100px;
      height: 100px;
      border-width: 4px;
      color: $primary-color;
      font-size: 20px;

    }
  }

  @include mq('lg') {
    padding: 0 15%;
    width: 70%;
  }
  .main {
    header {
      padding: 50px 0;
      border-bottom: none;

      h1 {
        padding-top: calc(var(--vh) - (var(--vh) * 0.95));
        font-size: 45px;
        font-weight: bold;
      }

      .prev-button {
        position: absolute;
        top: 0px;
        left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 90;
        cursor: pointer;
        a {
          font-size: 18px;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
    div.description {
      font-size: 30px;
      margin-bottom: 50px;
    }

    .scale-select {
      > div {
        width: 300px;
      }
      &.column-2 {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between; /*均等に間隔をあける*/
        > div {
          flex-wrap: wrap;
          width: 45%;
        }
      }

      .scale-select-card {
        margin-top: 25px;
        padding: 25px 0;
        width: 100%;
        border-radius: 8px;
        border: solid 2px black;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        font-size: 25px;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
        &.operator-card {
          color: $primary-color;
          background-color: #F2F2F2;
          border-color: $primary-color;
        }
        &.waste-card {
          background-color: #F2F2F2;
          border-color:  #DFDFDF;
          max-width: 48%;
          margin: 35px auto;
          div.img {
            width: 40%;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            text-align: center;
            img {
              max-height: 75px;
              max-width: 80%;
              border: solid 1px $border-deep-color;
            }
          }

          div.name {
            text-align: left;
            width: 60%;
          }
        }
      }
    }

  }



}
