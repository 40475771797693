// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  padding: 0 32px 32px;
  @include flex();

  [class^='SelectField_selectField'],
  [class^='InputDataListField_textInput__'] {
    margin-top: 16px;
  }
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 24px 0;

  > * {
    margin: 12px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.submit {
  margin: 0;
}

.submitButton, .disableButton {
  margin: 0;
  height: $buttonSmallHeight;
  background-color: $main400;
  border: none;
  border-radius: 4px;
  border: none;
  color: $grey-primary900;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;

  &:active {
    background-color: $main600;
  }
}

.disableButton {
  color: $main600;
  cursor: not-allowed;
  background-color: $main400 !important ;
}

.industryDesc {
  color: $grey-primary75;
  font-size: 12px;
  text-align: left;
  margin-top: 8px;
}

.labelICCard {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-top: 16px;
}

.icCardField {
  width: 100%;
  position: relative;

  .deleteIcon {
    color: $grey-primary100;
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }
  margin-bottom: 8px;
}

.addICCardField,
.btnBarcodeNew {
  div {
    @include flex($justify: end);
    margin-top: 8px;

    div {
      cursor: pointer;

      p {
        margin-left: 8px;
        color: $main400;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
