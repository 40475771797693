// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.dashboardGeneralArea {
  width: 80%;
  height: 148px;
  @include flex($justify: start);
  @include breakpoint-down('lg') {
    width: 100%;
    overflow: auto;
  }
  gap: 16px;

  &.adminSite {
    height: 200px;
  }
  .generalItem {
    height: 100%;
    width: 20%;
    @include breakpoint-down('lg') {
      width: calc(50% - 8px);
      min-width: calc(50% - 8px);
    }
    .generalItemWraper {
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      background-image: url('./general-item-wrapper-bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-color: $bgWhiteOpacity;
      backdrop-filter: blur(5px);
      margin-right: 16px;
      border-radius: 12px;
      text-align: center;
      padding: 16px;
      position: relative;

      @include flex($direction: column);
      .quantity {
        color: $primary75;
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        margin-bottom: 8px;
        margin-top: 10px;
        @include ellipseText(1);
      }
      .label {
        color: $primary300;
        font-size: 14px;
      }
    }
  }
}
