@import 'variables.scss';
@import 'mixin.scss';

.booking {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 336px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;
    @include breakpoint-down('sm') {
      height: auto;
    }
  }
  font-size: 16px;

  .notification {
    padding: 24px 0;

    @include flex($direction: column);
    gap: 24px;

    .description {
      color: $grey-primary50;
      line-height: 24px;
      padding: 0 24px;
    }

    .button {
      line-height: 24px;
      font-weight: 700;
      background-color: $function300;
      border-radius: 4px;
      border: none;
      color: $grey-primary900;

      &:focus {
        background-color: $function400;
      }
    }

    .button {
      width: 168px;
      height: 56px;
      color: $grey-primary50;
    }
  }
}
