// gkc_hash_code : 596AVJ0U6O4MG74XBQH62VSQ6T
@import 'variables.scss';

.coordinateInput {
  width: calc(100% - 28px);

  display: flex;
  justify-content: flex-end;

  input {
    height: 44px;
    padding: 12px;
    border: 1px solid $grey-primary600;
    border-radius: 4px;
    width: 100%;
    color: $primary300;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    box-sizing: border-box;
    background-color: $login-input-bg !important;

    &:disabled {
      background-color: $grey-primary75;
      color: $grey-primary600;
    }

    &:focus:not(.focus-visible) {
      border: 1px solid $grey-primary600;
      outline: none;
      box-shadow: none;
    }

    &.endExtraTextInput {
      padding-right: 67px;
    }
  }

  &.darkmode {
    input {
      padding: 10px 24px 10px 10px;
      background-color: $login-input-bg;
      border: 1px solid transparent;
      color: $grey-primary50;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }

      &:disabled {
        border-color: $grey-primary400 !important;
        background-color: $grey-primary400 !important;
        color: $grey-primary100 !important;
        cursor: not-allowed;
      }

      &::placeholder {
        color: $grey-primary100 !important;
      }
    }
  }
}
