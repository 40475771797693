@import 'variables.scss';
@import 'mixin.scss';

.expected {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 420px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;

    @include mq('xs') {
      width: 90%;
    }

    @include breakpoint-down('sm') {
      height: auto;
    }
  }

  .description {
    padding: 24px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .titleContainer {
      @include flex($justify: space-between);

      padding-bottom: 8px;
      border-bottom: 1px solid $grey-primary300;

      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
      }
    }

    .form {
      @include flex($direction: column, $items: flex-start);
      gap: 16px;

      *::-webkit-scrollbar {
        width: 10px;
      }

      *::-webkit-scrollbar-track {
        background: $grey-primary50;
      }

      *::-webkit-scrollbar-thumb {
        background-color: $grey-primary900;
        border-radius: 8px;
        border: 3px solid $background-white;
      }

      .formItem {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 110px;
        overflow-y: auto;
        overflow-x: hidden;

        .radio {
          display: flex;
          gap: 8px;

          cursor: pointer;

          .container {
            display: flex;
            align-items: center;
            gap: 8px;

            input[type='radio'] {
              --s: 1em; /* control the size */

              height: var(--s);
              aspect-ratio: 1;
              border: 1px solid $grey-primary400;
              padding: calc(var(--s) / 7);
              background: radial-gradient(farthest-side, $main400 94%, #0000)
                50%/0 0 no-repeat content-box;
              border-radius: 50%;
              outline-offset: calc(var(--s) / 10);
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              cursor: pointer;
            }

            input[type='radio']:checked {
              border-color: $main400;
              background-size: 100% 100%;
            }

            input[type='radio']:disabled {
              background: linear-gradient($grey-primary400 0 0) 50%/100% 20%
                no-repeat content-box;
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .bottom {
      width: 100%;
      @include flex();

      .button {
        padding: 12px 24px;

        color: $grey-primary900;
        font-size: 14px;
        font-weight: 700;

        margin: 0;
        border: none;
        border-radius: 4px;

        background-color: $main400;

        &:focus {
          background-color: $main500;
        }

        &:disabled {
          color: $main600;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  }
}
