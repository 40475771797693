// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.inputFrame {
  > div {
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
}

.textInput {
  border: 1px solid $primary200;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  padding: 0 12px;
  cursor: pointer;
  @include flex($items: center, $justify: center); 

  div {
    svg {
      margin-top: 2px;
    }
  }
}

.inputDarkMode{
  border-color: #6f6f6f !important;
  background-color: #6f6f6f !important;
  color: #B7B7B7 !important;
  cursor: not-allowed;

  &::placeholder {
    color: #B7B7B7 !important;
  }
}

.inputForm {
    background-color: $login-input-bg;
    border: none;
    height: 42px;
    border-radius: 4px;
    width: 100%;
    text-align: left;
    padding: 0 12px;
    margin-bottom: 0;
    cursor: pointer;
    @include flex($items: center, $justify: center); 

    div {
      svg {
        margin-top: 2px;
      }
    }
}

.iconDisabled {
  svg {
    width: 100%;
    height: 100%;

    path {
      stroke:#B7B7B7
    }
  }
}

.inputArea {
  flex: 1;
  
  input {
    width: 20px;
    height: 42px;
    border: none;
    outline: none;
    text-align: center;
    color: $primary600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    box-sizing: border-box;
  }
} 

.fieldDarkMode {
  flex: 1;

  input {
    max-width: 18px;
    color: #9ea0a2;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: none;
    outline: none;
    font-weight: 400;
    background-color: unset !important;

    &:disabled {
      border-color: #6f6f6f !important;
      background-color: #6f6f6f !important;
      color: #B7B7B7 !important;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #B7B7B7 !important;
    }
  }

  span {
    color: #9ea0a2;
    margin-right: 4px;
  }
}

.errorText {
  color: $error-border-color;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  margin-top: 4px;
}
