// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.loading-modal {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 40000;
  }
}

.loading-modal.black {
  background: $modal-background;
}

.loading-modal.white {
  background: rgba(255, 255, 255, 1);
}

.loading-modal.closed {
  display: none;
}
