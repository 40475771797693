// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

#admin-main {
  .side-nav {
    display: flex;
    flex-direction: column;

    .logo {
      @include flex($items: center, $justify: center);
      margin: 32px auto;
      width: 134px;
      height: 48px;
      margin-top: 58px;
      margin-bottom: 65px;
    }

    .side-nav-links {
      li {
        text-align: left;
        padding: 16px 32px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $background-white;
        cursor: pointer;

        &.active,
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        a {
          height: 100%;
          width: 100%;
          color: inherit;
        }

        &.has_submenu {
          position: relative;

          p {
            padding: 16px 32px;
            margin: -16px -32px;

            > svg {
              position: absolute;
              top: 2px;
              right: 16px;
              padding: 16px;

              path {
                stroke: $primary50;
              }
            }
          }

          ul {
            background-color: $primary400;
            margin: 16px -32px -16px;
            padding: 0 32px;
          }

          .submenu {
            padding-left: 20px;
            color: $background-white;
            opacity: 0.6;
            background-color: inherit;

            &.active,
            &:hover {
              opacity: 1;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .version {
    padding: 16px 8px;
    text-align: center !important;
    color: #878787;
    box-sizing: border-box;
    font-size: 14px;
  }
}
