// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customizeListCategory {
  .searchArea {
    margin-top: 20px;
    text-align: start;
  }

  .scrollWrapper {
    overflow: hidden;
  }
  
  table#customizeItemTable {
    max-width: 100%;
    max-height: calc(100vh - 264px);
    overflow: auto;

    tbody {
      tr {
        cursor: pointer;
  
        &:hover {
          opacity: 0.7;
        }

        .name {
          text-align: left;
          word-break: break-all;
          font-weight: bold;

          p {
            @include ellipseText(2);
            line-height: 20px;
          }
        }

        .contentName {
          text-align: left;

          p {
            @include ellipseText();

            line-height: 20px;
          }
        }

        .action {
          vertical-align: middle;
          padding: 8px 0 0 !important;

          .contentAction {
            @include flex($justify: flex-start);
  
            > div {
              cursor: pointer;
              height: 24px;
  
              &.actionEdit {
                margin-right: 24px;
              }
            }

            .disableButton {
              cursor: not-allowed !important;
            }
          }
        }
      }
    }

    tr {
      .name {
        width: 80%;
      }

      .action {
        width: 20%;
      }      
    }
  }

  table#customizeItemTable::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }
  
  table#customizeItemTable::-webkit-scrollbar {
    width: 5px;
  }
}
