// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.content {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: $grey-primary50;
  text-align: left;
  word-break: break-all;
  margin-bottom: 4px;
  @include flex($justify: start, $items: flex-start);
  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }
}
