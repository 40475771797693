// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.check-card {
  display: flex;
  padding: 8px 16px;
  border-bottom: solid 1px $primary500;
}

.check-card > div:first-child {
  width: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.check-card > div:last-child {
  width: 90%;
}
