// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.bottom-btn-field {
  @include flex();
  padding: 8px 0;

  button {
    &:disabled {
      color: $main600;
      background-color: $main400;
      cursor: not-allowed;
      border: none;
    }
  }
}
