// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.settingDeliveryModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    @include flex();
  }

  [class^='content'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;

    .modalContent {
      padding: 2rem;

      .customTitle {
        @include flex($justify: flex-end);

        .descriptionTitle {
          margin: auto;
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-weight: bold;
        }

        .top {
          @include flex($justify: flex-end);
        }
      }
    }
  }
}
