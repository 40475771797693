// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.row {
  cursor: pointer;
}

.operator {
  width: 20%;
}

.image {
  width: 20%;
}

.name {
  width: 30%;
}

.address {
  width: 20%;
}

.action {
  width: 10%;
}

.operator,
.image,
.name,
.address,
.action {
  vertical-align: middle;
  padding: 8px 20px 8px 0 !important;
}

.contentImage {
  height: 50px;
  width: 50px;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
}

.contentName {
  text-align: left;
  word-break: break-all;

  p {
    @include ellipseText(4);

    color: $primary600;
    line-height: 20px;
  }
}

.action {
  .contentAction {
    @include flex($justify: flex-start);

    > div {
      cursor: pointer;
      height: 24px;

      &.actionEdit {
        margin-right: 24px;
      }
    }

    .disableButton {
      cursor: not-allowed !important;
    }
  }
}
