// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.PoolDashboardDonutCharts {
  background-color: #2c2c2c;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .Filter {
    margin-right: 24px;
    max-width: 265px;

    [class^='SelectField_input'] {
      height: 36px;
    }

    & > p {
      font-size: 12px;
      color: $grey-primary300;
      text-align: start;
    }
  }

  .PoolDashboardExtendableDonutChart {
    flex: 1;
  }

  [class^='DonutChart_donut'] {
    padding: 0;
    height: 100%;

    [class^='DonutChart_headingBox'] p {
      font-size: 20px;
      line-height: 28px;

      @include breakpoint-down('xl') {
        font-size: 16px;
      }
    }
  }

  [class^='DonutChart_preview'] {
    max-width: 650px;
    width: 100%;
    flex: 1;
    justify-content: unset;
    margin: auto;
    gap: 64px;

    & > div {
      width: calc((100% - 64px) / 2);
    }
  }

  [class^='DonutChart_chart'] {
    height: 100%;
  }

  [class^='DonutChart_legendColum'] {
    max-height: 230px;
  }

  .NoteMessage {
    font-size: 10px;
    color: $grey-primary300;
    max-width: 650px;
    width: 100%;
    margin: auto;

    & > p {
      width: calc((100% - 64px) / 2);
      line-height: 16px;
    }
  }

  [class^='PreviewChartModal_body'] {
    gap: 16px;

    [class^='DonutChart_preview'] {
      max-width: unset;
    }

    [class^='PoolDashboardDonutCharts_NoteMessage'] {
      max-width: unset;

      & > p {
        width: calc(50% - 32px);
      }
    }
  }
}
