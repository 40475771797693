// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

#scale-contents {
  .scale-input {
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;

    .input-type {
      div {
        padding: 10px 0;
        font-size: 30px;
        font-weight: bold;
      }
      img {
        border: solid 1px $border-color;
        width: 65%;
      }
    }

    .input-field {

      .input-num {
        padding-top: 7%;
        padding-bottom: 10%;
        height: 80px;
        margin: 0 auto;
        font-size: 50px;
        font-weight: bold;

        input {
          text-align: center;
          outline: none;
          height: 80px;
          line-height: 80px;
          border: none;
          border-bottom: solid 3px $primary-color;
          width: 50%;
          font-weight: bold;
          font-size: 50px;
        }
      }
    }
  }

  .download-modal {
    .modal {
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;

    }

    .title {
      padding-top: 80px;
      font-size: 30px;
    }
    .body {
      margin-top: 50px;
      font-size: 18px;
    }

    .bottom-btn-field {
      margin: 30px auto;
      width: 50%;
    }
  }
}
