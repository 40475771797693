// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.searchArea {
  margin-top: 20px;
  text-align: start;
}

.scrollWrapper {
  overflow: hidden;
}

table#customizeItemChildTable {
  max-width: 100%;
  max-height: calc(100vh - 352px);
  overflow: auto;

  tbody {
    tr {
      &:hover {
        opacity: 0.7;
      }

      td {
        padding: 11px 20px 8px 0 !important;
      }

      .tag {
        vertical-align: middle;

        span {
          padding: 4px 8px;
          background-color: $primary100;
          border-radius: 2px;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .branch {
        vertical-align: middle;
        word-break: break-all;

        p {
          @include ellipseText(4);

          line-height: 20px;
        }
      }

      .contentImageName {
        @include flex($justify: center);
      }

      .contentImage {
        height: 50px;
        width: 50px;
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      .contentName {
        text-align: left;
        width: calc(100% - 64px);
        margin-left: 12px;
        word-break: break-all;
        font-weight: bold;

        p {
          @include ellipseText(4);

          line-height: 20px;
        }
      }

      .action {
        vertical-align: middle;
        padding: 8px 0 0 !important;

        .contentAction {
          @include flex($justify: flex-start);

          > div {
            cursor: pointer;
            height: 24px;

            &.actionEdit {
              margin-right: 24px;
            }
          }
        }
      }
    }
  }

  tr {
    .image {
      width: 40%;
    }

    .tag {
      width: 15%;
    }

    .branch {
      width: 25%;
    }

    .action {
      width: 10%;
    }
  }
}

table#customizeItemChildTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#customizeItemChildTable::-webkit-scrollbar {
  width: 5px;
}

.createItemModal {
  [class^='modal'] {
    @include flex($justify: center);

    overflow: auto;
  }

  [class^='content'] {
    width: 480px;
    margin: auto;
    max-height: calc(100vh - 120px);
  }
}

.backToCategoryList {
  margin: 20px 16px;
  text-align: left;
}

