// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

$headerInvoiceDataHeight: 68px;

.exportDataInvoice {
  height: calc(var(--vh) - #{$headerHeight});
  background-color: $grey-primary900;
  padding: 24px 32px;
  box-sizing: border-box;

  @include breakpoint-down('md') {
    padding: 24px 16px;
  }

  .exportDataInvoiceManage {
    height: $headerInvoiceDataHeight;
    padding-bottom: 24px;
    box-sizing: border-box;

    @include breakpoint-down('md') {
      padding-bottom: 12px;
    }

    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      height: auto;
      @include flex($direction: column, $justify: start);
    }

    .countList {
      min-width: 100px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      text-align: left;

      @include breakpoint-down('md') {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 8px;
      }
    }

    .formInput {
      .inputFrame {
        gap: 8px;
        @include flex($justify: space-between);

        .inputFrameWrap {
          gap: 8px;
          @include flex($justify: end);
          @include breakpoint-down('md') {
            @include flex($justify: start);
          }

          .showItem {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: $grey-primary50;
            padding: 0 16px;

            @include breakpoint-down('md') {
              padding: 0;
            }
          }
        }
      }

      button {
        width: 36px;
        height: 36px;
        margin: 0;
        background-color: $grey-primary900;
        border: 1px solid$grey-primary600;
        border-radius: 4px;
      }
    }
  }

  .exportDataInvoiceTable {
    max-height: calc(100% - #{$headerInvoiceDataHeight});
    overflow-y: auto;

    table {
      width: 100%;
      max-width: 100%;
      border-collapse: collapse;
      table-layout: auto;

      &::-webkit-scrollbar {
        width: 0 !important;
        display: none;
      }

      .tenantName {
        width: 28%;
      }

      .tenantNameColSpan2 {
        width: 64%;
      }

      th,
      td {
        border: 1px solid $grey-primary700;
        min-width: 120px;
        width: 12%;
        text-align: center;
        padding: 16px;
        box-sizing: border-box;
        text-align: left;
        vertical-align: top;

        &.textAlignRight {
          text-align: right;
        }

        p {
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: $grey-primary50;
          display: inline-block;
          word-break: break-all;
        }
      }

      thead {
        position: sticky;
        top: -2px;
        z-index: 10;
        background-color: $grey-primary800 !important;
        color: $grey-primary100;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }
      }

      tbody {
        color: $grey-primary50;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        .msgError {
          text-align: center;
        }

        .itemAndTotal {
          padding: 0;

          .itemAndTotalItem {
            td {
              border-bottom: 0;

              &:first-child {
                border-left: 0;
              }

              &:last-child {
                border-right: 0;
              }
            }

            &:first-child {
              td {
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
