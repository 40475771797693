// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.containerCollectRegistration {
  height: calc(var(--vh) - #{$headerHeight});
  background-color: $grey-primary900;
  color: $grey-primary50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  [class^='checkbox'] {
    input[type='checkbox'] + label::before {
      background-color: $grey-primary900;
      border: 1px solid $grey-primary400;
    }
  }

  .bottomBtn {
    margin: 14px auto;
    @include flex($items: center, $justify: center);

    button {
      padding: 16px 32px;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      border: 1px solid $grey-primary50;
      height: $buttonMediumHeight;
      box-sizing: border-box;
      @include breakpoint-down('md') {
        padding: 10px;
        line-height: 18px;
      }
      margin-right: 24px;
      @include breakpoint-down('md') {
        margin-right: 16px;
      }
    }

    .btnEditing {
      height: $buttonMediumHeight;
      color: $grey-primary50;
      background-color: $grey-primary900;
      border: 1px solid $grey-primary50;
      border-radius: 4px;

      &:disabled {
        color: $grey-primary600;
        cursor: not-allowed;
        border: 1px solid $grey-primary600;
      }

      &:focus {
        border: 1px solid $grey-primary600;
      }

      @include breakpoint-down('md') {
        width: 100%;
      }
    }

    .btnRequest {
      height: $buttonMediumHeight;
      color: $grey-primary900;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      width: 172px;
      &:disabled {
        color: $main600;
        cursor: not-allowed;
        border: none;
      }

      &:focus {
        background-color: $main600;
      }

      @include breakpoint-down('md') {
        width: 100%;
      }
    }
  }

  .totalWrapper {
    font-size: 16px;
    line-height: 24px;
    color: $grey-primary50;
    background-color: $grey-primary900;
    padding: 8px 0;
    border-bottom: 1px solid $primary500;
    border-top: 1px solid $primary500;
    box-sizing: border-box;
  }

  .total {
    @include flex($justify: space-around);
    font-weight: bold;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .error {
    margin-top: 8px;
    color: $function300;
    padding: 0 16px;
  }

  .areaName {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 0;
    font-weight: bold;
    background-color: $grey-primary900;
    height: $tabHeight;
    box-sizing: border-box;
    width: 100%;

    @include flex($items: center, $justify: flex-start);

    .areaNameTitle {
      width: 15%;
      @include ellipseText(1);
      word-break: break-all;
    }

    .areaNameTitleFull {
      width: 100%;
      padding: 0 8px 0 0;
    }
  }

  .filter {
    width: calc(85% - 16px);
    max-width: 300px;
    padding-right: 16px;

    [class^='SelectField_select'] {
      margin-bottom: 0;

      & > input {
        background: transparent;
        color: #e7e7e7;
        border: 1px solid #ffffff1f;
        padding-left: 8px;
      }
    }
  }
}
