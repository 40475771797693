// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.page {
  height: 100%;
  height: 100vh;
  margin: 0;
  background-color: $grey-primary900;
}
.pageAdmin {
  background-color: white;
}
.pageBody {
  z-index: 2;
  position: absolute;
}
.pageNotFound {
  color: $primary75;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
  @include breakpoint-down('md') {
    width: calc(100% - 32px);
    padding: 16px;
  }
}

.pageNotFoundAdmin {
  color: $grey-primary600;

  .p404,
  .title,
  .description {
    color: $grey-primary600;
  }
}

.p404 {
  font-size: 100px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: $grey-primary50;
  @include breakpoint-down('md') {
    font-size: 80px;
  }
}

.title {
  font-size: 36px;
  font-family: 'Noto Sans JP';
  font-weight: bold;
  margin-top: 56px;
  line-height: 40px;
  color: $grey-primary50;
  @include breakpoint-down('md') {
    font-size: 24px;
    margin-top: 28px;
  }
}

.description {
  font-size: 18px;
  font-family: 'Noto Sans JP';
  font-weight: 400;
  margin-top: 32px;
  white-space: pre-wrap;
  line-height: 28px;
  color: $grey-primary50;
  @include breakpoint-down('md') {
    font-size: 16px;
    margin-top: 20px;
  }
}

.button {
  margin-top: 32px;
  width: 240px;
  height: $buttonMediumHeight;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  background-color: $main400;
  border-radius: 4px;
  border: none;
  color: $grey-primary900;

  &:focus {
    background-color: $main600;
  }
}

.buttonAdmin {
  color: white;
  background-color: $grey-primary600;

  &:focus {
    background-color: $grey-primary800;
  }
}

.bgr {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
