// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.totalWeightOfEachProcessFilter {
  .heading {
    @include flex($justify: space-between);

    p {
      font-weight: 700;
      color: $grey-primary50;
      font-size: 16px;
    }

    .actions {
      @include flex();

      .filter {
        margin-right: 16px;
        gap: 8px;
        @include flex($justify: space-between);

        button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
        }

        .filterButtonSelected {
          svg path {
            fill: $main400;
          }
        }
      }

      .filterDate {
        cursor: pointer;
        width: 276px;
        height: 44px;
        border-radius: 4px;
        box-sizing: border-box;
        border: 1px solid $grey-primary600;
        @include flex();

        & > div {
          width: 100%;
        }
      }
    }
  }
}
