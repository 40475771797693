@import 'variables.scss';
@import 'mixin.scss';

.recycleChainDesignDetailForm {
  .formInput {
    .inputFrame {
      display: flex;
      justify-content: center;

      .col1 {
        width: 60%;
        margin-right: 16px;

        .stepAdd {
          padding-top: 16px;

          .step,
          .nextStep {
            display: flex;
            .select {
              width: 65%;
            }
            span {
              width: 16px;
              margin: 8px 10px 0px 0;
              padding: 5px;
              background-color: $primary300;
              border-radius: 53%;
              color: $primary50;
              font-weight: 700;
              height: 16px;
            }
            .buttonDeleteAdd {
              @include flex();
              font-weight: bold;
              font-size: 14px;
              cursor: pointer;
              margin: 0 0 4px 4px;

              p {
                margin-left: 6px;
              }
            }
          }
        }
      }
      .col2 {
        width: 40%;

        .materialName {
          width: 100%;
          gap: 16px;
          @include flex($items: flex-start);

          & > div {
            width: 50%;
          }
        }

        .notes {
          padding: 16px;
          background-color: $primary75;
          border-radius: 6px;
          margin-top: 16px;
          p {
            font-size: 14px;
            text-align: left;
            font-weight: 700;
            margin-bottom: 12px;
            color: #4d4d4d;
          }

          li {
            text-align: left;
            margin-top: 8px;
            margin-left: 8px;
            font-size: 14px;
            line-height: 20px;
            color: #4d4d4d;
          }
        }
      }
    }

    .submit {
      padding: 24px 27px 0;
      margin: 0 -32px;
      text-align: left;
      width: 56%;
      margin-left: 4px;
    }

    .submitButton {
      width: 69%;
      height: 44px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-size: 14px;
      font-weight: 700;
      flex: 1;
      &:hover,
      &:active {
        background-color: $primary500;
      }
      &:disabled {
        color: $primary200;
        cursor: not-allowed;
        background-color: $primary400 !important ;
      }
    }
  }
}
