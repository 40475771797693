// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.text-input {
  position: relative;
  margin: 2em auto;
  > div {
    text-align: left;
    margin-bottom: 3px;
    font-size: 1.2em;
  }
  input {
    padding: 0px 5px;
    border: solid 1px gray;
    line-height: 2em;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
  }
  p {
    margin-top: 4px;
    color: $function300;
    text-align: left;
  }
}

#scale-contents {
  .text-input {
    input {
      height: 40px;
      line-height: 2em;
    }
  }
}
