// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();

  .formInput {
    width: 100%;
    padding: 24px 0;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }

    .inputFrame {
      flex: 1 1 0%;
      padding: 4px 0;
      gap: 12px;
      flex-wrap: wrap;

      @include flex($items: start, $justify: start);

      & > div {
        width: calc(50% - 6px);
      }

      .price {
        position: absolute;
        right: 12px;
        bottom: 14px;
        font-weight: 400;
      }
    }

    .noData {
      text-align: center;
      padding: 24px 0;
    }

    .submit {
      padding: 24px 66px 0;
      margin: 0 -32px;
      border-top: 1px solid $primary100;
      @include flex();

      .submitButton {
        height: 44px;
        width: 240px;
        background-color: $primary400;
        border-radius: 4px;
        border: none;
        color: $primary50;
        font-weight: 700;
        flex: 1;
        &:hover,
        &:active {
          background-color: $primary500;
        }
      }

      .prevButton {
        height: 44px;
        width: 240px;
        background-color: white;
        border-radius: 4px;
        border: none;
        color: $primary600;
        font-weight: 700;
        margin-right: 24px;
        border: 1px solid $primary400;
        flex: 1;
        &:hover,
        &:active {
          background-color: $primary300;
          color: white;
        }
      }

      .disableButton {
        border-radius: 4px;
        border: none;
        color: $primary200;
        font-weight: 700;
        height: 44px;
        width: 100%;
        cursor: not-allowed;
        background-color: $primary400 !important ;
      }
    }
  }
}
