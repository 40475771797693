// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tenantItem {
  height: 120px;
  margin: 10px;
  text-align: center;
  background-color: $bgGrey;
  border-radius: 12px;
  cursor: pointer;
  width: 220px;
  font-weight: bold;
  @include flex();
  @include breakpoint-down('md') {
    width: calc(50% - 8px);
    margin: 0;
  }

  p {
    padding: 0px 16px;
    color: $grey-primary50;
    font-size: 20px;
    line-height: 24px;
    word-break: break-all;
    @include truncate-multiline(2);
  }
}
