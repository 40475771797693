// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.PoolDashboardResourceMap {
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  .ActionButtons {
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 1;
  }

  table {
    .station {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    tr {
      &:first-child {
        td {
          padding-bottom: 8px;
        }
      }
    }

    .tdContent {
      max-width: 220px;
      white-space: pre-line;
      overflow-wrap: break-word;
      font-size: 14px;
      line-height: 20px;
      color: $grey-primary900;
      min-width: 100px;
      margin-right: 8px;
    }

    .pr {
      padding-right: 8px;
    }
  }
}
