// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.emission-new {
  background-color: $grey-primary900;
  box-sizing: border-box;
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow: auto;

  &__sort-emissions-drag-drop-section {
    height: calc(100vh - 64px);
  }
}
