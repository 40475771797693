// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';
.wasteChart {
  background-color: $grey-primary800;
  border-radius: 12px;
  padding: 10px;
  position: relative;
}
.body {
  padding: 0px 16px;
  @include breakpoint-down('md') {
    padding: 4px;
  }
}
.heading {
  padding: 22px 22px 0px 22px;
  @include breakpoint-down('md') {
    padding: 4px;
  }
  @include flex($justify: space-between);
  p {
    font-weight: 700;
    color: $grey-primary50;
    font-size: 16px;
  }
  .buttonDownload {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $grey-primary600;
    @include flex();
    @include breakpoint-down('md') {
      width: 42px;
      height: 30px;
      font-size: 14px;
    }
  }
}

.actions {
  @include flex();
}

.filter {
  margin-right: 70px;
  width: 210px;
  @include flex($justify: space-between);
  @include breakpoint-down('md') {
    width: 140px;
    margin-right: 40px;
  }
}

.filterButton {
  width: 62px;
  height: 36px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 4px;
  color: white;
  border: 1px solid $grey-primary600;
  background-color: $grey-primary800;
  @include breakpoint-down('md') {
    width: 42px;
    height: 30px;
    font-size: 0px;
    &::first-letter {
      font-size: 14px;
    }
  }
}

.filterButton:hover {
  background-color: $grey-primary50;
  color: $grey-primary900;
}
.filterButton:disabled {
  color: $primary200;
  cursor: not-allowed;
  background-color: $primary300 !important;
}
.filterButtonSelected {
  background-color: $grey-primary50;
  color: $grey-primary900;
}

.chartHidden {
  visibility: hidden;
  canvas {
    width: 100% !important;
    height: 0 !important;
  }
}
