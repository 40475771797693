// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.processInfoBox {
  width: 100%;
  height: 148px;
  gap: 20px;
  @include flex($justify: start);
  @include breakpoint-down('lg') {
    width: 100%;
    overflow: auto;
  }
  .generalItem {
    height: 100%;
    width: 25%;

    .generalItemWrapper {
      height: 100%;
      width: 100%;
      background-color: $bgWhiteOpacity;
      border-radius: 12px;
      color: $grey-primary50;
      text-align: center;
      padding: 16px;
      box-sizing: border-box;
      @include flex($direction: column);

      .label {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 4px;
      }

      .quantityUnit {
        @include flex($items: start);

        .quantity {
          font-size: 36px;
          font-weight: 700;
          line-height: 48px;
          @include ellipseText(1);
        }

        .unit {
          font-size: 20px;
          font-weight: 700;
          line-height: 36px;
        }
      }
    }
  }
}
