// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.prev-button {
  text-align: center;
  font-weight: bold;
  padding: 15px 50px;
  border-radius: 50px;
  white-space: nowrap;
  font-size: 20px;
  border: solid 1px black;

  a {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
  }
}

button.prev-button {
  background-color: white;
  font-weight: bold;
  padding: 10px 50px;
}
