// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.createBody {
  display: flex;
  justify-items: center;
  margin: auto;

  .formInput {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

  .msgError {
    text-align: center;
    line-height: 26px;
    padding: 0 16px;
    font-size: 0.9em;
    color: $redRequired;
  }

  .inputFrame {
    flex: 1 1 0%;
    padding: 24px 0;

    > * {
      margin: 12px 0;
    }
  }

  .submit {
    padding: 30px 30px 0;
    margin: 0 -32px;
    border-top: 1px solid $primary100;
  }

  .submitButton {
    height: 44px;
    width: 100%;
    background-color: $primary400;
    border-radius: 4px;
    border: none;
    color: white;
    font-weight: bold;
    margin: 0;

    &:hover,
    &:active {
      background-color: $primary500;
    }
  }

  .disableButton {
    border-radius: 4px;
    border: none;
    background-color: $primary400;
    color: $primary200;
    font-weight: bold;
    height: 44px;
    cursor: not-allowed;
  }
}
