// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  > * {
    margin: 12px 0;
  }

  input[type='time']::-webkit-calendar-picker-indicator {
    filter: invert(45%) sepia(2%) saturate(1036%) hue-rotate(185deg)
      brightness(96%) contrast(84%);
    margin-right: -4px;
  }
}

.submit {
  margin-top: 4px;
  padding: 10px 0px;
}

.submitButton {
  height: 44px;
  width: 100%;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: bold;
  font-size: 14px;

  &:hover,
  &:active {
    background-color: $primary500;
  }
}

.submitButton:disabled {
  color: $primary200;
  cursor: not-allowed;
  background-color: $primary400 !important;
}

.label {
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-top: 24px;
}

.quantity {
  display: flex;
  flex-direction: row;
}

.quantityFieldWidth {
  width: 50%;
  [class^='text-input'] {
    margin: 0;
  }
}
.space {
  margin-left: 12px;
}

.emailField {
  width: 100%;
  position: relative;
  .deleteIcon {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;
  }
  margin-bottom: 8px;
}
.email {
  @include flex($justify: center);
}

.addEmailField {
  div {
    @include flex($justify: end);
    div {
      cursor: pointer;
      p {
        margin-left: 8px;
        color: $primary600;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}

.removePrefecture {
  cursor: pointer;
  @include flex($justify: space-between);
  margin-bottom: 8px;
  div {
    @include flex();
    p {
      margin-left: 8px;
      color: $primary600;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.addEmailFieldDisabled {
  div {
    @include flex($justify: end);
    div {
      cursor: not-allowed;
      p {
        margin-left: 8px;
        color: $primary200;
        font-weight: bold;
        font-size: 14px;
      }
      svg {
        path {
          stroke: $primary200;
        }
      }
    }
  }
}
.required {
  font-weight: bold;
  font-size: 14px;
  span {
    color: $redRequired;
  }
}

.labelEmail {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin-top: 12px;
}
.selectProvince {
  margin-bottom: 8px;
}

.headingServiceArea {
  text-align: left;
  font-weight: 700;
  margin-top: 24px;
  font-size: 16px;
}
