// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.confirmLogoutModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $bgOverLay;
    @include flex();
  }

  [class^='contents'] {
    width: 448px;
    height: auto;
    background-color: $grey-primary900;
    border-radius: 12px;
    color: $primary50;
    padding: 32px;
    margin: 0;
    @include breakpoint-down('md') {
      width: calc(100% - 16px);
      margin: 0 16px;
    }
  }

  &Content {
    position: relative;
    text-align: center;
    color: $grey-primary50;

    &IconClose {
      position: absolute;
      top: 0;
      right: 0;
    }

    &Title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
    }

    &Description {
      font-size: 16px;
      line-height: 24px;
      margin-top: 16px;
    }
  }

  &Footer {
    margin: 32px auto 0;

    &SubmitButton {
      background-color: $function300;
      border-radius: 4px;
      border: none;
      padding: 16px 32px;
      min-width: 168px;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: $grey-primary50;

      &:focus {
        background-color: $function500;
      }
    }
  }
}
