// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionList {
  &Header {
    @include flex($justify: space-between);

    [class^='count-list'] {
      width: 20%;
    }

    &TenantName {
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      color: $grey-primary50;
      width: 60%;
      @include ellipseText(1);
      @include breakpoint-down('md') {
        display: none;
      }
    }

    &SortButton {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
      flex-wrap: wrap;

      button {
        background-color: inherit;
        border: 1px solid $grey-primary50;
        border-radius: 4px;
        padding: 11px 23px;
        min-width: 104px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $grey-primary50;
        @include breakpoint-down('md') {
          display: none;
        }
      }
    }
  }
}

.compressionRegistration {
  padding: 24px 32px;
  @include breakpoint-down('md') {
    padding: 16px;
  }

  .headingArea {
    @include flex($justify: space-between);
  }

  .btnEditing {
    height: $buttonSmallHeight;
    color: $grey-primary50;
    background-color: $grey-primary900;
    border: 1px solid $grey-primary50;
    border-radius: 4px;
    padding: 0 24px;
  }

  .tenantNameMobile {
    display: none;
    @include breakpoint-down('md') {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 16px;
      color: $grey-primary50;
      @include ellipseText(1);
    }
  }
}

.gridArea {
  flex-wrap: wrap;
  margin: 16px -16px -16px;
  @include flex($items: flex-start, $justify: flex-start);

  @include breakpoint-down('md') {
    margin: 16px 0 0;
    gap: 12px;
  }

  .mediumItemsGroup {
    width: 25%;
    flex-wrap: wrap;
    @include flex($items: flex-start, $justify: flex-start);
    @include breakpoint-down('lg') {
      width: 33.33333%;
    }
    @include breakpoint-down('md') {
      width: 100%;
      gap: 12px;
    }
  }
  .customChange {
    .customEmissionChildSelect {
      position: absolute;
      z-index: 2;
      top: calc(100% + 8px);
      right: -160px;
      background: white;
      border: 0.5px solid rgba(209, 209, 209, 0.5);
      box-shadow: 2px 2px 8px rgba(74, 119, 140, 0.1);
      border-radius: 4px;
      ul {
        min-width: 180px;
      }
      li {
        padding: 18px 24px;
        @include flex($justify: start);
        span {
          margin-left: 8px;
        }
        &.active {
          background: $background-primary;
        }
      }
    }
  }
}
