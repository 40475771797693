// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.checkCard {
  display: flex;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  .children {
    @include flex();
  }
}

.checkCard > div:first-child {
  width: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.checkCard > div:last-child {
  width: 90%;
}
