// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminRecycleChainDesignTable {
  .titleTable {
    @include flex($justify: space-between);
    color: $primary600;

    .buttonAdd {
      @include flex();

      font-weight: bold;
      font-size: 14px;
      cursor: pointer;

      span {
        margin-left: 6px;
      }
    }
  }
  .tool {
    @include flex($justify: space-between);
    margin-top: 20px;

    .search {
      .inputFrame {
        display: flex;

        .inputSearch {
          margin-right: 12px;
          position: relative;

          input {
            padding: 2px 35px 2px 10px;
            width: 200px;
          }

          .iconSearch {
            position: absolute;
            top: 14px;
            right: 14px;
            z-index: 1;
            cursor: pointer;
          }
        }

        .selectOperator {
          height: 44px;

          input {
            height: -webkit-fill-available;
            line-height: 22px;
            width: 200px;
          }
        }
      }
    }

    .customButton {
      @include flex($justify: space-between);
      .buttonExport {
        svg {
          margin-bottom: -3px;
        }
        span {
          margin-left: 11px;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .buttonExport:hover {
        cursor: pointer;
      }

      .buttonDownLoad {
        margin-left: 20px;

        svg {
          margin-bottom: -5px;
        }
        svg > path {
          stroke: $primary600;
        }
        span {
          margin-left: 11px;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .buttonDownLoad {
        cursor: pointer;
      }
    }
  }
  .scrollWrapper {
    overflow-y: auto !important;
  }
}

table#recycleChainDesignTable {
  max-width: 100%;
  max-height: calc(100vh - 244px);
  thead {
    th {
      padding-right: 20px !important;
    }
  }
  tbody {
    tr {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
      td {
        p {
          @include ellipseText(2);
          line-height: 20px;
        }
      }
    }

    .status {
      p {
        padding: 4px 8px;
        border-radius: 2px;
        background-color: $primary100;
        color: $primary600;
        font-weight: 700;
        text-align: center;
        width: fit-content;
      }
    }
  }

  td,
  th {
    width: auto;
    min-width: 100px;
    vertical-align: middle;

    &.chainName {
      min-width: 200px;
      width: 60%;
    }

    &.materialName {
      min-width: 100px;
      width: 16%;
    }

    &.action {
      width: 0%;
      min-width: 70px;
      width: 8%;
    }
  }

  .action {
    .contentAction {
      @include flex();

      > div {
        cursor: pointer;
        height: 24px;

        svg {
          path {
            stroke: $primary400;
          }
        }
      }
    }
  }
}

table#recycleChainDesignTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#recycleChainDesignTable::-webkit-scrollbar {
  width: 5px;
}
