// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.barcodesListManagement {
  min-height: calc(100vh - #{$headerHeight});
  background-color: $grey-primary900;

  .barcodesList {
    .countList {
      height: 60px;
      color: $grey-primary50;
      padding: 24px 32px;
      text-align: left;
      box-sizing: border-box;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
        padding: 24px 16px 16px;
      }
    }

    .table {
      margin: 24px 32px;
      max-height: calc(100vh - #{$headerHeight} - 60px - 64px);
      overflow-y: auto;
      border: 1px solid $grey-primary700;
      border-radius: 4px;
      @include breakpoint-down('md') {
        max-height: calc(100vh - #{$headerHeight} - 60px - 16px);
        margin: 0 16px 16px;
      }

      #barcodesListTable {
        width: 100%;
        border-collapse: collapse;

        tr {
          border-top: 1px solid $grey-primary700;

          &:first-child {
            border-top: none;
          }
        }

        th,
        td {
          text-align: left;
          vertical-align: middle;
        }

        thead {
          position: sticky;
          top: -2px;
          z-index: 10;
          background-color: $grey-primary800!important;
          color: $grey-primary100;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;

          th {
            padding: 16px !important;
            border-right: 1px solid $grey-primary700;
            border-bottom: 1px solid $grey-primary700;
          }
        }

        tbody {
          color: $grey-primary50;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          tr {
            padding: 0 20px !important;

            td {
              border-right: 1px solid $grey-primary700;
            }

            .msgError {
              text-align: center;
              line-height: 26px;
              padding: 0 16px;
              font-size: 0.9em;
            }
          }
        }

        .tenantName {
          width: 50%;
          min-width: 200px;

          p {
            line-height: 24px;
            word-break: break-all;
            padding: 20px 16px;
            display: inline-block;
          }
        }

        .barcode {
          width: 30%;
          min-width: 150px;

          p {
            line-height: 24px;
            display: inline-block;
            word-break: break-all;
            padding: 20px 16px;
          }
        }

        .actionField {
          width: 15%;
          min-width: 80px;
          text-align: center;
          div {
            cursor: pointer;
          }
        }
      }
    }
  }
}
