// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminBrandItemTable {
  text-align: left;

  .tagNew {
    background-color: $main500;
    border-radius: 2px;
    padding: 4px 8px;
  }
}

.tool {
  @include flex($justify: space-between);
  margin-top: 20px;

  .customButton {
    @include flex($justify: space-between);
  }
}

.search {
  .inputFrame {
    display: flex;

    .inputSearch {
      margin-left: 24px;
      position: relative;

      input {
        padding: 2px 35px 2px 10px;
        width: 320px;
      }

      .searchButton {
        position: absolute;
        top: 14px;
        right: 14px;
        z-index: 1;
        padding: 0;
        background: transparent;
        border: none;
        margin: 0;
      }
    }

    .selectOperator {
      height: 44px;

      input {
        height: -webkit-fill-available;
        line-height: 22px;
        width: 240px;
      }
    }
  }
}

.scrollWrapper {
  overflow-y: auto !important;
}

.itemName {
  max-width: 80%;
}

.submit {
  text-align: center;
  display: flex;

  .contentAction {
    margin-right: 12px;
    position: relative;
  }

  .submitButton {
    height: 44px;
    width: 160px;
    font-size: 14px;
    background-color: #4d4d4d;
    border-radius: 4px;
    border: none;
    color: $primary50;
    line-height: 1.25rem;
    font-weight: bold;
    margin: 0;

    &:hover,
    &:active {
      background-color: $primary500;
    }
    &:disabled {
      cursor: not-allowed;
      color: $primary200;
      background-color: $primary400 !important;
    }
  }
}

.titleTable {
  color: $primary600;

  .buttonAdd {
    @include flex();

    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    span {
      margin-left: 6px;
    }
  }
}

table#brandItemTable {
  max-width: 100%;
  max-height: calc(100vh - 308px);
  overflow: auto;

  thead {
    th {
      padding-right: 20px !important;
    }
  }
  
  tbody {
    tr {
      td {
        p {
          @include ellipseText(2);
          line-height: 20px;
        }
      }
    }

    .status {
      p {
        padding: 4px 8px;
        border-radius: 2px;
        background-color: $primary100;
        color: $primary600;
        font-weight: 700;
        text-align: center;
        width: fit-content;
      }
    }
  }

  td,
  th {
    width: auto;
    min-width: 60px;
    vertical-align: middle;

    &.checkbox {
      width: 40px;

      div {
        label::before {
          background-color: #ffffff;
          color: $grey-primary50;
        }
      }
    }

    &.chainName {
      width: 140px;
    }

    &.materialName {
      width: 300px;

      span {
        @include ellipseText(2);
        line-height: 1.5rem;
      }
    }

    &.action {
      width: 0%;
      min-width: 200px;
      width: 7%;
    }
  }
}

table#brandItemTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#brandItemTable::-webkit-scrollbar {
  width: 5px;
}

.formInput {
  width: 100%;
}

