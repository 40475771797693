// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.createBody {
  display: flex;
  justify-items: center;
  margin: auto;
}

.formInput {
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.inputFramRow1 {
  display: flex;
  flex-direction: row;

  .operatorId {
    padding-right: 20px;
    width: 50%;
    [class^='text-input'] {
      margin: 0;
    }
  }

  .operatorType {
    width: 50%;
    [class^='text-input'] {
      margin: 0;

      input {
        margin-top: 12px;
      }
    }
  }
}

.inputFramRow2 {
  display: flex;
  justify-content: center;

  .operatorName {
    width: 50%;
    padding-right: 20px;
  }

  .postCode {
    width: 50%;
  }
}

.inputFramRow3 {
  display: flex;
  justify-content: center;

  .operatorContent {
    width: 50%;
    padding-right: 20px;
  }
  .operatorContentDetail {
    width: 50%;
    padding-top: 28px;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  > * {
    margin: 12px 0;
  }
}

.submit {
  margin-top: 4px;
  padding: 10px 0;
}

.submitButton {
  height: 44px;
  width: 50%;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: bold;

  &:hover,
  &:active {
    background-color: $primary500;
  }
}

.disableButton {
  border-radius: 4px;
  border: none;
  background-color: $primary400;
  color: $primary200;
  font-weight: bold;
  height: 44px;
  width: 50%;
  cursor: not-allowed;
}
