// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.Wrapper {
  padding: 32px;

  @include breakpoint-down('md') {
    padding: 16px;
  }
}

.PrintWrapper {
  [class^='TenantStatistics_DonutChartsWrapper'] {
    flex-direction: row !important;
  }
}
