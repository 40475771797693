// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.analysis_right {
  width: 373px;
  padding: 24px 16px;
  background: $bgWhiteOpacity;
  border-radius: 12px;
  color: rgba(231, 231, 231, 1);

  @include flex($direction: column, $items: flex-start, $justify: flex-start);
  gap: 16px;

  &_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
  }

  &_filter {
    width: inherit;
    @include flex($direction: column, $items: flex-start);
    gap: 24px;

    &_when {
      @include flex($direction: column, $items: flex-start);
      gap: 8px;

      &_title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
      }

      &_form {
        @include flex($direction: row);
        gap: 8px;

        [class^='select-date'] {
          min-width: auto !important;
          width: 50% !important;
          background-color: #ffffff1f !important;

          input {
            border: none !important;
          }
        }
      }
    }

    &_where {
      @include flex($direction: column, $items: flex-start);
      width: 100%;
      gap: 12px;

      &_text {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
      }

      &_radios {
        width: 100%;
        @include flex($direction: column, $items: flex-start);
        gap: 12px;

        label {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: $grey-primary50;
        }

        .whereSelect {
          @include flex($direction: column, $items: flex-end);
          width: 100%;
          gap: 8px;

          .treeSelect {
            width: calc(100% - 28px);
          }

          [class^='SelectField_selectField'] {
            width: calc(100% - 28px);
          }

          [class^='InputDataListField_textInput'] {
            width: calc(100% - 28px);
          }

          [class^='InputDataListField_textInputWrap'] {
            width: 100%;
          }
        }
      }
    }

    &_what {
      @include flex($direction: column, $items: flex-start);
      gap: 12px;
      width: 100%;

      .title {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        color: $grey-primary50;
      }

      .right {
        width: 100%;
        @include flex($direction: row, $justify: flex-end);
        .container {
          width: calc(100% - 28px);
        }
      }
    }

    &_btn {
      width: 100%;
      @include flex();

      .primary {
        height: 44px;
        width: 160px;
        padding: 12px 16px;
        border-radius: 4px;
        border: none;
        background-color: $main400;
        color: $grey-primary900;
        font-size: 14px;
        line-height: 20px;
        font-weight: bold;

        &:focus {
          background-color: $main500;
        }

        &:disabled {
          color: $main600;
          cursor: not-allowed;
          border: none;
        }
      }
    }
  }
}
