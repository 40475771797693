// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.buttonTabs {
  @include flex();
  gap: 12px;

  .buttonStyle {
    width: 62px;
    height: 36px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    color: $grey-primary50;
    border: 1px solid $grey-primary600;
    background-color: $grey-primary800;
    margin: 0;

    &.active,
    &:hover {
      background-color: $grey-primary50;
      color: $grey-primary900;
    }

    &:disabled {
      color: $primary200;
      cursor: not-allowed;
      background-color: $primary300 !important;
    }
  }
}
