// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

tr {
  .wraperName {
    @include flex($justify: start);

    .arrowDown {
      transform: rotate(90deg);
      margin-left: 2px;
    }

    .arrowRight {
      transform: rotate(0);
    }
  }

  .name {
    width: 80%;

    .contentName {
      @include ellipseText(2);

      text-align: left;
      word-break: break-all;
      margin-left: 8px;
      line-height: 20px;
    }
    .contentNameChild{
      margin-left: 15px;
      word-break: break-all;
      line-height: 20px;
    }
  }

  .action {
    width: 20%;
    vertical-align: middle;
    padding: 8px 0 0 !important;

    .contentAction {
      @include flex($justify: flex-start);

      > div {
        cursor: pointer;
        height: 24px;

        svg {
          path {
            stroke: $primary400;
          }
        }

        &.actionEdit {
          margin: 0 24px;
        }
      }
    }

    .contentActionChild {
      padding: 0 24px;
      @include flex($justify: flex-start);

      > div {
        cursor: pointer;
        height: 24px;

        svg {
          path {
            stroke: $primary400;
          }
        }

        &.actionEdit {
          margin: 0 24px;
        }
      }
    }
  }
}
