// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.required {
  color: $function300;
}

.label {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.circleNumber {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid $grey-primary600;

  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    font-size: 10px;
  }
}

.description {
  font-size: 12px;
  line-height: 16px;
  color: $grey-primary600;
  margin-top: 4px;
  text-align: left;
  white-space: pre-line;
}
