@import 'variables.scss';
@import 'mixin.scss';

.CompressionBookingItem {
  width: 100%;
  border-top: 1px solid $grey-primary800;

  color: $grey-primary50;
  font-size: 16px;
  line-height: 24px;

  @include flex($direction: row);

  .w65 {
    width: 65%;
  }

  .w35 {
    width: 35%;
  }

  .w60 {
    width: 60%;
  }

  .w40 {
    width: 40%;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 0 12px 32px;

    @include mq('xs') {
      padding: 12px 0 12px 8px;
    }

    .contentRow {
      @include flex($direction: row, $justify: flex-start);

      .left {
        width: 40%;
        @include flex($justify: flex-start);
      }

      .right {
        width: 60%;
        @include flex($justify: flex-start);
      }
    }
  }

  .QRContainer {
    padding: 12px 32px;

    @include mq('xs') {
      padding: 0;
    }

    .QRCode {
      width: 80px;
      height: 80px;
      padding: 16px;
      background-color: $grey-primary50;
      cursor: pointer;
    }
  }
}
