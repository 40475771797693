// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.comparisonOfProcurementIntensity {
  box-sizing: border-box;
  padding: 16px 0px 0;
  background-color: $grey-primary800;
  border-radius: 12px;
  white-space: pre-line;
  min-width: 100%;

  .heading {
    padding: 0 24px;
    @include flex($justify: space-between);

    p {
      font-weight: 700;
      color: $grey-primary50;
      font-size: 16px;
    }

    .buttonDownload {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid $grey-primary600;
      @include flex();
    }
  }

  .chartHidden {
    visibility: hidden;
    height: 0 !important;
    width: 100%;
  }

  canvas {
    cursor: pointer;
  }

  .chartContainer {
    overflow-x: auto;

    .miniChart {
      height: 400px !important;
      width: auto;
      min-width: 400px;
    }
  }

  [class^='modal'] {
    box-sizing: border-box;
    padding: 0 16px;
  }

  [class^='content'] {
    width: 100% !important;
    background-color: $grey-primary900;

    .description {
      margin-top: 32px;
      padding: 16px;
      font-size: 14px;
      line-height: 20px;
      color: $grey-primary50;
      background-color: $grey-primary800;
      text-align: left;
      white-space: pre-line;
    }
  }

  .msgEmpty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      color: $primary50;
    }
  }
}
