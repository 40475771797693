// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

#admin-main, #user-layout {
  .select-date {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 44px;
    border: none;
    padding: 0;
    min-width: 320px;
    width: 30%;
    position: relative;

    input {
      width: 100%;
      border-radius: 4px;
      border: 1px solid $primary200;
      padding: 11px 15px;
      font-size: 14px;
      line-height: 20px;
      color: $primary300;

      &:focus {
        border: 1px solid $grey-primary600;
        outline: none;
      }

      &:disabled {
        border-color: #6f6f6f !important;
        background-color: #6f6f6f !important;
        color: #B7B7B7 !important;
        cursor: not-allowed;
      }

      &::placeholder {
        color: #B7B7B7 !important;
      }
    }

    .icon {
      text-align: right;
      position: absolute;
      top: calc(50% - 8px);
      right: 16px;
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;

        path {
          fill: $primary300;
        }
      }
    }

    .calender-datepick {
      z-index: 100;
      position: absolute;
      top: 40px;
      right: -5px;

      .calender {
        padding-left: 30px;
        padding-right: 30px;
        width: 320px;
        display: none;

        &.active {
          display: block;
        }
      }
    }
    .darkMode{
      z-index: 100;
      position: absolute;
      top: 40px;
      right: -5px;

      .calender {
        padding-left: 15px;
        padding-right: 15px;
        width: 320px;
        display: none;
        background-color:#2c2c2c;
        color: #e7e7e7;
        border: none;

        &.active {
          display: block;
        }

        .react-calendar__navigation {
          margin-bottom: 0;
          min-width: none;

          button {
            min-width: none;
            background-color: unset;

            &:disabled {
              cursor: not-allowed;
              color: #878787 !important;
            }
          }
        }

        .react-calendar__month-view__weekdays {     
          border-top: 1px solid $grey-primary700;
          border-bottom: 1px solid $grey-primary700;
          color: $grey-primary300;
          font-size: 14px;
          font-weight: 400;

          div > abbr {
            text-decoration: unset !important;
          }
        }

        .react-calendar__month-view__days {
          background-color:#2c2c2c;
          border-radius: 4px;
          color: $grey-primary900 !important;
          margin: 12px 0;
        }

        .react-calendar__tile {
          border: none;
          background-color: #2c2c2c;

          abbr {
            color: #e7e7e7;
            font-weight: bold;
          }

          &:active {
            background-color: #5e5e5e !important;
          }

          &:disabled {
            abbr {
              color: #878787 !important;
              font-weight: bold;
              cursor: not-allowed;
            }
          }
        }

        .react-calendar__tile.react-calendar__tile--active {
          background-color: #5e5e5e !important;
          border-radius: 4px;
        }

        .react-calendar__navigation__label {
          font-size: 16px;
          color: white;
          font-weight: bold;
          background: none;
        }
      }
    }

    .iconDarkMode {
      path {
        stroke: #6F6F6F;
        fill: #FFFFff1F !important
      }
    }

    .iconDisable {
      path {
        fill: #FFFFff1F !important;
        stroke:#B7B7B7 !important
      }
    }
  }
}
