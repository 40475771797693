@import 'variables.scss';
@import 'mixin.scss';

.tareWeightSubtraction {
  min-height: calc(100vh - #{$headerHeight});
  background-color: $grey-primary900;

  .tareWeightSubtractionContent {
    padding: 24px 32px;
    @include breakpoint-down('md') {
      padding: 24px 16px;
    }

    .title {
      @include flex($justify: space-between);

      .countItem {
        color: $grey-primary50;
        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }
        span {
          font-weight: bold;
        }
      }
    }

    .submitButton {
      height: $buttonSmallHeight;
      padding: 12px 20px;
      background-color: $main400;
      color: $grey-primary900;
      border-radius: 4px;
      border: 1px solid transparent;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      @include flex();

      &:focus {
        background-color: $main600;
      }

      .iconAdd {
        margin-top: 2px;
        svg {
          path {
            stroke: $grey-primary900;
          }
        }
      }

      span {
        margin-left: 6px;
        @include breakpoint-down('md') {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .table {
      margin-top: 24px;
      max-height: calc(100vh - 210px);
      overflow-y: auto;
      border: 1px solid $grey-primary700;
      @include breakpoint-down('md') {
        max-height: calc(100vh - #{$headerHeight} - 56px - 24px - 48px);
      }
      #tareWeightSubtractionTable {
        width: 100%;
        border-radius: 4px;
        border-collapse: collapse;

        &::-webkit-scrollbar {
          width: 0 !important;
          display: none;
        }

        tr {
          border-bottom: 1px solid $grey-primary700;
          &:last-child {
            border-bottom: none !important;
          }
        }

        th,
        td {
          text-align: left;
          border-left: 1px solid $grey-primary700;
          vertical-align: middle;
        }

        thead {
          position: sticky;
          top: -2px;
          z-index: 10;
          background-color: $grey-primary800 !important;
          color: $grey-primary100;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;

          @include breakpoint-down('md') {
            font-size: 14px;
            line-height: 20px;
          }

          th {
            border: 1px solid $grey-primary700 !important;
            padding: 16px !important;
            border-left: 1px solid $grey-primary700 !important;
            border-right: 1px solid $grey-primary700 !important;
            border-bottom: 1px solid $grey-primary700 !important;
          }
        }

        tbody {
          color: $grey-primary50;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          @include breakpoint-down('md') {
            font-size: 14px;
            line-height: 20px;
          }

          tr {
            padding: 0 20px !important;

            .msgError {
              text-align: center;
              line-height: 26px;
              padding: 0 16px;
              font-size: 0.9em;
            }
          }
        }

        .tareName, .tenants, .weight {
          width: 40%;
          min-width: 200px;

          p {
            line-height: 24px;
            word-break: break-all;
            padding: 16px;
            display: inline-block;
          }
        }
        .weight {
          width: 20%;
          min-width: 100px;
          word-break: break-all;
          text-align: right;
        }

        .tenants {
          p {
            display: flex;
            justify-content: space-between;
          }

          svg {
            cursor: pointer;
          }
        }

        .actionField {
          width: 15%;
          min-width: 120px;
          text-align: center;
        }
        .actionField1 {
          width: 5%;
          min-width: 60px;
          padding: 0px !important;

          .contentAction {
            @include flex();
            svg {
              cursor: pointer;
            }

            .actionEdit {
              padding: 16px;
              flex: 1;
              text-align: center;
              svg > path {
                stroke: $main400;
              }
            }

            .actionDelete {
              padding: 16px;
              svg > path {
                stroke: $function300;
              }
              flex: 1;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
