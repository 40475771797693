// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.uploadFile {
  > p {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
  }
}

.content {
  background-color: $primary75;
  border-radius: 4px;
  border: 1px dashed $primary200;
  display: flex;
  padding: 12px 16px;
  margin-top: 8px;
  flex-direction: column;
}

.picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px 8px 0px;
}

.borderBt {
  height: 1px;
  background-color: $alto;
  margin-bottom: 8px;
}

.pickerFile {
  display: flex;
  align-items: center;

  & > p {
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    @include ellipseText(1);
  }
}

.closeButton {
  cursor: pointer;
}

.contentLeft {
  display: flex;
  align-items: center;
  font-weight: bold;

  > p {
    font-size: 12px;
  }
}

input[type='file'] {
  display: none;
}

.customFileUpload {
  border-radius: 4px;
  background-color: $primary400;
  display: inline-block;
  justify-content: center;
  cursor: pointer;
  color: white;
  padding: 8px 16px;
  font-size: 12px;
}

.photo {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 12px;
}

.error {
  font-size: 14px;
  text-align: left;
  margin-top: 4px;

  p {
    color: $function300;
  }
}

.size {
  font-weight: bold;
}

