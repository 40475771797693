// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.recycleEdit {
  box-shadow: 0px 1px 0px $primary500;
  padding: 1.5rem 2rem;
  text-align: left;

  @include breakpoint-down('md') {
    padding: 1rem;
  }

  .recycleDate,
  .recycleType,
  .inputLabel {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $grey-primary50;
    word-break: break-all;
    margin-bottom: 0.25rem;
  }

  .selectLabel {
    margin-top: 12px;
  }

  .inputNum {
    margin-top: 12px;
    position: relative;

    input {
      height: $inputSmallHeight;
      padding: 12px 16px;
      border: 1px solid transparent;
      border-radius: 4px;
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      box-sizing: border-box;
      color: $grey-primary50;
      background-color: $login-input-bg;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }
    }

    .weightUnit {
      position: absolute;
      top: 25%;
      right: 12px;
      color: $primary300;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
    }
  }

  .overload {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #fcaf57;
  }
}
