// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.adminTenantManagement {
  .searchArea {
    margin-top: 20px;
    text-align: start;

    [class^='search-input'] {
      min-width: 250px !important;
      width: 25% !important;
    }

    .buttonImportBarcodeICCard {
      margin-right: 48px;
      @include flex();
    }

    .buttonOption {
      @include flex();
    }

    .buttonSection {
      margin-right: 48px;
    }

    .buttonImportTenant {
      margin-right: 48px;
      @include flex();
    }
  }

  .scrollWrapper {
    overflow: hidden;
  }

  table#customizeItemTable {
    max-width: 100%;
    max-height: calc(100vh - 264px);
    overflow: auto;

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        .name {
          text-align: left;
        }

        .contentName,
        .contentSection,
        .contentFloor,
        .contentBrandName {
          p {
            @include ellipseText(2);
            font-size: 14px;
            line-height: 20px;
          }
        }

        .contentName {
          p {
            text-align: left;
            font-weight: bold;
          }
        }

        .action {
          vertical-align: middle;
          padding: 8px 0 0 !important;

          .contentAction {
            @include flex($justify: flex-start);

            > div {
              cursor: pointer;
              height: 24px;

              &.actionEdit {
                margin-right: 24px;
              }

              &.actionBarcode {
                margin-left: 24px;
              }
            }

            .disableButton {
              cursor: not-allowed !important;
            }
          }
        }
      }
    }

    tr {
      .name {
        width: 30%;
      }

      .floor,
      .section {
        width: 10%;
      }

      .brandName {
        width: 20%;
      }

      .viewReport,
      .action {
        width: 15%;
      }
    }
  }

  table#customizeItemTable::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }

  table#customizeItemTable::-webkit-scrollbar {
    width: 5px;
  }
}
