// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.barcodeDetailModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    @include flex();
  }

  [class^='content'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;

    .modalContent {
      padding: 2rem;

      .customTitle {
        @include flex($justify: flex-end);

        .descriptionTitle {
          margin: auto;
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-weight: bold;
        }

        .top {
          @include flex($justify: flex-end);
        }
      }
    }
  }

  .body {
    padding: 2.5rem 0;

    p {
      letter-spacing: 12px;
      text-align: end;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      color: $black;
      text-align: center;
    }
  }
  .line {
    width: 480px;
    height: 1px;
    background-color: $primary100;
    margin-left: -32px;
  }

  .submit {
    margin-top: 1.5rem;

    .submitButton {
      height: $buttonSmallHeight;
      width: 100%;
      padding: 12px 16px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: bold;
      box-sizing: border-box;
    }
  }
}
.darkMode {
  .modalContent {
    background-color: $grey-primary900;
    color: $grey-primary50;
  }
  .body {
    background-color: $white;
    margin: 24px 0;
    border-radius: 8px;
  }

  .line {
    background-color: $grey-primary700;
  }

  .submit .submitButton {
    color: $grey-primary900;
    background-color: $main400;
  }
}
