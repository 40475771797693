// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.compressionDelivery {
  .primaryTable {
    height: calc(
      var(--vh) - #{$tabHeight + $headerHeight} - #{$totalBottomHeight}
    );
    background-color: $grey-primary900 !important;
    color: $grey-primary50;
    width: 100%;

    thead {
      box-shadow: 0px 1px 0px $primary500;
      background-color: $grey-primary900 !important;
    }

    th {
      height: $theadTableHeight;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      border: none !important;
    }

    tr {
      box-shadow: 0px 1px 0px $primary500;
      border: none !important;
    }

    .disableTr {
      pointer-events: none;
      opacity: 0.4;
    }

    td {
      border: none !important;
    }
  }

  .bottomBtn {
    margin: 16px auto;
    @include flex($items: center, $justify: center);
    @include breakpoint-down('md') {
      margin: 16px;
    }

    button {
      padding: 16px 32px;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      border: 1px solid $grey-primary50;
      height: $buttonMediumHeight;
      box-sizing: border-box;
      border-radius: 4px;

      @include breakpoint-down('md') {
        width: 100%;
        padding: 10px;
        line-height: 18px;
      }
    }

    .btnEditing {
      color: $grey-primary50;
      background-color: $grey-primary900;
      border: 1px solid $grey-primary50;
      margin-right: 24px;
      @include breakpoint-down('md') {
        margin-right: 16px;
      }
      &:focus {
        border: 1px solid $grey-primary600;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .btnRequest {
      color: $grey-primary900;
      background-color: $main400;
      border: none;
      // width: 172px;

      &:focus {
        background-color: $main600;
      }

      &:disabled {
        color: $main600;
        cursor: not-allowed;
        border: none;
      }
    }
  }

  .total {
    @include flex($justify: space-around);
    font-size: 16px;
    line-height: 24px;
    color: $grey-primary50;
    padding: 12px 0;
    box-shadow: 0px 1px 0px $primary500;
    font-weight: bold;
    height: 40px;
    box-sizing: border-box;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .checkBox {
    width: 20%;
    padding: 12px 8px 12px 16px;
    vertical-align: middle;
    color: $grey-primary50;
    font-weight: 700 !important;
    text-align: center;
    cursor: pointer;

    [class^='checkbox'] {
      input[type='checkbox'] + label::before {
        background-color: $grey-primary900;
        border: 1px solid $grey-primary400;
      }
    }
  }
  .label {
    width: 20%;
    padding: 12px 8px;
    text-align: left;
    line-height: 24px;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }

    .labelText {
      margin-bottom: 4px;
    }
  }
  .content {
    width: 60%;
    padding: 12px 16px 12px 8px;
    line-height: 24px;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .filter {
    text-align: end !important;
  }

  [class^='bottom-field'] {
    height: $totalBottomHeight;
    box-sizing: border-box;
  }

  .materialName {
    color: $grey-primary50 !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    float: right;

    .selectName {
      display: flex;
      align-items: center;

      .iconFilter {
        margin-right: 4px;
      }

      ul {
        right: 0;
        width: 180px;
      }

      input {
        background-color: $grey-primary900;
        color: $grey-primary50;
        border: 1px solid transparent !important;
        padding: 10px 24px 10px 0;
      }
    }

    .ja {
      width: 98px;
    }

    .en {
      width: 138px;
    }
  }
}
