// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.dashboardSelectArea {
  width: 20%;
  * {
    box-sizing: border-box;
  }
  gap: 7px;
  display: flex;
  flex-direction: column;
  position: relative;

  @include breakpoint-down('lg') {
    width: 100%;
    padding-left: 0;
  }
}

.selectItem {
  display: flex;
  gap: 8px;
}

[class^='DashboardSelectArea_dashboardSelectArea'] {
  [class^='SelectFieldMultipleChoise_select'] {
    margin: 0;
  }

  [class^='SelectFieldMultipleChoise_input'] {
    border: none;
  }
}

[class^='DashboardSelectArea_selectItem'] {
  &:last-child {
    [class^='SelectFieldMultipleChoise_selectMultipleField'] {
      width: calc(100% - 80px - 8px);
    }
  }
}

.buttonApply {
  width: 80px;
  height: 44px;
  background-color: $main400;
  color: $grey-primary900;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  margin: 0;

  &:focus {
    background-color: $main600;
  }
}
.buttonApply:disabled {
  color: $main600;
  cursor: not-allowed;
  background-color: $main400 !important ;
}
