// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.modalContent {
  background-color: $primary50;
  border-radius: 4px;
}

.uploadFileCSVModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 680px;
    margin: auto;
    border-radius: 4px;
  }

  .top {
    @include flex($items: center, $justify: center);
    padding: 2rem 2rem 0rem 2rem;
    position: relative;
    .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 700;
      color: $primary600;
    }
    .closeIcon {
      position: absolute;
      right: 32px;
    }
  }

  .uploadFileIcon {
    svg {
      height: 2rem;
      width: 2rem;
      margin-bottom: 0.5rem;

      path {
        fill: $primary200;
      }
    }
  }

  .uploadFileContent {
    background-color: $primary100;
    border: 1px dashed $primary100;
    border-radius: 4px;
    padding: 2rem;
    margin: 1.5rem 2rem 0 2rem;

    span {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: $primary600;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: $primary300;
      margin-top: 0.25rem;
      margin-bottom: 0.75rem;
    }

    .uploadFileButton {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: $mariner;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0;
    }
  }

  .uploadFileInfo {
    @include flex($items: center, $justify: space-between);
    background-color: $primary100;
    border-radius: 4px;
    padding: 15px 29px;
    margin: 1.5rem 2rem 0;

    .uploadFileName {
      @include flex($items: center, $justify: start);

      span {
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 500;
        color: $primary600;
        text-align: left;
        display: inline;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        width: 300px;
      }
    }

    .uploadFileAction {
      @include flex($items: center, $justify: start);

      .uploadFileSize {
        font-size: 0.875rem;
        line-height: 1.5rem;
        font-weight: 400;
        color: $primary600;
        margin-right: 2.25rem;
      }

      .deleteIcon {
        cursor: pointer;
        path {
          stroke: $primary600 !important;
        }
      }
    }
  }
}
.submitButton {
  height: 44px;
  background-color: $primary400;
  border: 1px solid $primary100;
  border-radius: 4px;
  border: none;
  color: $primary50;
  font-size: 14px;
  font-weight: 700;
  margin: 1.5rem 0 2rem 0 !important;
  padding: 12px 40px;
  box-sizing: border-box;

  &:hover,
  &:active {
    background-color: $primary500;
  }
}

.submitButton:disabled {
  color: $primary200;
  cursor: not-allowed;
  background-color: $primary400 !important ;
}

.error {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
  margin-top: 0.5rem;
  padding: 0 32px;
  p {
    color: $function300;
  }
}
