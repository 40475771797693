// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0 12px;

  & > * {
    margin: 12px 0;
  }

  .textFrame {
    gap: 12px 12px;
    flex-wrap: wrap;
    @include flex($items: start, $justify: start);

    & > div {
      width: calc(50% - 6px);
    }

    .address {
      width: 100%;
    }
  }
}

.submit {
  padding: 24px 66px 0;
  margin: 0 -32px;
  border-top: 1px solid $primary100;
  @include flex();
}

.prevButton {
  height: 44px;
  width: 240px;
  background-color: white;
  border-radius: 4px;
  border: none;
  color: $primary600;
  font-weight: 700;
  margin-right: 24px;
  border: 1px solid $primary400;
  flex: 1;
  
  &:hover,
  &:active {
    background-color: $primary300;
    color: white;
  }
}

.submitButton {
  height: 44px;
  width: 240px;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: $primary50;
  font-weight: 700;

  &:hover,
  &:active {
    background-color: $primary500;
  }

  &:disabled {
    cursor: not-allowed;
    color: $primary200;
    background-color: $primary400 !important ;
  }
}

.prevButton {
  height: 44px;
  width: 240px;
  background-color: $primary75;
  border-radius: 4px;
  border: none;
  color: $primary600;
  font-weight: 700;
  margin-right: 24px;
  border: 1px solid $primary400;
  flex: 1;
  
  &:hover,
  &:active {
    background-color: $primary300;
    color: $primary75;
  }
}

.headingImages {
  text-align: left;
  font-weight: 700;
  margin-top: 16px;
  font-size: 14px;
}
