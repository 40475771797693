// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.collectList {
  position: relative;

  .primaryTable {
    background-color: $grey-primary900 !important;
    color: $grey-primary50;
    width: 100%;
    thead {
      box-shadow: 0px 1px 0px #36383c;
      background-color: $grey-primary800 !important;
    }
    th {
      border: none !important;
      font-size: 16px !important;
      color: $primary300;
      @include breakpoint-down('md') {
        font-size: 14px !important;
        line-height: 20px;
      }
    }
    tr {
      box-shadow: 0px 1px 0px #36383c;
      border: none !important;
    }
    td {
      border: none !important;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .left {
    text-align: left;
  }

  .checkBox {
    width: 15%;
    vertical-align: middle;
    color: $grey-primary50 !important;
    padding: 12px 8px 12px 16px;
    cursor: pointer;
  }
  .label {
    width: 35%;
    text-align: left;
    line-height: 24px;
    padding: 12px 8px;
  }
  .content {
    width: 25%;
    line-height: 24px;
    text-align: left;
    padding: 12px 16px 12px 8px;
  }
}
