// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.scanBarcodeModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    min-width: 480px;
    width: auto;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;

    @include breakpoint-down('md') {
      min-width: 300px;
      width: 300px;
    }
  }

  .customTitle {
    padding: 32px 32px 16px 32px;
    border-bottom: 1px solid $grey-primary800;
    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      padding: 24px 16px 16px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;

      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .top {
      svg > path {
        stroke: $grey-primary300;
      }
    }
  }

  .body {
    padding: 48px 32px 4px;
    @include breakpoint-down('md') {
      padding: 16px 16px 4px;
    }

    p {
      letter-spacing: 12px;
      text-align: center;
      font-size: 14px;
      color: $grey-primary50;
    }

    svg {
      @include breakpoint-down('md') {
        width: -webkit-fill-available;
      }
    }
  }
  .submit {
    margin-top: 4px;
    padding: 24px 16px 32px 16px;
    @include breakpoint-down('md') {
      padding: 12px 16px 24px;
    }

    .submitButton {
      width: 242px;
      height: $buttonMediumHeight;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      color: $grey-primary900;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;

      &:focus {
        background-color: $main600;
      }

      @include breakpoint-down('md') {
        width: 100%;
      }
    }

    .submitButton:disabled {
      border-radius: 4px;
      border: none;
      color: $grey-primary300;
      font-weight: bold;
      width: 242px;
      height: 68px;
      cursor: not-allowed;
      background-color: $main400 !important ;
    }
  }
}
