// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
// UIガイド記載の共通定義
$background-white: #ffffff; // バックグラウンド１ コンテンツエリアの背景色
$background-gray: #f2f6f7; // バックグラウンド２ コンテンツエリアの背景色
$point-blue: #4b97b3; // ポイント１ 強調テキスト、タップ可能箇所
$point-red: #f15a24; // ポイント２ 注意書き、通知バッジなど
$text-one: #333333; // 見出し、本文などのサブテキストカラー
$text-two: #969aa7; // 日付などのサブテキストカラー
$text-three: #7f8c91; // サブテキストカラー (背景色（バックグラウンド2）の場合)
$text-four: #8d9699; // 非アクティブ（テキスト、アイコン）、サブテキストカラー（背景色（バックグラウンド１）の場合）
$error-color: rgb(122, 0, 12); // エラーメッセージ
$disabled-color: rgb(180, 180, 180); // 非アクティブボタンなど
$primary-color: rgb(33, 73, 111); // メインカラー
$secondary-color: rgb(215, 232, 236);
$border-color: rgb(222, 223, 222);
$border-deep-color: rgb(120, 120, 120);
$link-color: #2a5a82;
$error-back-color: rgba(240, 175, 192, 0.5);
$error-border-color: rgb(233, 8, 30);
$box-shadow: rgba(0, 0, 0, 0.08);
$borderSecond-color: #e6e6e6;
$background-deep-gray: #d9d9d9;
$background-overlay: rgba(51, 51, 51, 0.8);
$background-gray-100: #f4f4f4;
$table-hover-bg: #fbf8f880;
$background-gray-300: #ccc;
$background-gray-200: #ededed;
$background-gray-400: #d1d1d1;
$light-blue: #c8deda;
$deep-sky-blue: #0b99db;
$light-gray-100: #f9f9f9;
$light-gray: #aaaaaa;
$red-color: #e83e3e;
$black-color: #000;
$white: #fff;
$border-row-color: #dedfde;
$background-primary: #f4f8f7;
$background-label: #d39552;
$placehoderColor: #777777;

// Update Style Guide
$headerSmallHeight: 48px;
$headerHeight: 64px;
$tabSmallHeight: 44px;
$tabHeight: 52px;
$buttonTinyHeight: 36px;
$buttonSmallHeight: 44px;
$buttonMediumHeight: 56px;
$buttonLargeHeight: 68px;
$buttonSuperLargeHeight: 76px;
$theadTableHeight: 52px;
$tbodyTableHeight: 64px;
$totalBottomHeight: 128px;
$bottomHeight: 116px;
$bottomSmallHeight: 88px;
$headerModalHeight: 68px;
$bottomModalHeight: 96px;
$menuTabHeight: 52px;
$inputHeight: 48px;
$inputSmallHeight: 44px;
$tableThHeight: 48px;
$selectHeight: 44px;
$purchaseHeaderHeight: 68px;

// Primary color
$primary50: #ffffff;
$primary75: #f6f6f6;
$primary100: #e8e8e8;
$primary200: #cfd1d2;
$primary300: #9ea0a2;
$primary400: #6f7175;
$primary500: #36383c;
$primary600: #24252a;
$primary700: #191b1f;
$primary800: #16171b;
$primary900: #101216;

// Second color
$second50: #eaf2ec;
$second75: #c7ddcf;
$second100: #a1cbb4;
$second200: #7abc9c;
$second300: #50ae88;
$second400: #24a279;
$second500: #1c8766;
$second600: #156c53;
$second700: #0f503e;
$second800: #093428;
$second900: #041612;

// Secondary2 color
$second50-2: #fdf7e0;
$second75-2: #fceabf;
$second100-2: #fbda9d;
$second200-2: #fcc57a;
$second300-2: #fdac57;
$second400-2: #ff8d32;
$second500-2: #db7028;
$second600-2: #b7561f;
$second700-2: #923e17;
$second800-2: #6e2a10;
$second900-2: #4a180a;

// Function color
$function50: #f9edf1;
$function75: #f3ccd5;
$function100: #f1a7b3;
$function200: #f27f88;
$function300: #f75354;
$function400: #ff3424;
$function500: #d32c1b;
$function600: #a62313;
$function700: #7a1b0d;
$function800: #4e1107;
$function800: #210803;

// Scale Color
$grey-primary50: #e7e7e7;
$grey-primary75: #cfcfcf;
$grey-primary100: #b7b7b7;
$grey-primary200: #9f9f9f;
$grey-primary300: #878787;
$grey-primary400: #6f6f6f;
$grey-primary500: #5e5e5e;
$grey-primary600: #4d4d4d;
$grey-primary700: #3c3c3c;
$grey-primary800: #2c2c2c;
$grey-primary900: #1b1b1b;

// Main color
$main50: #d2fdc6;
$main75: #c0fbaf;
$main100: #b0f899;
$main200: #a1f484;
$main300: #93f070;
$main400: #86ea5c;
$main500: #66c346;
$main600: #489933;
$main700: #2e6d21;
$main800: #173f11;
$main900: #050f04;

$mariner: #1f6dc9;
$black100: rgba(0, 0, 0, 0.08);
$background-deep-gray: rgba(36, 37, 42, 0.6);
$shadow-primary: rgba(33, 73, 111, 0.05);
$shadow-primary75: #efefef;
$dark100: rgba(54, 56, 60, 0.7);
$dark200: #1e1f24;
$dark300: rgba(0, 0, 0, 0.05);
$dark400: rgba(54, 56, 60, 0.9);
$doveGray: #6e6e6e;
$crollColor: #b7b7b7;
$bgOverLay: #2c2c2ccc;
$borderGrey: #3c3c3cb3;
$bgWhiteOpacity: #ffffff0d;
$bgGrey: #393939;

// z-index
$under-tabs-z-index: 5000;
$navbar-z-index: 10000;
$search-modal-z-index: 15000;
$bottom-tab-z-index: 20000;
$main-content-header-z-index: 20000;
$cover-tabs-z-index: 30000;
$toast-z-index: 30000;
$modal-z-index: 30000;

// ナビゲーションバーの高さ
$header-height: 65px;
$tab-height: 54px;
$default-bottom-height: 104px;
$total-bottom-height: #{$default-bottom-height + 56px + 39px};
$total-w-button-bottom-height: calc(#{$total-bottom-height} + 61px);

// モーダル背景の共通定義
$modal-background: rgba(0, 0, 0, 0.8);

// 繰り返し使われる影の定義
$shadow: 0 0 8px 0 rgba(0, 0, 0, 0.06);
//----------------------------------
// Browser
//----------------------------------
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1350px;

$breakpoints: (
  'xs': 'screen and (max-width: 480px)',
  'sm': 'screen and (min-width: 400px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1000px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$mercury: #e6e6e6;
$alto: #d9d9d9;
$alabaster: #f8f8f8;
$dustyGray: #999999;
$redRequired: #e22222;
$diSerria: #d39552;
$shark: #2c2e32;
$red-color: #ff3424;
$link-color: rgba(31, 109, 201, 1);
$login-input-bg: #ffffff1f;
$iron: #cfd1d2;
$wildSand: #f6f6f6;
$black: #000000;
$gray: #858585;
$boulder: #767676;
$sharkDark: #24252a;
$modalICCardBg: #2e303599;
$bgPurchase: #1f1f1f;
