// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.historyCollectCard {
  border-top: solid 1px $primary500;
  padding: 8px 0;

  .requestBorder {
    margin: 12px 0;
    border-bottom: 1px solid $primary600;
  }
}
