// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.downloadModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 90%;
    margin: auto;
    border-radius: 4px;
    max-height: 709px;
    background-color: $grey-primary900;
    color: $grey-primary50;
    @include breakpoint-down('sm') {
      height: auto;
    }
  }

  .customTitle {
    padding: 24px 24px 16px;
    border-bottom: 1px solid $primary600;
    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      padding: 24px 16px 16px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .body {
    margin: 0px 24px;
    overflow: auto;
  }

  .totalRecovery {
    display: flex;
    justify-content: flex-end;

    text-align: right;
    padding: 0 32px;
    font-size: 18px;
    line-height: 28px;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
      padding: 24px 16px;
    }

    .item {
      max-height: 84px;
      overflow-y: auto;
      padding-right: 6px;
    }

    p > span {
      font-weight: bold;
    }
  }

  .label {
    font-size: 14px;
    text-align: left;
    padding: 16px 24px;
  }

  .submit {
    padding: 16px 16px 24px;
    

    .submitButton {
      width: 160px;
      height: 44px;
      background-color: $main400;
      border-radius: 4px;
      font-family: 'Noto Sans JP';
      border: none;
      color: $grey-primary900;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }

      &:hover,
      &:active {
        background-color: $main500;
      }

      &:disabled {
        cursor: not-allowed;
        color: $main600;
        background-color: $main300 !important;
      }
    }
  }
}
