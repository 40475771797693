// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.confirmModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 800px;
    margin: auto;
    border-radius: 4px;
    height: auto;
    background-color: $grey-primary900;
    color: $grey-primary50;
    @include breakpoint-down('sm') {
      height: auto;
    }
  }

  .modalContent {
    padding: 32px 32px 16px 32px;
    border-bottom: 1px solid $primary600;
    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      padding: 24px 16px 16px;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .body {
    margin: 16px 32px;
    text-align: left;

    .quantity {
      max-height: 232px;
      overflow-y: auto;
    }

    .item {
      background-color: #202020;
      padding: 16px;
      padding-bottom: 4px;
      margin-bottom: 12px
    }

    .confirmInfo {
      display: flex;
      margin-bottom: 12px;
      align-items: center;

      .label {
        font-size: 20px;
        line-height: 32px;
        color: $grey-primary50;
        width: 124px;
      }

      .confirmInfoLabel {
        font-size: 20px;
        line-height: 32px;
        color: $grey-primary50;
        width: 140px;
      }

      .shipping{    
        display: flex;
        align-items: center;
        width: calc(50% - 8px);
      }

      .confirmInfoContent{
        font-size: 20px;
        line-height: 32px;
        font-weight: bold;
        @include truncate-multiline(2)
      }

      .to {
        display: flex;
        align-items: center;
      }

      .confirmToLabel {
        font-size: 20px;
        line-height: 32px;
        color: $grey-primary50;
        width: 120px;
        margin-left: 16px;
      }
    }
  }

  .inputForm {
    margin: auto;

    .formInput {
      width: calc(100% - 64px);
      margin: 16px 32px;
      display: flex;
      flex-direction: column;

      .time {
        padding: 16px;
        background-color: #202020;
      }
  
      .label {
        font-size: 18px;
        margin-bottom: 8px;
      }

      .inputFrame {  
        display: flex;

        [class^='select-date'] {
          width: calc(50% - 8px) !important
        }
  
        .timeFrame {
          width: 314px;
          margin-left: 8px !important;
        }

        input {
          background-color: $login-input-bg;
          border: none !important;
        }
      }
    }
  }

  .submit {
    padding: 24px 16px 32px;

    .submitButton {
      width: 168px;
      height: $buttonMediumHeight;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      color: $grey-primary900;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }

      &:hover,
      &:active {
        background-color: $main500;
      }

      &:disabled {
        cursor: not-allowed;
        color: $main600;
        background-color: $main300 !important;
      }
    }
  }
}

