// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.compressionReceived {
  [class^='scroll-contents'] {
    height: calc(
      var(--vh) - #{$headerHeight + $tabHeight} - #{$tableThHeight} - #{$bottomSmallHeight}
    );

    overflow: auto;

    [class^='check-card'] {
      &:last-child {
        border-bottom: none;
      }
    }

    [class^='checkbox'] {
      label::before {
        background-color: $grey-primary900;
        border: 1px solid $grey-primary400;
      }
    }

    [class^='label-contents'] {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  [class^='bottom-field'] {
    height: $bottomSmallHeight;
  }

  [class^='no-data'] {
    width: 100%;
    height: 100%;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $grey-primary50;

    @include flex();
  }
}
.bottomBtn {
  margin: 16px auto;
  gap: 16px;
  @include flex($items: center, $justify: center);
  @include breakpoint-down('md') {
    margin: 16px;
  }

  button {
    padding: 16px 40px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    height: $buttonMediumHeight;
    box-sizing: border-box;
    border: none;

    &:focus {
      background-color: $main600;
    }

    &:disabled {
      color: $main600;
      cursor: not-allowed;
      background-color: $main400 !important ;
      border: none;
    }

    @include breakpoint-down('md') {
      width: 100% !important;
      padding: 16px;
    }
  }

  .btnEditing {
    color: $grey-primary900;
    background-color: $main400;
    border-radius: 4px;
  }

  .btnRequest {
    color: $primary600;
    background-color: $main400;
    border-radius: 4px;
    width: 172px;
  }
}

.checkBox {
  width: 10%;
  padding: 12px 8px 12px 16px;
  font-size: 16px;
  line-height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;
  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }
}
.label {
  width: 90%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }
}
.topSelect {
  @include flex();
  box-shadow: 0px 1px 0px $grey-primary800;
  user-select: none;
}
