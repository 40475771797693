// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  display: flex;
  justify-items: center;
  margin: auto;
}

.formInput {
  width: 100%;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0 12px;

  > * {
    margin: 12px 0;
  }

  .textFrame {
    gap: 12px 12px;
    flex-wrap: wrap;
    @include flex($items: start, $justify: start);

    & > div {
      width: calc(50% - 6px);
    }
    .address {
      width: 100%;
    }

    .comparison {
      @include flex($justify: space-between, $items: flex-start);

      width: 100%;
      gap: 12px;

      & > div {
        width: 100%;
      }

      .addContainer {
        @include flex($justify: flex-end);

        .addButton {
          @include flex($justify: flex-end);

          gap: 4px;
          height: 20px;
          cursor: pointer;
        }
      }
    }

    .label {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      color: $text-one;
    }
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.submit {
  margin-top: 4px;
  padding: 10px 0;
}

.submitButton {
  height: 44px;
  width: 50%;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: white;
  font-weight: bold;

  &:hover,
  &:active {
    background-color: $primary500;
  }

  &:disabled {
    cursor: not-allowed;
    color: $primary200;
    background-color: $primary400 !important ;
  }
}
