// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

@mixin container() {
  padding: 24px 16px;
  border-radius: 12px;
}

.materialStatistic {
  @include flex($direction: column);
  @include container();

  width: 100%;
  height: 340px;
  box-sizing: border-box;
  background: $bgWhiteOpacity;
  gap: 24px;

  &_header {
    @include flex($direction: row, $justify: space-between);
    width: 100%;
    height: 36px;

    &_text {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0em;
      color: $grey-primary50;
    }

    &_tabs {
      @include flex($direction: row);
      gap: 12px;

      button {
        width: 62px;
        height: 36px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        color: $grey-primary900;

        padding: 0;
        margin: 0;
        border: none;

        border-radius: 4px;
      }
    }
  }

  &_content {
    @include flex($direction: row);
    width: 100%;
    height: 232px;
    gap: 48px;

    &_chartContainer {
      width: 75%;
    }

    &_legend {
      @include flex(
        $direction: column,
        $items: flex-start,
        $justify: flex-start
      );
      @include truncate-oneline();
      width: 25%;
      height: 100%;
      gap: 12px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px !important;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $crollColor;
      }

      &_item {
        @include flex($direction: row);
        gap: 8px;

        &_text {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          color: $grey-primary50;
        }

        &_box {
          width: 12px;
          height: 12px;
          border-radius: 2px;
        }
      }
    }
  }
}
