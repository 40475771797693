@import 'variables.scss';
@import 'mixin.scss';

.recycleQRScanning {
  height: calc(var(--vh) - #{$headerHeight + $tabHeight});
  width: 100%;
  background-color: $grey-primary900;
  box-sizing: border-box;
  overflow: auto;
  border-top: 1px solid $grey-primary800;

  .recycleQRScanningBody {
    height: 100%;
    width: 100%;
    @include flex($direction: column);

    .scanQRCode {
      @include flex($direction: column);
      @include breakpoint-down('md') {
        margin: 0 48px;
      }

      .empty {
        background: unset;
        border: none;
        width: 200px;

        @include breakpoint-down('md') {
          width: 334px;
          height: 334px;
          border-radius: 8px;
        }

        img {
          display: none;
        }

        video {
          border-radius: 12px;
          width: 100% !important;
          height: 200px;
          border-radius: 12px;
          border: 1px dashed $grey-primary600;

          @include breakpoint-down('md') {
            height: 334px;
          }
        }

        [id^='qrCode__scan_region'] {
          display: flex;
          justify-content: center;
          height: 200px;
          background-color: $grey-primary800;
          border: 1px dashed $grey-primary600;
          border-radius: 12px;

          @include breakpoint-down('md') {
              height: 334px;
          }
        }

        [id^='qrCode__dashboard_section'] {
          display: flex !important;
          flex-direction: column;

          & > span:nth-child(2) {
            display: flex;
            flex-direction: column;
            margin-right: 0 !important;
            width: 100%;
            gap: 8px;
          }
        }

        [id='qrCode'] {
          border: none !important;
          width: 100%;
        }

        [id^='qrCode__header_message'] {
          border: none !important;
          position: absolute;
          width: calc(100% - 26px);
          left: 0;
          text-align: center;
        }

        &:not(video) {
          @include flex($items: center, $direction: column, $justify: center);
        }
      }

      .step1 {
        background: $grey-primary800;
        width: 200px;
        height: 200px;
        border-radius: 12px;
        border: 1px dashed $grey-primary600;

        @include breakpoint-down('md') {
          width: 334px;
          height: 334px;
          border-radius: 8px;
        }
      }

      .message {
        font-size: 16px;
        font-weight: 400;
        color: $grey-primary50;
        margin-top: 24px;
        margin-bottom: 24px;

        @include breakpoint-down('md') {
          width: 100%;
          margin-top: 48px;
          margin-bottom: 48px;
        }
      }

      .back {
        font-size: 16px;
        font-weight: 400;
        color: $grey-primary50;
        margin-top: 16px;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .infoQRCode {
      width: 100%;
      margin: 0 auto 24px;
      text-align: left;
      @include flex($items: center, $direction: column);

      .title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        color: $grey-primary50;
      }

      .QRContainer {
        padding: 24px 20px;

        @include breakpoint-down('md') {
          width: calc(100% - 96px);
          margin: 0 48px;
          padding: 24px 0;
        }

        .QRCode {
          width: 120px;
          height: 120px;
          padding: 40px;
          background-color: $grey-primary800;
          border-radius: 12px;

          @include breakpoint-down('md') {
            width: 100%;
            height: 100%;
            padding: 67px;
            box-sizing: border-box;
          }
        }
      }

      .infoQRCodeBox {
        .infoQRCodeRow {
          width: 100%;
          text-align: left;
          font-size: 16px;
          line-height: 24px;
          color: $grey-primary50;

          @include breakpoint-down('md') {
            font-size: 14px;
            line-height: 20px;
          }

          td {
            padding: 4px 12px 4px 0;
          }

          .infoQRCodeLabel {
            font-weight: 400;
          }

          .infoQRCodeContents {
            font-weight: 700;
          }
        }
      }
    }

    .submitButton {
      padding: 0;
      width: 200px;

      @include breakpoint-down('md') {
        width: calc(100% - 96px);
        margin: 0 48px;
      }

      button {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        height: $buttonMediumHeight;
        padding: 16px 40px;
        margin: 0;
        background-color: $main400;
        color: $grey-primary900;
        border-radius: 4px;
        border: none;
        box-sizing: border-box;

        &:focus {
          background-color: $main600;
        }
      }
    }

    .error {
      font-size: 16px;
      font-weight: 500;
      color: $grey-primary50;
    }
  }
}
