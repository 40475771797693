@import 'variables.scss';
@import 'mixin.scss';

.languages {
  border-radius: 4px;
  padding: 12px 24px;

  @include flex($direction: row, $justify: space-between);
  gap: 4px;

  cursor: pointer;

  .icon-language {
    width: 20px;
    height: 20px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0em;
    color: $grey-primary50;
  }

  .icon-down {
    width: 20px;
    height: 20px;
    color: $grey-primary50;
  }
}

.dropdown-content {
  width: 150px;
  top: 45px;

  position: absolute;
  background: $grey-primary600;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid $grey-primary500;
  border-radius: 4px;

  cursor: pointer;
  z-index: 100;

  .dropdown-item {
    @include truncate-oneline();

    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 14px 25px;
    color: $grey-primary50;
  }

  .dropdown-item:hover {
    background-color: $grey-primary400;
  }

  .selected {
    background-color: $grey-primary400;
  }
}
