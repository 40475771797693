// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tabAdminCustomize {
  [class^='tab-menu'] {
    margin-bottom: 20px;
  }

  [class^='tab-item'] {
    cursor: pointer;
  }

  .titleCustomize {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: $primary600;
    text-align: left;
    margin-bottom: 20px;
  }
}

.adminCustomizeItems {
  [class^='admin-contents'] {
    overflow: hidden !important;
  }
}
