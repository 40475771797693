// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

#admin-main.trace-detail-page {
  .trace-detail {
    @include flex($items: top, $justify: left);

    margin-top: 20px;
    gap: 16px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $crollColor;
    }
    .trace-detail-field {
      flex: 1;
      min-width: 320px;

      .process-title {
        background-color: $primary75;
        border-radius: 4px;
        border: 1px solid $primary100;
        padding: 12px 16px;
        font-size: 14px;
        line-height: 20px;
        color: $primary600;
        font-weight: bold;
        text-align: left;
      }

      .process-detail {
        background-color: $background-white;
        border-radius: 4px;
        border: 1px solid $primary100;
        padding: 16px;
        margin-top: 8px;

        .label-contents {
          padding: 0;

          > div {
            font-size: 14px;
            line-height: 20px;
            color: $primary600;

            &.title {
              width: 100px;
              margin-right: 20px;
            }

            &.contents {
              flex: 1;
              min-width: 125px;
            }
          }

          &:not(:first-child) {
            margin-top: 12px;
          }
        }
      }
    }
  }
}

