@import 'variables.scss';
@import 'mixin.scss';

.shippingContent {
  width: 100%;
  color: $grey-primary50;

  @include flex($direction: column);
  padding: 48px 0;
  gap: 24px;

  .qrCodeContainer {
    padding: 32px;
    border-radius: 12px;
    background-color: $grey-primary50;

    .qrCode {
      background-color: $grey-primary50;
    }
  }

  .information {
    .row {
      width: 100%;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      color: $grey-primary50;

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }

      td {
        padding: 4px 12px 4px 0;
      }

      .title {
        font-weight: 400;
      }

      .content {
        font-weight: 700;
      }
    }
  }

  .bottom {
    .btn {
      padding: 16px 32px;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      height: $buttonMediumHeight;
      box-sizing: border-box;
      border: none;

      &:focus {
        background-color: $main600;
      }

      &:disabled {
        color: $main600;
        cursor: not-allowed;
        background-color: $main400 !important ;
        border: none;
      }

      @include breakpoint-down('md') {
        width: 100% !important;
        padding: 16px;
      }
    }

    .btn {
      color: $grey-primary900;
      background-color: $main400;
      border-radius: 4px;
      width: 200px;
    }
  }
}
