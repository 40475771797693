// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
  padding: 0 32px 32px;
  @include breakpoint-down('md') {
    padding: 0 16px 24px;
  }

  [class^='SelectFieldMultipleChoise_input'] {
    border: none;
    height: 48px;
    margin-top: 16px;
  }

  [class^='SelectFieldMultipleChoise'] input {
    background-color: white;

    &:focus {
      border: none !important;
      caret-color: $grey-primary600;
    }
  }

  [class^='SelectFieldMultipleChoise_wrapSelected'] {
    font-size: 0.875rem;
    font-weight: 400;
    color: $grey-primary50;
  }
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.inputFrame {
  flex: 1 1 0%;
  color: white;
  > * {
    margin: 16px 0;
  }
  div {
    font-size: 16px;
    font-weight: bold;
    color: $grey-primary50;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
    }
  }
  input {
    margin-top: 8px !important;
    height: 48px !important;
    background-color: $login-input-bg;
    border: 1px solid transparent;
    border-radius: 4px;
    caret-color: white;
    color: $grey-primary50;
    padding-left: 16px;

    &:focus:not(.focus-visible) {
      border: 1px solid $main400;
      outline: none;
      box-shadow: none;
    }
  }
  p {
    font-weight: normal;
  }

  span {
    font-weight: 400;
  }

  [class^='Tag_tag'] {
    color: $primary600;
    font-size: 12px;
  }
}

.submit {
  margin-top: 24px;
}

.submitButton {
  margin: 0;
  height: $buttonMediumHeight;
  background-color: $main400;
  border: none;
  border-radius: 4px;
  border: none;
  color: $grey-primary900;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    background-color: $main600;
  }
}

.submitButton:disabled {
  color: $main600;
  cursor: not-allowed;
  background-color: $main400 !important ;
}

.weightField {
  position: relative;
  [class^='text-input'] {
    margin: 0;

    input {
      padding-right: 40px;
    }
  }
  .weightUnit {
    position: absolute;
    top: 50px;
    right: 10px;
    color: $grey-primary300;
  }
}
