// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.histories {
  [class^='admin-contents__main'] {
    background-color: $grey-primary900 !important;
  }

  [class^='admin-contents__main__body'] {
    margin-top: 0 !important;
  }

  .tabAcceptance {
    height: 100%;
    padding: 16px;
    margin: 20px;
    background-color: $bgPurchase !important;
    box-sizing: border-box;

    [class^='tab-menu'] {
      border-bottom: 1px solid $grey-primary600;
      
      [class^='tab-items'] {
        [class^='tab-item'] {
          color: $grey-primary300;
          background-color: $grey-primary900;
          border: 1px solid $grey-primary600;
          border-radius: 4px 4px 0 0;
          cursor: pointer;
        }

        [class^='tab-item active'] {
          background-color: $grey-primary600;
          color: $grey-primary50;
        }
      }
    }

    [class^='tab-container'] {
      overflow: auto;
      max-height: calc(100% - 53px);
    }
  }
}
