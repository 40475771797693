// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.searchArea {
  margin-top: 20px;
  text-align: start;
}

.scrollWrapper {
  overflow: hidden;
}

table#areaFloorTable {
  max-width: 100%;
  max-height: calc(100vh - 352px);
  overflow: auto;

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  tr {
    .name {
      width: 80%;
    }

    .action {
      width: 20%;
    }
  }
}

table#areaFloorTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#areaFloorTable::-webkit-scrollbar {
  width: 5px;
}

.backToTenantList {
  margin: 20px 16px;
  text-align: left;
}
