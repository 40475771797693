@import 'variables.scss';
@import 'mixin.scss';

.tabExtends {
  .tabMenu {
    overflow: hidden;
    height: $tabHeight;
    box-sizing: border-box;
    background-color: $bgOverLay;
    @include breakpoint-down('md') {
      height: $tabSmallHeight;
    }
  }

  .tabItems {
    display: flex;
  }
  .tabItem {
    height: $tabHeight;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    padding: 12px;
    color: $grey-primary300;
    border-right: 1px solid $borderGrey;
    box-sizing: border-box;
    cursor: pointer;
    &:last-child {
      border-right: none;
    }
    @include breakpoint-down('md') {
      height: $tabSmallHeight;
      font-size: 14px;
      line-height: 18px;
      @include flex();
    }
  }

  .tabItemActive {
    color: $grey-primary50;
    border-bottom: 1px solid $grey-primary50;
  }

  .hidden {
    display: none;
  }
}
