// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  margin-top: 32px;
  color: $grey-primary50;

  @include breakpoint-down('md') {
    font-size: 16px;
    line-height: 24px;
  }
}

.container {
  @include flex($items: flex-start);
  min-height: calc(100vh - 65px);
  background-color: $grey-primary900;
  padding-bottom: 32px;

  @include breakpoint-down('md') {
    padding: 0 16px;
  }

  .skipRight {
    right: 64px;
  }

  button {
    margin: 5px;
  }
}

.body {
  max-width: 100%;
  width: 100%;
  margin-top: 10px;

  @include breakpoint-down('md') {
    max-width: calc(100% - 32px);
    width: calc(100% - 32px);
    padding: 0 16px;
  }
}

.sectionList {
  margin: auto;
  margin-top: 40px;
  gap: 16px;
  flex-wrap: wrap;
  @include flex();
}

.floorList {
  display: block;
  margin-top: 30px;
}

.tenant {
  @include flex();
  margin-top: 30px;
}

.tenantContainer {
  width: 100%;
  flex-wrap: wrap;
  @include flex();

  @include breakpoint-up('lg') {
    grid-template-columns: auto auto auto auto;
  }

  @include breakpoint-down('md') {
    grid-template-columns: auto auto;
    gap: 16px;
  }
}

.skip {
  position: absolute;
  margin-top: 24px;
  right: 16px;
  text-align: right;
  width: 30%;

  button {
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    height: 44px;
    font-size: 14px;
    padding: 0 24px;
    font-weight: 700;
    color: $grey-primary50;
    background-color: $grey-primary900;
  }

  @include breakpoint-down('md') {
    display: none;
  }
}
