// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.tableList {
  .titleTable {
    @include flex($justify: space-between);
    color: $primary600;
  }

  .scrollWrapper {
    overflow: hidden;
  }
}

table#resourceHistoryTable {
  max-width: 100%;
  max-height: calc(100vh - 264px);
  overflow: auto;

  tbody {
    tr {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
    .status p {
      padding: 4px 8px;
      border-radius: 2px;
      background-color: $primary100;
      color: $primary600;
      font-weight: 700;
      text-align: center;
      width: fit-content;
    }
    td {
      vertical-align: middle;
      padding: 16px 20px 12px 0;
      p {
        padding-bottom: 4px;
        @include ellipseText(2);
      }
    }
    
  }
  td,
  th {
    width: 14%;
    &.editTime {
      width: 12%;
    }
    &.status {
      width: 12%;
    }
  }
}


table#resourceHistoryTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: $crollColor;
}

table#resourceHistoryTable::-webkit-scrollbar {
  width: 5px;
}