// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@mixin flex($justify: center, $items: center, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $items;
  flex-direction: $direction;
  -webkit-align-items: $items;
  -webkit-justify-content: $justify;
}

$breakpointsDown: (
  'sm': (
    max-width: $sm,
  ),
  'md': (
    max-width: $md,
  ),
  'lg': (
    max-width: $lg,
  ),
  'xl': (
    max-width: $xl,
  ),
  'xxl': (
    max-width: $xxl,
  ),
);

$breakpointsUp: (
  'sm': (
    min-width: $sm + 1px,
  ),
  'md': (
    min-width: $md + 1px,
  ),
  'lg': (
    min-width: $lg + 1px,
  ),
  'xl': (
    min-width: $xl + 1px,
  ),
  'xxl': (
    min-width: $xxl + 1px,
  ),
);

@mixin breakpoint-down($breakpoint) {
  $raw-query: map-get($breakpointsDown, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin breakpoint-up($breakpoint) {
  $raw-query: map-get($breakpointsUp, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin truncate-oneline() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin truncate-multiline($line-number) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-number;
  -webkit-box-orient: vertical;
}

@mixin disable-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

@mixin ellipseText($line: 1) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  overflow: hidden;
  word-break: break-all;
}

@mixin fontSizeText($font-size: 36px, $line-height: 40px, $font-weight: 700) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
}

@mixin disableSelectText() {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

@mixin customScroll {
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
    background-color: $grey-primary700;
  }
}
