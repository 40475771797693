// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.dashboardCalendarField {
  .selectDate {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 44px;
    border: none;
    padding: 0;
    width: 100%;
    position: relative;

    input {
      width: 100%;
      border-radius: 4px;
      padding: 11px 15px;
      font-size: 14px;
      line-height: 20px;
      color: $grey-primary300;
      background: $login-input-bg;
      border: 1px solid transparent;
      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }
    }

    .icon {
      text-align: right;
      position: absolute;
      top: calc(50% - 8px);
      right: 16px;
      width: 16px;
      height: 16px;
    }

    .calenderDatepick {
      z-index: 100;
      position: absolute;
      top: 50px;
      right: 0;

      .calender {
        padding-left: 30px;
        padding-right: 30px;
        width: 320px;
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}
