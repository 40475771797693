// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.adminRecycleChainStepItem {
  box-sizing: border-box;
  min-width: 20%;
  max-width: 20%;
  padding: 0 8px;
  height: 100%;
  & > div {
    border-radius: 4px;
    height: 100%;
    border: 1px solid $primary100;
  }
  .stepName {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 12px 16px;
    background-color: $primary300;
    @include flex($justify: flex-start);
    .index {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background: $primary50;
      color: $primary300;
      font-weight: 700;
      font-size: 14px;
      @include flex();
    }
    .name {
      font-weight: 700;
      color: $primary50;
      margin-left: 8px;
    }
  }
  .body {
    padding: 16px;
    font-weight: 700;
    font-size: 12px;
    color: $primary600;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $crollColor;
    }
    box-sizing: border-box;
    max-width: 100%;
    height: calc(100% - 105px);

    .siteItem {
      background: $primary100;
      max-width: 100%;
      box-sizing: border-box;
      width: 100%;
      padding: 8px 12px;
      margin-bottom: 16px;
      border-radius: 4px;
      @include flex($justify: space-between);
      border: 1px solid transparent;
      cursor: pointer;

      .siteName {
        color: $primary600;
        line-height: 20px;
        font-weight: 400;
        font-size: 14px;
        @include ellipseText(1);
      }

      svg {
        margin-left: 8px;
      }
    }

    .selectedSite {
      border: 1px solid black;
    }

    &.bodyEmpty {
      @include flex();
    }
  }

  .setupRoute {
    height: calc(100% - 48px - 40px - 32px - 56px);
  }

  .actionSetupRoute {
    min-height: 56px;

    .buttonSetupRoute {
      border: 1px solid $grey-primary600;
      border-radius: 4px;
      margin: 16px;
      padding: 12px;
      cursor: pointer;

      @include flex();

      padding: 12px;
      p {
        color: $primary600;
        font-weight: 700;
        font-size: 14px;
      }
      path {
        stroke: $primary600;
      }
    }
  }

  .actionArea {
    padding: 16px;
    border-top: 1px solid $primary100;
    min-height: 56px;
    box-sizing: border-box;
    .buttonAddSite,
    .buttonSettingDelivery {
      cursor: pointer;
      @include flex();
      p {
        color: $primary600;
        font-weight: 700;
        font-size: 14px;
        margin-left: 8px;
      }
      path {
        stroke: $primary600;
      }
    }
  }
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    @include flex();
  }
  [class^='contents'] {
    width: 320px;
    margin: auto;
    border-radius: 4px;
  }
  .modalContent {
    padding: 32px;
  }
  .customTitle {
    margin-bottom: 24px;
    @include flex($justify: space-between);
  }
  .descriptionTitle {
    font-size: 20px;
    font-weight: 700;
    color: $primary600;
  }
  .modalBody {
    max-height: 220px;
    overflow-y: scroll;
    border-bottom: 1px solid $primary100;
    margin-bottom: 24px;
    .emptySite {
      font-weight: 700;
      font-size: 12px;
      color: $primary600;
      padding-bottom: 16px;
    }
    li {
      cursor: pointer;
      width: fit-content;
      @include flex($justify: flex-start);
      & > div {
        padding: 0px 0px 26px 10px;
        width: fit-content;
      }
      span {
        display: block;
        margin-bottom: 24px;
        font-size: 14px;
        font-weight: 700;
        color: $primary600;
        line-height: 20px;
        position: relative;
        top: -2px;
        @include ellipseText(1);
      }
    }
  }
  .submitButton {
    width: 100%;
    height: 44px;
    background-color: $primary400;
    border-radius: 4px;
    border: none;
    color: $primary50;
    font-size: 14px;
    font-weight: 700;
    flex: 1;
    &:hover,
    &:active {
      background-color: $primary500;
    }
    &:disabled {
      color: $primary200;
      cursor: not-allowed;
      background-color: $primary400 !important ;
    }
  }
}
