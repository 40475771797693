// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0px 12px;
  > * {
    margin: 12px 0;
  }
}

.submit {
  padding: 24px 32px 0;
  margin: 0 -32px;
  border-top: 1px solid $primary100;
}

.submitButton {
  height: 44px;
  width: 100%;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: $primary50;
  font-weight: 700;

  &:hover,
  &:active {
    background-color: $primary500;
  }
  &:disabled {
    color: $primary200;
    cursor: not-allowed;
    background-color: $primary400 !important ;
  }
}

.headingAddress {
  text-align: left;
  font-weight: 700;
  margin-top: 24px;
}
.weightUnit {
  position: absolute;
  right: 12px;
  bottom: 14px;
  font-weight: 400;
}
