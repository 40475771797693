// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

h1 {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;

  @include breakpoint-down('md') {
    font-size: 16px;
    line-height: 24px;
  }
}

.emission {
  position: relative;
  background-color: $grey-primary900;
  color: $grey-primary50;
  height: $headerHeight;
  box-sizing: border-box;
  z-index: 99;
  border-bottom: 1px solid $grey-primary800;
  @include flex();
  @include breakpoint-down('md') {
    height: $headerSmallHeight;
  }

  .disp-info.flex {
    margin: 0;
  }
}

header {
  padding: 0 20px;
  border-bottom: solid 1px $primary700;
  .user-info {
    font-size: 0.8em;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
    & > div {
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-bottom: 10px;
    }
  }
  .prev {
    position: absolute;
    top: 10px;
    left: 1px;
    display: flex;
    height: 40px;
    width: 100px;
    justify-content: center;
    align-items: center;
    z-index: 90;

    cursor: pointer;
    @include breakpoint-down('md') {
      top: 4px;
    }

    span {
      margin-left: 13px;
    }

    a {
      color: $grey-primary50;
    }
  }
  .reload {
    position: relative;
    top: 5px;
    left: 8px;
    cursor: pointer;
  }

  .download {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    z-index: 90;
    cursor: pointer;
    @include breakpoint-down('md') {
      top: 2px;
    }
  }

  h1 {
    line-height: 30px;
  }
}

.contents {
  .count-list {
    font-size: 1rem;
    line-height: 1.5em;
    color: $grey-primary50;
    text-align: left;
  }
}

.disp-info {
  height: 30px;
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 10px;
}

table.primary-table {
  border-collapse: collapse;
  table-layout: fixed;
  display: block;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  thead {
    display: inline-table;
    width: 100%;
    background-color: $secondary-color;
    font-size: 0.7em;
    font-weight: bold;
    /* 縦スクロール時に固定する */
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    /* tbody内のセルより手前に表示する */
    z-index: 10;
    tr {
      th {
        border-left: solid 1px $border-color;
        border-right: solid 1px $border-color;
      }
    }
  }
  tbody {
    display: inline-table;
    width: 100%;
    overflow: auto;
  }
  tr {
    border-bottom: solid 1px $border-color;
    th {
      height: 35px;
      vertical-align: middle;
    }
    td {
      border: 1px solid $border-row-color;
      height: 40px;
      vertical-align: middle;
    }
  }
}

.left {
  text-align: left;
}

.site-name {
  position: absolute;
  top: 20px;
  right: 240px;
  color: $grey-primary50;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;

  @include flex();
  @include breakpoint-down('md') {
    display: none;
  }

  @include breakpoint-down('lg') {
    display: none;
  }
}

.selectLanguage {
  position: relative;
  width: 135px;

  .icon-language {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;
    z-index: 1;
    height: 20px;
  }

  & > div {
    margin-bottom: 0;
  }
}

.language {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 52px;
  color: $grey-primary50;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  @include breakpoint-down('md') {
    display: none;
  }

  @include breakpoint-down('lg') {
    display: none;
  }
}

.back {
  position: absolute;
  left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background: $grey-primary50;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  @include breakpoint-down('md') {
    top: 14px;
    left: 12px;
    background: none;
    padding: 0;
  }

  svg > path {
    stroke: $grey-primary900;

    @include breakpoint-down('md') {
      stroke: #e7e7e7;
    }
  }

  span {
    margin-left: 10px;
    color: $grey-primary900;
    font-weight: 700;
    font-size: 18px;

    @include breakpoint-down('md') {
      color: #e7e7e7;
    }
  }
}
