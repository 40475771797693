// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.suggestionList {
  list-style: none;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  max-width: 100%;
  position: absolute;
  margin-top: 4px;
  width: 100%;
  z-index: 1;
  background-color: $primary75;
  border: 1px solid $primary200;
  box-shadow: 0px 1px 2px $black100;
  border-radius: 4px;

  .suggestion {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid $primary200;
    white-space: pre-line;
    word-break: break-all;
    cursor: pointer;

    p {
      color: $primary600;
      overflow: hidden;
      display: block;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      max-height: 65px;
      box-sizing: border-box;
      margin: 0;
    }

    &:hover {
      background-color: $primary100;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
.selected {
  background-color: $primary100;
}
