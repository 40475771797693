// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.searchArea {
  margin-top: 20px;
  text-align: start;
}

.scrollWrapper {
  overflow: hidden;
}

table#brandListTable {
  max-width: 100%;
  max-height: calc(100vh - 308px);
  overflow: auto;

  tbody {
    tr {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  tr {
    .operator {
      width: 20%;
    }

    .image {
      width: 20%;
    }

    .name {
      width: 30%;
    }

    .address {
      width: 20%;
    }

    .action {
      width: 10%;
    }
  }
}

table#brandListTable::-webkit-scrollbar-thumb {
  border-radius: 4px;
background-color: $crollColor;
}

table#brandListTable::-webkit-scrollbar {
width: 5px;
}
