// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

#admin-main.traces-page {
  .scroll-wrapper {
    overflow: hidden;
  }
  .scroll-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
  }

  .scroll-wrapper::-webkit-scrollbar {
    height: 5px;
  }

  .countList {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: $black;
    margin-bottom: 20px;
    text-align: left;

    span {
      font-weight: 700;
    }
  }

  table#trace-table {
    max-width: 100%;
    max-height: calc(100vh - 314px);
    height: calc(100vh - 300px) !important;
    overflow: auto;
    thead,
    tbody {
      min-width: 1500px;
    }

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }
      }
    }

    th.c--weight {
      padding-right: 20px;
    }

    td,
    th {
      &.c {
        &--date {
          width: 10%;
          min-width: 80px;
        }

        &--staff {
          width: 15%;
          min-width: 150px;
        }

        &--operator {
          width: 11%;
          min-width: 80px;
        }

        &--description {
          width: 10%;
          min-width: 80px;
        }

        &--type {
          width: 10%;
          min-width: 80px;
        }

        &--grade {
          width: 10%;
          min-width: 80px;
        }

        &--weight {
          width: 9%;
          min-width: 80px;
          text-align: right;
        }

        &--id {
          width: 14%;
          min-width: 80px;
        }
      }

      p {
        @include ellipseText(2);
      }
    }
  }

  table#trace-table::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $crollColor;
    overflow: hidden;
  }

  table#trace-table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    overflow: hidden;
  }
}
