// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.topChart {
  padding: 0 16px;
}

.donutChart {
  @include flex();
}
.donutContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  width: 100%;
  margin-top: 16px;
  // @include breakpoint-down('lg') {
  //   grid-template-columns: 1fr 1fr;
  // }
  @include breakpoint-down('md') {
    grid-template-columns: 1fr;
  }
}
.DashboardContainer {
  background-color: $grey-primary900;

  .BarsArea {
    gap: 16px;
    padding: 16px;
    flex-wrap: wrap;
    @include flex($justify: flex-start);
  }
  .generalsArea {
    box-sizing: border-box;
    padding: 38px 16px 16px;
    width: 100%;
    @include flex();
    gap: 16px;

    @include breakpoint-down('lg') {
      flex-wrap: wrap;
    }
  }
}
