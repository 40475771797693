// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.dropdown {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 1px 2px $box-shadow;
  border-radius: 4px;
  cursor: pointer;

  .clear {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    background-color: $function500;
    border-radius: 4px;
    color: $grey-primary50;
    margin-top: 4px;
    height: 38px;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
}

.dropdownCentered {
  left: 50% !important;
  transform: translateX(-50%);
}

.labelContainer {
  @include flex($justify: space-between);
  gap: 4px;
  cursor: pointer;

  .label {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #b7b7b7;
  }
}

.labelExpanded {
  flex-direction: column;
  justify-content: start;
  align-items: flex-start
}
