// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.RecyclingChartContainer {
  box-sizing: border-box;
  width: calc(50% - 8px);
  padding: 16px 0px 24px;
  background-color: $grey-primary800;
  border-radius: 12px;
  position: relative;

  @include breakpoint-down('md') {
    width: 100%;
  }
  .heading {
    padding: 0 24px;
    @include flex($justify: space-between);
    p {
      font-weight: 700;
      color: $grey-primary50;
      font-size: 16px;
    }
    .buttonDownload {
      cursor: pointer;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid $grey-primary600;
      @include flex();
    }
  }
  .chartHidden {
    visibility: hidden;
    height: 0 !important;
    width: 1400px;
  }
  canvas {
    cursor: pointer;
  }
  [class^='modal'] {
    box-sizing: border-box;
    padding: 0 16px;
  }
  [class^='content'] {
    width: 100% !important;
  }
}
