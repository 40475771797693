// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';


.scale-confirm {
    padding: 30px 0;
    .item {
        display: flex;
        > div {
            font-size: 25px;
            font-weight: bold;
            margin: 10px auto;

        }
        .label {
            width: 40%;
            text-align: right;
            padding-right: 25px;
        }
        .value {
            width: 60%;
            text-align: left;
            padding-left: 25px;

        }

    }
}
