// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap');

:root {
  --vh: 100vh;
}
* {
  font-size: 18px;
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', Meiryo, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $grey-primary900;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sub-title {
  width: 100%;
  background-color: $secondary-color;
  line-height: 45px;
  font-size: 1.2em;
  font-weight: bold;
}

.common-card {
  width: 100%;
  padding: 10px;
  width: 100%;
  border-bottom: solid 1px $border-color;
}

div.scroll {
  overflow-y: scroll;
}

input.error {
  border-color: $function300 !important;
}

p.error {
  margin-top: 8px;
  color: $function300;
  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }
}

.over-ray {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &.active {
    display: block;
  }
}

.hidden {
  display: none;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.calender-datepick {
  padding: 5px;

  .calender {
    background-color: #f4f4f4;
    color: #6e6e6e;
    margin: 0 auto;

    .react-calendar__navigation__arrow {
      color: #b4b4b4;
    }

    .react-calendar__navigation__label {
      font-size: 14px;
    }

    .react-calendar__month-view {
      margin-bottom: 30px;
    }

    .react-calendar__tile--now {
      background-color: #f4f4f4;
      font-weight: bolder;
    }

    .react-calendar__tile {
      background-color: white;
      border: solid 1px $border-color;
      color: #6e6e6e;
      &.react-calendar__tile--active {
        background-color: $secondary-color;
        color: #6e6e6e;
      }
      &:disabled {
        background-color: $border-color;
      }
      &:hover {
        background-color: $secondary-color;
      }
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: #c7c7c7 !important;
    }
    .react-calendar__month-view__days__day--weekend {
      color: #6e6e6e;
    }
  }
}

.scroll-contents {
  overflow-y: auto;
  overflow-x: hidden;
}

.bold {
  font-weight: bold;
}

.mt {
  &--px-30 {
    margin-top: 30px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.Toastify__toast-body {
  white-space: pre-line;
  word-break: break-word;
  line-height: 20px;
}

// Toast in SP
[class^='Toastify__toast-container'] {
  @include breakpoint-down('md') {
    position: absolute;
    top: auto !important;
    bottom: 1em !important;
  }
}

.pointer {
  cursor: pointer;
}

// Tooltip bubble map
.gm-style-iw:focus-visible {
  outline: none !important;
}
