// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 600px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;
    @include breakpoint-down('sm') {
      height: auto;
    }
  }

  .customTitle {
    padding: 24px;
    border-bottom: 1px solid $primary600;
    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      padding: 24px 16px 16px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .inputFrame {
    flex: 1 1 0%;
    padding: 0 24px;
    > * {
      margin: 16px 0;
    }
    .textFrame {
      gap: 12px 12px;
      flex-wrap: wrap;
      @include flex($items: start, $justify: start);
      & > div {
        width: calc(50% - 6px);
      }
      .address {
        width: 100%;
      }
    }
  }

  .submit {
    margin-bottom: 24px;

    .submitButton {
      width: 160px;
      height: 44px;
      background-color: $main400;
      border-radius: 4px;
      font-family: 'Noto Sans JP';
      border: none;
      color: $grey-primary900;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }

      &:hover,
      &:active {
        background-color: $main500;
      }

      &:disabled {
        cursor: not-allowed;
        color: $main600;
        background-color: $main300 !important;
      }
    }
  }
}
