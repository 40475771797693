// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.paginationWrapper {
  padding: 0 2rem 2rem;
  .pagination {
    margin: 0.5rem;
    padding: 0;
    text-align: center;

    li {
      display: inline;
      padding: 0.25rem;

      button {
        height: 36px;
        display: inline-block;
        text-decoration: none;
        padding: 0.25rem 0.875rem;
        border-radius: 0.25rem;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        background-color: $primary50;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 400;
        color: $primary600;
        border: none;
        box-sizing: border-box;

        &.active {
          background-color: $primary100;
          font-weight: 700;
        }

        &:hover:not(.active) {
          background-color: $primary100;
          font-weight: 700;
        }

        &.dots {
          background-color: $primary50 !important;
          cursor: not-allowed;
        }

        svg {
          vertical-align: middle;
          transform: scale(0.7);
          path {
            stroke: $primary400;
          }
        }
      }
    }

    .btnArrow {
      padding: 0.25rem;
    }

    .disableButton {
      cursor: not-allowed;
      color: $primary400;
      &:hover {
        background-color: $primary50 !important ;
      }
    }
  }
}
