// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.loginContainer {
  background: $primary400;
  @include flex();
  #contents {
    height: 100vh;
    width: 50%;
    background: $primary900;
    background-image: url('./login-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 16px;
    box-sizing: border-box;
    @include flex($direction: column);
    @include breakpoint-down('lg') {
      width: 100%;
    }
  }
  #rightContents {
    width: 50%;
    height: 100vh;
    background: $primary900;
    @include flex($justify: flex-end);
    @include breakpoint-down('lg') {
      display: none;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
