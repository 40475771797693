// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.preRegistResourceSelect {
  @include breakpoint-down('md') {
    margin: 0 -24px;
  }

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: $grey-primary50;
    text-align: left;
    margin: 24px 0 0;

    @include breakpoint-down('md') {
      margin-top: 12px;
      text-align: center;
      font-size: 14px;
    }
  }

  .form {
    table,
    th,
    td {
      border: 1px solid $grey-primary700;
    }

    .tablePreRegistResourceSelect {
      max-height: calc(
        var(--vh) - #{$tabHeight + $headerHeight} - 80px - #{$bottomSmallHeight}
      );
      background-color: $grey-primary900 !important;
      color: $grey-primary50;
      width: 100%;
      border-radius: 4px;
      margin: 24px 0;

      @include breakpoint-down('md') {
        overflow: auto;
      }
    }

    thead {
      background-color: $grey-primary800 !important;
    }

    th {
      height: $theadTableHeight;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: $grey-primary100;
      text-align: left;
      padding: 16px;
    }

    .selection {
      width: 7%;
      text-align: center;

      .checkAllContainer .checkAll {
        text-decoration: underline;
      }
    }

    .materialName {
      width: 100%;

      input {
        background-color: $grey-primary800 !important;
        font-size: 0.875rem;
        font-weight: 700;
        color: $grey-primary100 !important;
      }
    }

    .dateTimeFilter {
      width: 10%;
      text-align: left;
    }

    .grade {
      width: 9%;
      text-align: left;
    }

    .receiveFilter {
      width: 14%;
      text-align: left;
    }

    .materialNameFilter {
      width: 14%;
      text-align: left;
    }

    .resourceIdCol {
      width: 18%;
      text-align: left;
    }

    .resourceId {
      width: 26%;
      text-align: left;
    }

    .weight {
      width: 10%;
      text-align: right;
    }

    .inputWeight {
      width: 18%;
      text-align: left;
    }

    .msgError {
      text-align: center;
      line-height: 26px;
      padding: 0 16px;
      font-size: 0.9em;
    }

    tbody {
      background: $grey-primary900;

      tr {
        height: $tbodyTableHeight;

        td {
          height: auto;
          padding: 16px;
          color: $grey-primary50;
          text-align: right;
        }
      }
    }

    tr {
      th {
        font-size: 0.875rem;
        font-weight: 700;
        height: auto;
        padding: 16px;
        color: $grey-primary100;
        text-align: right;
        position: relative;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        .checkAll {
          color: $grey-primary100;
          cursor: pointer;
        }
      }

      td {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        padding: 0.25rem;
        vertical-align: middle;
        word-break: break-all;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .msgError {
        text-align: center;
        line-height: 26px;
        padding: 0 16px;
        font-size: 0.9em;
      }
    }

    .inputWeightColInput {
      width: 100%;
    }

    .inputWeightCol {
      text-align: left;
      position: relative;
      color: $grey-primary50;
      font-size: 16px;
      line-height: 24px;

      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }

      .unit {
        position: absolute;
        top: 12px;
        right: 20px;
        font-size: 16px;
        line-height: 24px;
        color: $grey-primary300;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .input {
        width: -webkit-fill-available;
        height: 48px;
        border: 1px solid $login-input-bg;
        border-radius: 4px;
      }

      input[type='text'] {
        width: 100%;
        height: $inputHeight;
        padding: 12px 44px 12px 16px;
        background-color: $login-input-bg;
        color: $grey-primary50;
        font-size: 16px;
        line-height: 24px;
        border: 1px solid transparent;
        box-sizing: border-box;

        @include breakpoint-down('md') {
          font-size: 14px;
          line-height: 20px;
        }

        &:focus:not(.focus-visible) {
          border: 1px solid $main400;
          outline: none;
          box-shadow: none;
        }
      }

      .inputError {
        border-color: #f75354 !important;
      }
    }

    .error {
      font-size: 14px;
      color: #e9081e;
      font-weight: 400;
    }

    .bottomBtn {
      margin: 16px auto;
      @include flex($items: center, $justify: center);
      @include breakpoint-down('md') {
        margin: 16px;
      }

      button {
        min-width: 168px;
        padding: 16px 32px;
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 700;
        height: $buttonMediumHeight;
        box-sizing: border-box;
        border-radius: 4px;

        @include breakpoint-down('md') {
          width: 100%;
          padding: 10px;
          line-height: 18px;
        }
      }

      .btnRequest {
        color: $grey-primary900;
        background-color: $main400;
        margin: 0;
        border: none;

        &:focus {
          background-color: $main600;
        }

        &:disabled {
          color: $main600;
          cursor: not-allowed;
        }
      }
    }
  }

  .disabled {
    cursor: not-allowed !important;
  }
}

.mobileResourceList {
  .filterWrapper {
    overflow-y: auto;
    height: calc(var(--vh) - #{$tabHeight + $headerHeight} - 60px - #{$bottomSmallHeight});
    background-color: #2c2c2c;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .filterForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
  }

  .filterButtons {
    display: flex;
    gap: 12px;

    button {
      margin: 0;
      border-radius: 4px;
      font-size: 14px;
      padding: 6px 12px;
      min-height: 35px;
      border: 1px solid white;
      background-color: transparent;
      color: white;
      flex: 1;
    }

    .saveFilter {
      background-color: #86EA5C;
      border: 1px solid #86EA5C;
      color: #2c2c2c;
    }

    .clearFilter {
      border: 1px solid #fe3c61;
      color: #fe3c61;
    }
  }

  .resourcesListWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    height: calc(var(--vh) - #{$tabHeight + $headerHeight} - 28px - #{$bottomSmallHeight});
  }

  .resourceItem {
    border: 2px solid transparent;
    background-color: #2c2c2c;
    border-radius: 4px;
    color: #b7b7b7;
  }

  .checkedResourceItem {
    border: 2px solid $main600;
  }

  .checkedResourceError {
    border: 2px solid #F95776;

    .error {
      padding: 2px 12px 8px;
      text-align: left;
      color: #F95776;
      font-size: 12px;
    }
  }

  .disabledResourceItem {
    background-color: #6f6f6f;
  }

  .resourceItemContent {
    padding: 12px 12px 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    font-size: 14px;
  }

  .resourceWeightInputWrapper {
    padding: 4px;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      font-size: 14px;
    }
  }

  .resourceWeightInput {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    height: 28px;
    outline: none;
    border: none;
    border-radius: 4px;
    background-color: $login-input-bg;
    color: white;
    padding: 1px 28px 1px 8px;
  }

  .resourceWeightInputError {
    background-color: #fe3c61;
    border-radius: 4px;
  }

  .actionButtons {
    display: flex;
    gap: 12px;
    margin: 12px 0;

    button {
      margin: 0;
      border-radius: 4px;
      font-size: 14px;
      padding: 6px 12px;
      min-height: 35px;
      border: 1px solid white;
      background-color: transparent;
      color: white;
      flex: 1;

      &:disabled {
        cursor: none;
        background-color: #ffffff1f;
        border: 1px solid #b1b1b1;
      }
    }

    .active {
      border: 1px solid $main600;
    }
  }

  .mobileNoData {
    color: white;
    height: calc(var(--vh) - #{$tabHeight + $headerHeight} - 28px - #{$bottomSmallHeight});
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
