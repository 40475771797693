// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionSuccessModal {
  [class^='modal'] {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $dark400;
  }

  [class^='contents'] {
    width: 680px;
    background-color: $grey-primary900;
    box-shadow: 2px 2px 8px $shadow-primary;
    border-radius: 12px;
    padding: 48px;
    margin: 0;
  }

  &Content {
    text-align: center;
    color: $grey-primary50;

    &Icon {
      @include flex();

      width: 96px;
      height: 96px;
      border: 4px solid $main400;
      border-radius: 55px;
      margin: 0 auto;

    
    }

    &Description {
      font-size: 36px;
      line-height: 56px;
      margin: 32px 0;
    }
  }

  &Footer {

    &SubmitButton {
      background-color: $main400;
      height: 80px;
      border-radius: 4px;
      border: none;
      padding: 20px 40px;
      min-width: 189px;
      color: $grey-primary900;
      @include fontSizeText();

      &:focus {
        background-color: $main600;
      }
    }
  }
}
