// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.modalContent {
  padding: 2rem;
}

.customTitle {
  @include flex($justify: flex-end);

  .descriptionTitle {
    margin: auto;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
}

.top {
  @include flex($justify: flex-end);
}

.createUpdateSiteModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    @include flex();
  }

  [class^='content'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;
  }
  &.sizeModal,
  &.dateModal {
    [class^='content'] {
      width: 636px;
    }
  }
}
