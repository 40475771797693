// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R

@import 'variables.scss';
@import 'mixin.scss';

.modalContent {
  height: fit-content;
  max-height: 248px;
  padding: 0 16px 0 20px;
  margin: 16px 12px 32px 32px;
  font-size: 16px;
  color: $grey-primary50;
  text-align: left;
  line-height: 36px;
  overflow-y: scroll;
  overflow-x: hidden;
  @include customScroll;

  ul li {
    list-style-type: disc;
    word-break: break-word;

    &::marker {
      color: $main400;
    }
  }
}

.customTitle {
  padding: 32px 32px 12px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: $grey-primary50;
  @include breakpoint-down('md') {
    font-size: 16px;
    line-height: 20px;
    padding: 24px 16px 16px;
  }
  @include flex($justify: space-between);
  border-bottom: 1px solid $grey-primary800;
  margin-bottom: 8px;
}

.tareModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 448px;
    max-width: 50%;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    @include breakpoint-down('md') {
      max-width: 90%;
    }
  }
}

.top {
  @include flex($items: flex-end);

  svg {
    width: 16px;
    height: 16px;
  }
}
