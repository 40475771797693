// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.selectField {
  text-align: left;
  position: relative;
  font-size: 14px;
  & > div {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
  select {
    width: 100%;
    height: 44px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    background-color: $background-white;
    padding: 10px 24px 10px 10px;
  }

  .select {
    position: relative;
  }

  .input {
    caret-color: transparent;
    width: 100%;
    height: 44px;
    padding: 10px 24px 10px 10px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 14px;
    color: $grey-primary600;
    background-color: $background-white;
    border: 1px solid $grey-primary200;
    border-radius: 4px;
    box-sizing: border-box;

    &.empty {
      color: $placehoderColor;
    }
    &.inputError {
      border-color: $error-border-color;
    }
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      border: 1px solid $grey-primary200;
      color: $grey-primary600;
    }
    &.inputDisable:disabled {
      border-color: $grey-primary100;
      background-color: $grey-primary75;
      color: $grey-primary600 !important;
      cursor: not-allowed;
    }

    &:focus {
      border: 1px solid $grey-primary600;
    }
  }

  svg {
    position: absolute;
    top: 55%;
    right: 10px;
    margin-top: -5px;
  }
  .error {
    color: $error-border-color;
    font-size: 14px;
  }

  &.selectFieldDarkmode {
    input[name='language'] {
      padding: 10px 20px 10px 48px;
      background-color: $grey-primary900;
      &:focus:not(.focus-visible) {
        border: 1px solid transparent;
        outline: none;
        box-shadow: none;
      }
    }

    input {
      padding: 10px 24px 10px 10px;
      background-color: $login-input-bg;
      border: 1px solid transparent;
      color: #9f9f9f;

      &:focus:not(.focus-visible) {
        border: 1px solid $main400;
        outline: none;
        box-shadow: none;
      }

      &.inputDisable:disabled {
        border-color: #6f6f6f;
        background-color: #6f6f6f;
        color: #B7B7B7 !important;
        cursor: not-allowed;
      }

      &.empty {
        color: #9f9f9f;
      }
    }
  }
}

.placeHolder {
  color: $grey-primary300;
}

.SelectOptionsList {
  width: 100%;
  position: absolute;
  background-color: $background-white;
  z-index: 100;
  top: calc(100% + 4px);
  border: 1px solid $iron;
  max-height: 210px;
  overflow-y: scroll;
  cursor: pointer;
  box-shadow: 0px 1px 2px $box-shadow;
  border-radius: 4px;
  font-size: 14px;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  li {
    padding: 10px 8px;
    border-bottom: 1px solid $iron;
    overflow-wrap: break-word;
    color: $grey-primary600;
    &:hover {
      background-color: $grey-primary50;
    }
    &.selected {
      background-color: $grey-primary50;
    }
  }

  li:last-child {
    border-bottom: 0px;
  }
}

.DarkSelectOptionsList {
  background-color: $grey-primary600;
  border: 1px solid $grey-primary500;

  li {
    font-weight: 400;
    color: $grey-primary50;
    border-bottom: 1px solid $grey-primary500;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: $grey-primary500;
    }

    &.selected {
      background-color: $grey-primary500;
    }
  }
}
