// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.toggle {
  display: flex;
}

.switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  .switch .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey-primary50;
    border: 1px solid $grey-primary50;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
      background-color: $primary50;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }

  .sliderDarkMode {
    background-color: $grey-primary800;
    border: 1px solid  $grey-primary300;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
      background-color: $grey-primary300;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }

  .checkbox:checked + .slider {
    background-color: $grey-primary800;
    border: 1px solid $grey-primary800;
  }

  .checkbox:checked + .sliderDarkMode {
    background-color: $grey-primary800;
    border: 1px solid $main400;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
      background-color: $main400;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }

  .checkbox:checked + .slider:before {
    left: 2px;
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }

  .disableToggle {
    cursor: not-allowed;
  }

.content {
  margin-left: 8px;
  font-size: 20px;
  color: $grey-primary50
}
