// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customCheckBox {
  display: block;
  text-align: center;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
  }

  label:before {
    content: '';
    -webkit-appearance: none;
    background-color: $grey-primary900;
    border: 1px solid $grey-primary400;
    border-radius: 4px;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 9px;
    left: 8px;
    width: 5px;
    height: 8px;
    border: solid $grey-primary800;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
    @include breakpoint-down('md') {
      top: 8px;
    }
  }

  input:checked + label::before {
    background-color: $grey-primary50;
  }

  &.dark {
    input:checked + label:before {
      background-color: $main400;
      border: 1px solid $grey-primary400;
    }
  }
}

.disabled, .disabled label::before {
  cursor: not-allowed !important;
}
