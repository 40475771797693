// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.searchModal {
  [class^='modal'] {
    overflow: hidden;
    box-shadow: 2px 2px 20px 0px #16171b;
  }

  [class^='contents'] {
    background: none;
  }

  input {
    background: transparent;
    color: $grey-primary300;
  }

  [class^='InputTagField_mainFrame'] {
    background: $login-input-bg;
    border: none;
  }

  [class^='InputTagField_textInput'] {
    width: 100%;
  }

  [class^='PurchaseResourceSearchForm_analysis_right'] {
    padding: 0;
    background-color: transparent;
  }

  [class^='PurchaseResourceSearchTable_wrapper'] {
    max-height: calc(var(--vh) - (var(--vh) * 0.15) - 110px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $crollColor;
    }

    &::-webkit-scrollbar-thumb:hover {
      border-radius: 4px;
      background-color: $grey-primary700;
    }
  }

  .content {
    @include flex($direction: column);
    gap: 16px;
    border-radius: 8px;
    padding: 24px;
    background: #1b1b1b;

    .customTitle {
      @include flex($justify: space-between);
      width: 100%;

      .descriptionTitle {
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        color: $grey-primary50;
        margin-bottom: 8px;
      }

      .top {
        @include flex($items: flex-end);
      }

      border-bottom: 1px solid $grey-primary700;
    }

    .container {
      @include flex($items: flex-start);
      width: 100%;
      gap: 32px;

      .table {
        @include flex($direction: column, $items: flex-start);
        gap: 16px;
        flex: 1 1 auto;

        .total {
          color: #e7e7e7;
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          gap: 8px;

          .number {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
          }

          .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
          }
        }
      }
    }
  }
}
