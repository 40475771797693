@import 'variables.scss';
@import 'mixin.scss';

.container {
  position: relative;
  margin-left: 12px;

  .dropdown-toggle {
    @include flex($items: center, $justify: left);
    gap: 12px;

    height: 32px;
    cursor: pointer;

    p {
      @include truncate-oneline();

      text-align: left;
      flex: 1;

      color: $grey-primary50;

      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0em;
    }

    .avatar {
      background-color: $grey-primary50;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      font-weight: 700;
      font-size: 8px;
      line-height: 32px;

      img {
        width: 28px;
        height: 8.54px;
        object-fit: cover;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background: $grey-primary600;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    border: 1px solid $grey-primary500;
    border-radius: 4px;
    width: 120px;
    z-index: 100;
  }

  .dropdown-item {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 14px 25px;
    color: $grey-primary50;
  }

  .dropdown-item:hover {
    background-color: $grey-primary400;
  }
}
