@import 'variables.scss';
@import 'mixin.scss';

.bookingPrintModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 24px;
    padding-bottom: 24px;
    @include flex();
  }

  [class^='content'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;

    @include mq('xs') {
      width: 90%;
    }

    .modalContent {
      padding: 2rem;
      background: $grey-primary900;

      display: flex;
      flex-direction: column;
      gap: 16px;

      .customTitle {
        @include flex($justify: space-between);

        .descriptionTitle {
          font-size: 1.25rem;
          line-height: 1.75rem;
          font-weight: bold;
        }

        .top {
          @include flex($justify: flex-end);
        }
      }

      .btn {
        padding: 16px 32px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        height: $buttonMediumHeight;
        box-sizing: border-box;
        border: none;

        &:focus {
          background-color: $main600;
        }

        &:disabled {
          color: $main600;
          cursor: not-allowed;
          background-color: $main400 !important ;
          border: none;
        }

        @include breakpoint-down('md') {
          width: 100% !important;
          padding: 16px;
        }
      }

      .btn {
        color: $grey-primary900;
        background-color: $main400;
        border-radius: 4px;
        width: 200px;
        margin: 0;
      }
    }
  }
}
