// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

#html5qr-code-full-region {
  background-color: $grey-primary700;
  min-height: 384px;
  width: 384px;
  border: none !important;
  margin-top: 50px;
  @include flex($direction: column);
  video {
    width: 384px !important;
  }
  & > div:nth-of-type(1) {
    display: none;
  }
  #html5qr-code-full-region__dashboard_section > div:nth-of-type(2) {
    margin-top: 8px;
    span {
      color: $primary100;
      text-decoration: none !important;
    }
  }
  #html5-qrcode-anchor-scan-type-change {
    display: none !important;
  }
}
.wraper {
  position: relative;
  height: 100%;
  width: 100%;
  @include flex();
  .button-close {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
