// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  [class^='PostalCodeForm_inputFrame'] {
    [class^='PostalCodeForm_textFrame'] {
      padding: 20px 0 0;
    }
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0 0;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  > * {
    width: calc(50% - 6px);
  }

  .provinceWrap {
    width: 100% !important;
    .provincesFrame {
      .provinceItem {
        width: 100%;
        p {
          margin-bottom: 8px;
        }
      }
      .labelWrap {
        margin-bottom: 10px;
        @include flex($justify: space-between);
        .buttonDelete {
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;
          svg {
            margin-right: 4px;
            transform: translateY(1.5px);
          }
          @include flex();
        }
      }

      .location {
        display: flex;
        grid-gap: 12px;
        gap: 12px;
        justify-content: space-between;

        .province {
          width: 100%;
        }

        .district {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;

          .inputTag {
            position: none !important;
          }

          ul {
            width: 280px;
            li {
              p {
                font-weight: 700;
                font-size: 14px;
                margin-bottom: 0;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
    .customButtonAdd {
      margin-left: 440px;
      .buttonAdd {
        width: 100%;
        @include flex($justify: flex-end);
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        text-align: right;
        cursor: pointer;
        svg {
          margin-right: 4px;
        }
        & > div {
          width: fit-content;
          cursor: pointer;
          @include flex();
        }
        &.buttonAddDisabled {
          color: $primary200;
          path {
            stroke: $primary200;
          }
          & > div {
            cursor: not-allowed;
          }
          cursor: no-drop;
        }
      }
    }
  }
}

.submit {
  @include flex();
  padding: 1.5rem 2rem 0;
  border-top: 1px solid $primary100;
  margin: 1.5rem -2rem 0;
  gap: 24px;

  button {
    height: 44px;
    width: 240px;
    border-radius: 4px;
    margin: 0;
    font-weight: bold;
    line-height: 1.25rem;
    font-size: 0.875rem;
  }

  .submitButton {
    background-color: $primary400;
    border: none;
    color: $primary50;

    &:hover {
      background-color: $primary500;
    }
    &:disabled {
      cursor: not-allowed;
      color: $primary200;
      background-color: $primary400 !important ;
    }
  }

  .nextButton {
    border-radius: 4px;
    border: 1px solid var(--grey-color-g-600, #4d4d4d);
    background-color: $background-white !important;
  }
}
