@import 'variables.scss';
@import 'mixin.scss';

.adminUserListManagement {
  text-align: left;
  .countUser {
    margin-bottom: 20px;
    span {
      font-weight: 700;
    }
  }

  .tool {
    display: flex;
    justify-content: space-between;
    
    .search {
     .inputFrame {
        display: flex;

        .inputSearch {
          margin-right: 12px;
          position: relative;

          input {
            padding: 2px 25px 2px 30px;
            width: 200px;
          }

          .iconSearch {
            position: absolute;
            top: 14px;
            left: 14px;
            z-index: 1;
          }
        }

        .selectOperator {
          height: 44px ;

          input {
            height: -webkit-fill-available;
            line-height: 22px;
            width: 200px;
          }
        }
     }
    }

    .buttonAdd {
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      @include flex();
      span {
        margin-left: 6px;
      }
    }
  }

  .userTable {
    position: relative;
    margin-top: 12px;
    height: calc(100vh - 308px);
  }
}
