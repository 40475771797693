// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyEmissionCard {
  border-top: solid 1px $primary500;
  padding: 8px 0;

  .emissionType {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 4px 8px;
    margin: 4px 32px;
    border-radius: 4px;
    width: fit-content;
    @include breakpoint-down('md') {
      margin: 4px 16px;
    }

    &.emission {
      background-color: $grey-primary500;
      color: $grey-primary100;
    }

    &.requested {
      background-color: $grey-primary50;
      color: $grey-primary900;
    }
  }

  .request {
    border-top: solid 1px $primary600;
    margin-top: 12px;
    padding-top: 12px;
  }

  .label {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .status {
    width: 30%;
  }

  .printLabelButton {
    display: flex;
    width: 70%;
    height: fit-content;

    button {
      border: 0;
      text-align: center;
      font-weight: bold;
      padding: 4px 12px;
      border-radius: 4px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      color: $grey-primary900;
      background-color: $main400;
      cursor: pointer;
  
      &:active {
        background-color: $main600;
      }
    }
  }

  [class^='label-contents'] {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $grey-primary50;
    padding: 4px 32px;

    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
      padding: 4px 16px;
    }
  }
  
}
