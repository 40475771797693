// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

#scale-login {
  margin: 0 auto;
  padding: 0 30%;

  > div {
    width: 40%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  * {
    font-size: 1.1em;
  }
}
