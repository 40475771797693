// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.item {
  padding: 16px;
  box-sizing: border-box;
  transition: all 300ms ease;
  width: 25%;
  aspect-ratio: 1 / 1;

  @include breakpoint-down('lg') {
    width: 33.33333%;
  }

  @include breakpoint-down('md') {
    width: 100% !important;
    aspect-ratio: auto;
    padding: 0;
  }

  &.itemMedium {
    width: 100%;
    aspect-ratio: 2 / 1;
    @include breakpoint-down('md') {
      aspect-ratio: auto;
    }
  }

  &.itemSmall {
    width: 50%;
  }

  &.itemMedium,
  &.itemSmall {
    .customizeItemName {
      font-size: 18px;
      @include breakpoint-down('md') {
        display: none;
      }
    }
  }

  &_content {
    background-color: $bgGrey;
    border-radius: 12px;
    padding: 12px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    @include flex($items: flex-start, $justify: flex-start, $direction: column);
    @include disable-select();

    @include breakpoint-down('md') {
      height: 80px;
      @include flex(
        $items: center !important,
        $justify: flex-start,
        $direction: row
      );
    }
    .imageWrapper {
      width: 67.66667%;
      aspect-ratio: 1/1;
      border-radius: 12px;

      @include breakpoint-down('md') {
        width: 56px !important;
        aspect-ratio: 1/1;
        border-radius: 12px;
        margin-right: 12px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
        aspect-ratio: 1/1;
      }
    }

    .hiddenImgDefault {
      display: none
    }

    &.item_contentMedium {
      @include flex($items: flex-start, $justify: flex-start, $direction: row);
      .imageWrapper {
        height: 100%;
        width: calc(50% - 28px);
      }

      .customizeItemBody {
        padding-left: 12px;
        height: 100%;
        @include breakpoint-down('md') {
          padding-left: 0;
        }
      }

      .customizeItemNameFix {
        padding-right: 32px;
      }
    }
    &.item_contentSmall {
      .imageWrapper {
        width: 50%;
      }

      .customizeItemName {
        font-size: 16px !important;
        line-height: 24px !important;
      }

      .optionsArea {
        right: 8px;
      }
    }

    .customizeItemBody {
      z-index: 1;
      box-sizing: border-box;
      width: 100%;
      flex: 1;
      @include flex($direction: column, $items: flex-start);

      .customizeItemName {
        font-weight: 700;
        font-size: 20px;
        text-align: start;
        word-break: break-word;
        @include ellipseText(2);
      
        @include breakpoint-down('md') {
          font-size: 18px !important;
          line-height: 28px !important;
          margin-bottom: 4px;
        }
      }

      .customizeItemQuantity {
        font-size: 18px;
        line-height: 24px;
        text-align: start;
        @include ellipseText(1);

        @include breakpoint-down('md') {
          font-size: 16px;
          line-height: 24px !important;
        }
      }

      .customizeItemName,
      .customizeItemQuantity {
        width: 100%;
        line-height: 28px;
        color: $grey-primary50;
      }

      .ellipseText1 {
        font-size: 16px
      }

      .itemNameMedium {
        font-size: 18px !important
      }

      .itemNameSmall {
        font-size: 16px
      }
    }

    .customizeItemNameFix {
      @include breakpoint-down('md') {
        padding-right: 32px;
      }
    }
  }

  .optionsArea {
    position: absolute;
    z-index: 0;
    top: 12px;
    right: 12px;

    .buttonToggleSelectSize {
      @include flex($justify: flex-end);

      .buttonClick {
        @include flex($justify: flex-end, $items: flex-start);
        width: 32px;
        height: 16px;
      }
    }

    .itemType {
      color: $grey-primary50;
      font-size: 14px;
      line-height: 20px;
      background-color: $main500;
      border-radius: 4px;
      padding: 5px;
      @include flex();
      @include breakpoint-down('md') {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
      }

      svg {
        path {
          fill: $black;
        }
      }
    }
  }

  .selectSize {
    position: absolute;
    z-index: 2;
    top: calc(100% + 8px);
    right: 0;
    background: white;
    border: 0.5px solid rgba(209, 209, 209, 0.5);
    box-shadow: 2px 2px 8px rgba(74, 119, 140, 0.1);
    border-radius: 4px;

    .selectSizeList {
      min-width: 180px;
    }

    .selectSizeItem {
      padding: 18px 24px;
      @include flex($justify: start);

      span {
        margin-left: 8px;
      }
      
      &.active {
        background: $background-primary;
      }
    }
  }
}
