// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.purchase-header {
  @include flex($direction: row, $justify: space-between);

  flex: 1 1 0%;
  padding: 12px 20px;
  background-color: rgba(44, 44, 44, 0.24);
  box-shadow: inset 0 -1px 0 rgba(44, 44, 44, 1);
  gap: 16px;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    color: $grey-primary50;
  }

  .actions {
    @include flex($direction: row, $justify: normal);

    position: relative;
  }

  .breadcrumb {
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;

    a {
      color: $grey-primary300;
      font-weight: 400;
      word-break: break-all;
    }

    svg {
      padding: 0 14px;
      width: 6px;
      height: 12px;

      path {
        stroke: $grey-primary300;
      }
    }

    span {
      color: $grey-primary50;
    }
  }
}
