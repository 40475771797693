// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.grade-total .label-text {
  border-bottom: none;
  font-weight: bold;
}

.compressionReceives {
  min-height: calc(var(--vh) - #{$headerHeight});
  background-color: $grey-primary900;
  color: $grey-primary50;
}
