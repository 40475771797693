// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.productRegistration {
  .productRegistrationPulldown {
    .inputFrame {
      flex: 1 4 0%;

      > * {
        margin: 16px 0;
      }

      .textNotification {
        gap: 16px;
        flex-wrap: wrap;
        color: $grey-primary50;

        @include flex($items: flex-start, $justify: flex-start);
      }

      .textFrame {
        gap: 16px;
        flex-wrap: wrap;
        @include flex($items: flex-start, $justify: flex-start);

        & > div {
          width: calc(25% - 6px);
        }

        [name^='siteId'] {
          background-color: $login-input-bg;
          border: 1px solid transparent;
          color: $grey-primary50;
        }
      }
    }
  }
}
