// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.customTitle {
  padding: 32px 32px 12px 32px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: $grey-primary50;
  @include breakpoint-down('md') {
    font-size: 16px;
    line-height: 20px;
    padding: 24px 16px 16px;
  }
  @include flex($justify: space-between);
  border-bottom: 1px solid $grey-primary800;
}

.createTenantModal {
  [class^='modal'] {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
  }

  [class^='contents'] {
    width: 480px;
    margin: auto;
    border-radius: 4px;
    background-color: $grey-primary900;
    color: $grey-primary50;
  }
}

.top {
  @include flex($items: flex-end);
}
