// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyRecycleReceives {
  max-height: calc(var(--vh) - #{$headerHeight + $tabHeight});

  .commonCard {
    padding: 12px 0;
    border-bottom: 1px solid $primary500;

    &:last-child {
      border-bottom: none;
    }

    [class^='label-contents'] {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      padding: 4px 16px;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
