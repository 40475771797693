// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.history {
  background-color: $grey-primary900;
  min-height: calc(var(--vh) - #{$headerHeight});

  .scroll-contents {
    max-height: calc(var(--vh) - #{$headerHeight + $tabHeight});
  }
}
