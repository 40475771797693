// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.addICCardForm {
  .inputFrame {
    padding: 1.5rem 0;
  }
  .line {
    width: 480px;
    height: 1px;
    background-color: $primary100;
    margin-left: -32px;
  }

  .submit {
    margin-top: 1.5rem;

    .submitButton {
      height: $buttonSmallHeight;
      width: 100%;
      padding: 12px 16px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $primary50;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: bold;
    }

    .disableButton {
      height: $buttonSmallHeight;
      width: 100%;
      padding: 12px 16px;
      background-color: $primary400;
      border-radius: 4px;
      border: none;
      color: $background-white;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: bold;
      cursor: not-allowed;
      opacity: 0.7;
      box-sizing: border-box;
    }
  }
}
