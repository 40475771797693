// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.historyRecycleCompletes {
  max-height: calc(var(--vh) - #{$headerHeight + $tabHeight});

  [class^='scroll-contents'] {
    max-height: calc(
      var(--vh) - #{$headerHeight + $tabHeight} - #{$bottomSmallHeight}
    );
  }

  [class^='bottom-field'] {
    height: $bottomSmallHeight;
    box-sizing: border-box;
    @include flex();

    [class^='bottom-btn-field'] {
      @include breakpoint-down('md') {
        width: 100%;
        margin: 24px 16px;
      }
    }

    button {
      height: $buttonMediumHeight;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: $grey-primary900;
      background-color: $main400;
      padding: 1rem 5rem;
      margin: 0;

      &:disabled {
        cursor: not-allowed;
        color: $main600;
      }

      &:focus {
        background-color: $main600;
      }
    }
  }

  .commonCard {
    padding: 12px 0;
    border-bottom: 1px solid $primary500;

    &:last-child {
      border-bottom: none;
    }

    [class^='label-contents'] {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      padding: 4px 16px;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
