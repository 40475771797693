// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.downloadModal {
  [class^='modal'] {
    display: flex;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $dark400;
  }

  [class^='content'] {
    max-width: 1072px;
    width: 80%;
    margin: auto;
    border-radius: 4px;
    box-shadow: 2px 2px 20px $grey-primary900;
    background-color: $grey-primary900;
    @include breakpoint-down('md') {
      width: calc(100% - 32px);
    }
  }

  .modalContent {
    .heading {
      padding: 32px 32px 16px 32px;
      box-shadow: 0px 1px 0px $grey-primary700;
      font-size: 18px;
      line-height: 28px;
      font-weight: 700;
      text-align: left;
      color: $grey-primary50;
      @include flex($justify: space-between);
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 24px;
        padding: 24px 16px 16px;
      }

      .top {
        @include flex($items: flex-end);
      }
    }

    .body {
      @include breakpoint-down('md') {
        padding: 0 16px;
      }
      .targetDateGroup {
        font-size: larger;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 1.5rem 0;
        color: $grey-primary50;

        p {
          padding: 5px;
        }
      }
    }

    .modalBottomField {
      .bottomBtnField {
        .primaryBtn {
          .downloadButton {
            @include breakpoint-down('md') {
              padding: 16px 16px 24px;
            }
            &.default {
              button {
                font-size: 16px;
                line-height: 24px;
                font-weight: bold;
                border-radius: 4px;
                width: 350px;
                height: $buttonMediumHeight;
                background-color: $main400;
                color: $grey-primary900;
                box-sizing: border-box;
                border: none;
                margin: 2rem;
                padding: 16px 40px;

                &:focus {
                  background-color: $main600;
                }

                @include breakpoint-down('md') {
                  height: $buttonMediumHeight;
                  font-size: 16px;
                  line-height: 24px;
                  padding: 16px;
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.downloadModalOpen {
  display: block;
}

.downloadModalClose {
  display: none;
}

.calendarField {
  @include flex($justify: space-between);
  width: 240px;
  background-color: $login-input-bg;
  padding: 12px 16px;
  border-radius: 4px;
  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
    height: $buttonSmallHeight;
    box-sizing: border-box;
  }
}
