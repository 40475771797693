// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';

.textInput {
  position: relative;
  > div {
    text-align: left;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
  .textInputWrap {
    position: relative;
    &.textInputWrapHasSubfix input {
      padding-right: 34px;
    }
  }
  textarea {
    height: 100px;
    padding: 12px;
    border: 1px solid $primary200;
    border-radius: 4px;
    width: 100%;
    color: $primary600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    box-sizing: border-box;

    &:disabled {
      background-color: $primary200;
      color: $primary600;
    }

    &:focus:not(.focus-visible) {
      border: 1px solid $primary600;
      outline: none;
      box-shadow: none;
    }
  }
  p {
    margin-top: 4px;
    color: $error-border-color;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
  }
  .error {
    font-size: 14px;
    color: $error-border-color;
  }
}
