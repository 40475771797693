// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.donut {
  padding: 22px 16px;
  background-color: $grey-primary800;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.main {
  padding: 16px 0px;

  @include breakpoint-down('md') {
    padding: 0;
  }
}

.donutchart {
  width: 80%;
  display: inline-block;
  height: auto;
  margin: auto;
  cursor: pointer;
  position: relative;

  @include breakpoint-down('lg') {
    width: 90%;
  }

  canvas {
    width: 80%;
    position: relative;
    z-index: 98;

    @include breakpoint-down('lg') {
      width: 90%;
    }
  }
}

.heading {
  @include flex($justify: space-between);
  margin-bottom: 16px;
  align-items: flex-start;

  .headingBox {
    text-align: left;

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      color: $grey-primary50;
    }

    .content {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      padding-top: 8px;
    }
  }

  .buttonDownload {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $grey-primary600;
    @include flex();
  }
}

.legendArea {
  display: grid;
  margin: 32px 16px 0 16px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row dense;
  /* extra styles */
  grid-gap: 12px;
}

.legendColum {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 500px;
  overflow: auto;
  @include customScroll();
  padding: 4px 8px 4px 0;
  font-size: 12px;
  color: #f6f6f6;

  @include breakpoint-down('md') {
    width: 100%;
    gap: 8px;
    max-height: unset;
    overflow: unset;
  }
}

.legendColumItem {
  @include flex($justify: flex-start);
  gap: 8px;

  & > p {
    text-align: left;
  }
}

.legend {
  display: flex;
  grid-column-start: 1;
  align-items: center;
  gap: 8px;

  div {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.legend2 {
  grid-column-start: 2;
}

.legendColor {
  border-radius: 2px;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  max-height: 12px;
}

.legendLabel {
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  @include truncate-multiline(1);
  color: $primary50;
  word-break: break-all;
}

.emptyView {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: $primary50;
  }
}

.preview {
  @include flex($direction: row);
  gap: 64px;

  @include breakpoint-down('md') {
    flex-direction: column;
    gap: 16px;
  }

  .chart {
    position: relative;

    canvas {
      position: relative;
      z-index: 10000;
    }
  }
}

.total {
  color: #e7e7e7;
  margin-bottom: 16px;
}

.totalRate {
  z-index: 1;
}

.actionsWrapper {
  display: flex;
}
