// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.purchaseReceives {
  [class^='scroll-contents'] {
    height: calc(
      var(--vh) - #{$purchaseHeaderHeight} - 40px - 32px - #{$tabHeight} - #{$selectHeight +
        32px} - #{$bottomSmallHeight} - 48px
    );

    overflow: auto;
  }

  [class^='check-card'] {
    border: none;
    padding: 12px 16px;
    box-shadow: 0px 1px 0px $primary500;

    [class^='label-contents'] {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $grey-primary50;
      padding: 4px 0 4px 16px;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
        padding: 4px 0 4px 16px;
      }
    }

    [class^='checkbox'] {
      label::before {
        background-color: $grey-primary900;
        border: 1px solid $grey-primary400;
      }
    }
  }

  [class^='bottom-field'] {
    background-color: $bgPurchase;
    width: calc(100% - 240px - 40px);
    height: $bottomSmallHeight;
    margin: 0 -16px;
    box-sizing: border-box;
    @include flex();

    [class^='bottom-btn-field'] {
      @include breakpoint-down('md') {
        width: 100%;
        margin: 24px 16px;
      }
    }

    button {
      height: $buttonMediumHeight;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      color: $grey-primary900;
      background-color: $main400;
      padding: 1rem 5rem;
      margin: 0;

      &:disabled {
        cursor: not-allowed;
        color: $main600;
      }

      &:focus {
        background-color: $main600;
      }
    }
  }

  [class^='no-data'] {
    width: 100%;
    height: 100%;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $grey-primary50;

    @include flex();
  }
}

.checkBox {
  width: 10%;
  padding: 12px 8px 12px 16px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  cursor: pointer;

  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }
}

.disabledCheckAll {
  cursor: not-allowed
}

.label {
  width: 90%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  @include breakpoint-down('md') {
    font-size: 14px;
    line-height: 20px;
  }
}
.topSelect {
  color: $grey-primary50;
  @include flex();
  box-shadow: 0px 1px 0px $grey-primary800;
  user-select: none;
}
