// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.emissionChild {
  background-color: $grey-primary900;
  box-sizing: border-box;
  min-height: calc(100vh - 64px);
  max-height: calc(100vh - 64px);
  overflow: auto;
  padding: 24px 32px;
  @include breakpoint-down('md') {
    padding: 16px;
  }

  .tenantNameMobile {
    display: none;
    @include breakpoint-down('md') {
      display: block;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
      margin-bottom: 16px;
      color: $grey-primary50;
      @include ellipseText(1);
    }
  }

  &Header {
    @include flex($justify: space-between);

    [class^='count-list'] {
      width: 20%;
    }

    &TenantName {
      font-size: 30px;
      line-height: 42px;
      color: $grey-primary50;
      width: 60%;
      @include ellipseText(1);
      @include breakpoint-down('md') {
        display: none;
      }
    }

    &SortButton {
      width: 20%;
      text-align: right;
      button {
        background-color: inherit;
        border: 1px solid $primary75;
        border-radius: 4px;
        padding: 11px 23px;
        min-width: 104px;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $primary75;
        @include breakpoint-down('md') {
          display: none;
        }
      }
    }
  }

  &HeaderNormal {
    [class^='count-list'] {
      width: 20%;
    }

    display: flex;
    justify-content: flex-start !important;
    -webkit-justify-content: flex-start !important;

    &TenantName {
      font-size: 30px;
      line-height: 42px;
      color: $grey-primary50;
      width: 60%;
      @include ellipseText(1);
      @include breakpoint-down('md') {
        display: none;
      }
    }
  }

  &SortEmissionsDragDropSection {
    height: calc(100vh - 64px);
    margin: -24px -32px;
  }
}

.gridArea {
  margin: 16px -16px -16px;
  flex-wrap: wrap;
  @include flex($items: flex-start, $justify: flex-start);
  @include breakpoint-down('md') {
    margin: 16px 0 0;
    gap: 12px;
  }

  .mediumItemsGroup {
    width: 25%;
    flex-wrap: wrap;
    @include flex($items: flex-start, $justify: flex-start);
    @include breakpoint-down('md') {
      width: 100%;
      gap: 12px;
    }
  }
}
