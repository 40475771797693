@import 'variables.scss';
@import 'mixin.scss';

@mixin text {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0em;
}

.field {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px $primary500;
  padding: 8px 32px;

  @include mq('xs') {
    padding: 8px;
  }

  @include flex($justify: flex-start);

  .labelBox {
    @include flex($justify: flex-start);

    height: 48px;
    min-width: 25%;

    @include mq('xs') {
      min-width: 100px;
    }

    .label {
      @include text();
      font-weight: 700;
    }
  }

  .fieldBox {
    @include flex($justify: flex-start);

    height: 48px;
    width: 100%;

    .value {
      @include text();
      font-weight: 700;
    }

    .selectBox {
      width: 100%;
      position: relative;

      select {
        width: 100%;
        height: 48px;
        color: #878787;
        background: #ffffff1f;
        padding-left: 8px;
        border-radius: 4px;
        border: none;
        appearance: none;

        @include text();

        &:focus {
          outline: none;
        }
      }

      .icon {
        position: absolute;
        top: 12px;
        right: 8px;
      }
    }
  }
}
