// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.downloadModal {
  [class^='modal'] {
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
  }

  [class^='content'] {
    width: 90%;
    margin: auto;
    border-radius: 4px;
    height: 709px;
    background-color: $grey-primary900;
    color: $grey-primary50;
    @include breakpoint-down('sm') {
      height: auto;
    }
  }

  .customTitle {
    padding: 32px 32px 16px 32px;
    border-bottom: 1px solid $primary600;
    @include flex($justify: space-between);
    @include breakpoint-down('md') {
      padding: 24px 16px 16px;
    }

    .title {
      font-size: 20px;
      font-weight: bold;
      @include breakpoint-down('md') {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .top {
      svg > path {
        stroke: $primary400;
      }
    }
  }

  .body {
    margin: 0px 32px;
    height: 444px;
    overflow: auto;
    border: 1px solid $primary600;
    border-radius: 4px;
    @include breakpoint-down('sm') {
      max-height: 60%;
      margin: 0px 16px;
    }
  }

  .totalRecovery {
    text-align: right;
    padding: 24px 32px;
    font-size: 18px;
    line-height: 28px;
    @include breakpoint-down('md') {
      font-size: 14px;
      line-height: 20px;
      padding: 24px 16px;
    }

    p > span {
      font-weight: bold;
    }
  }

  .submit {
    padding: 24px 16px 32px;

    .submitButton {
      width: 242px;
      height: $buttonMediumHeight;
      background-color: $main400;
      border-radius: 4px;
      border: none;
      color: $grey-primary900;
      font-size: 16px;
      line-height: 24px;
      font-weight: bold;

      &:focus {
        background-color: $main600;
      }

      @include breakpoint-down('md') {
        width: 100%;
        padding: 12px 16px;
      }
    }

    .submitButton:hover {
      background-color: $main400;
      color: $grey-primary900;
    }

    .submitButton:disabled {
      border-radius: 4px;
      border: none;
      font-weight: bold;
      width: 242px;
      height: $buttonMediumHeight;
      cursor: not-allowed;
      background-color: $main400 !important;
      color: $main600;
    }
  }
}
