// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.text-input-login {
  position: relative;
  margin: 16px auto !important;
  > div {
    text-align: left;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
  }
  input {
    padding: 0px 8px;
    border: none !important;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    color: $grey-primary50;
    background: transparent;
    outline: none !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: $grey-primary50;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    caret-color: white;
    transition: background-color 5000s ease-in-out;
  }
  p {
    margin-top: 4px;
    color: $function300;
    text-align: left;
  }
  .input-wraper {
    padding: 12px 16px;
    height: $inputHeight;
    background: $login-input-bg;
    border-radius: 4px;
    border: 1px solid transparent;
    box-sizing: border-box;
    @include flex($justify: flex-start);

    &:has(input:focus) {
      border: 1px solid $main400;
      outline: none;
      box-shadow: none;
    }
  }
  .label-wraper {
    @include flex($justify: flex-start);
    & > div {
      font-size: 16px;
      line-height: 24px;
      color: $grey-primary50;
      @include breakpoint-down('md') {
        font-size: 14px;
        line-height: 20px;
      }
    }
    span {
      margin-left: 2px;
    }
  }
  .icon {
    height: 100%;
    border-right: 1px solid $grey-primary300;
    padding-right: 10px;
    @include flex();
  }
  .error {
    border: 1px solid $function300;
  }
  .error-text {
    color: $function300;
  }
}

#scale-contents {
  .text-input-login {
    input {
      height: 100%;
    }
  }
}
