// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex(); 
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 24px 0;

  > * {
    margin: 12px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.line {
  width: 480px;
  height: 1px;
  background-color: $primary100;
  margin-left: -32px;
}

.submit {
  margin-top: 24px;
}

.submitButton {
  height: 44px;
  width: 100%;
  padding: 12px 16px;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: $background-white;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;

  &:hover,
  &:active {
    background-color: $primary500;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    color: $primary200;
    background-color: $primary400;
  }
}
