// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

$heightHeader: 36px;

.LineChart {
  height: 100%;
  padding: 14px 16px;
  background-color: $grey-primary800;
  border-radius: 12px;
  text-align: left;
  box-sizing: border-box;

  .Header {
    height: $heightHeader;
    gap: 20px;
    @include flex($justify: space-between);

    .Heading {
      .Title {
        font-size: 20px;
        font-weight: 700;
        color: $grey-primary50;
        text-transform: capitalize;

        @include breakpoint-down('xl') {
          font-size: 16px;
        }
      }

      .NoteMessage {
        font-size: 12px;
        color: $grey-primary300;
        padding-top: 6px;
      }
    }
  }

  .Actions {
    gap: 24px;
    @include flex();

    button {
      margin: 0;
    }

    .DownloadButton {
      border: 1px solid $grey-primary600;
      background-color: transparent;
      border-radius: 4px;

      &:disabled {
        cursor: not-allowed;
        background-color: $primary300;
      }
    }
  }

  .Chart {
    height: calc(100% - #{$heightHeader});
    @include flex($items: center, $direction: column);

    .Empty {
      @include flex();
      height: 100%;
      font-size: 16px;
      color: $grey-primary50;
    }
  }

  .ChartWrapper {
    gap: 32px;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    @include flex($justify: space-between);
  }

  .ChartBox {
    height: 100%;
    width: 100%;
  }

  .TimeLineChartModalWrapper {
    overflow-y: hidden;
    display: flex;

    [class^='contents'] {
      max-width: 1600px;
      margin: auto;
      background: none;
      border-radius: 8px;
    }

    .TimeLineChartModal {
      background-color: $grey-primary900;
      margin: auto;
      max-height: 100vh;
      overflow: auto;
      @include customScroll();

      .ModalTitle {
        display: flex;
        justify-content: space-between;
        padding: 32px 32px 16px 32px;
        border-bottom: 1px solid $grey-primary700;
        gap: 16px;

        & > p {
          color: $grey-primary50;
          font-weight: 700;
          font-size: 20px;
        }
      }

      .ModalBody {
        padding-bottom: 24px;

        .NoteMessage {
          font-size: 14px;
          color: $grey-primary300;
          padding: 16px 32px 0;
          text-align: left;
        }
      }

      .Charts {
        gap: 8px;
        @include flex(
          $justify: space-between,
          $items: flex-end,
          $direction: column
        );
        align-items: stretch;
        padding: 16px 32px 0;

        .ChartBox {
          max-height: 800px;
          height: 70vh;
          min-height: 400px;
        }
      }
    }
  }

  .LegendWrapper {
    width: 100%;
    gap: 12px;
    @include flex();

    .LegendItem {
      gap: 8px;
      @include flex();

      .LegendSolidLine {
        border: 1px solid $main400;
        width: 30px;
      }

      .LegendDashedLine {
        border: 1px dashed $main400;
        width: 30px;
      }

      .LegendLabel {
        font-size: 12px;
        line-height: 18px;
        color: $primary75;
      }
    }
  }
}
