// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.primaryBtn {
  position: relative;
  padding: 16px 0;

  @include breakpoint-down('md') {
    width: 100%;
  }
}

.btnRequest {
  height: 56px;
  color: $grey-primary900;
  background-color: $main400;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  padding: 0 28px;
  font-size: 16px;
  line-height: 24px;

  &:focus {
    background-color: $main600;
  }

  @include breakpoint-down('md') {
    width: 100%;
  }
}
