// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

#admin-main.download-page {
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $primary600;
  }

  #data-downloads {
    display: flex;
    margin-top: 20px;

    .target-date-group {
      @include flex($items: center, $justify: space-between);

      padding-bottom: 20px;

      .distance {
        padding: 16px;
      }

      .calendar-text-field {
        border-radius: 4px;
        border: 1px solid $primary200;
        padding: 11px 15px;
        font-size: 14px;
        line-height: 20px;
        color: $primary300;
        min-width: 100px;
        text-align: left;  
        width: 268px;

        .icon {
          top: calc(50% - 8px);
          right: 16px;
          width: 16px;
          height: 16px;
    
          svg {
            width: 100%;
            height: 100%;
    
            path {
              fill: $primary300;
            }
          }
        }
      }
    }

    .calender-datepick {
      padding: 0;
      width: 300px;

      .react-calendar {
        border: 0px;
        &__month-view {
          margin-bottom: 0;
        }
      }

    }
  }

  .modal-bottom-field {
    margin-top: 20px;
    width: 300px;

    .bottom-btn-field {
      padding: 0;
      width: 100%;
    }
  }
  .submit {
    margin-top: 24px;
    width: 100%;
  }
  
  .submitButton {
    height: 44px;
    width: 100%;
    padding: 12px 16px;
    background-color: $primary400;
    border-radius: 4px;
    border: none;
    color: $background-white;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;

    &:hover, &:active {
      background-color: $primary500;
    }
  }
}
