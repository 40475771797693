// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  font-size: 14px;
  z-index: 10000;
}

.tooltip .tooltipText {
  visibility: hidden;
  width: 100%;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  transform: translate(-50%, -16px);
  word-break: break-all;
}

.tooltip .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
