// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.materialFlowChart {
  background-color: $grey-primary800;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 20px
}

.body {
  padding: 0px 16px;
  @include breakpoint-down('md') {
    padding: 4px;
  }

  .chart {
    height: 300px;
  }

  > div {
    color: #e7e7e7;
    margin-bottom: 16px;
  }
}

.heading {
  padding: 16px;
  @include flex($justify: space-between);

  @include breakpoint-down('md') {
    padding: 4px;
  }
 
  .label {
    display: flex;
    align-items: center;
  }

  p {
    font-weight: 700;
    color: $grey-primary50;
    font-size: 16px;
    margin-left: 10px;
  }

  .buttonDownload {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid $grey-primary600;
    @include flex();

    @include breakpoint-down('md') {
      width: 42px;
      height: 30px;
      font-size: 14px;
    }
  }
}
