// gkc_hash_code : 01GPFQ2BY4JCG0W281FKCRX39R
@import 'variables.scss';
@import 'mixin.scss';

.createBody {
  margin: auto;
  @include flex();
}

.formInput {
  width: 100%;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.msgError {
  text-align: center;
  line-height: 26px;
  padding: 0 16px;
  font-size: 0.9em;
  color: $redRequired;
}

.inputFrame {
  flex: 1 1 0%;
  padding: 20px 0 12px;
  > * {
    margin: 12px 0;
  }
  .timeFrame,
  .selectFrame,
  .emailFrame {
    gap: 12px 12px;
    flex-wrap: wrap;
    @include flex($items: start, $justify: start);
    & > div {
      width: calc(50% - 6px);
    }
  }
  .emailFrame {
    margin-bottom: 12px;
    &.emailFrameError input {
      border-color: $error-border-color !important;
    }
    .inputWrap {
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 6px;
        height: 16px;
        cursor: pointer;
      }
    }
    input {
      height: 44px;
      padding: 12px 32px 12px 12px;
      border: 1px solid $primary200;
      border-radius: 4px;
      width: 100%;
      color: $primary600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      font-weight: 400;
      box-sizing: border-box;

      &:disabled {
        background-color: $primary200;
        color: $primary600;
      }

      &:focus:not(.focus-visible) {
        border: 1px solid $primary600;
        outline: none;
        box-shadow: none;
      }
    }
  }
  .error {
    font-size: 16px;
    color: $error-border-color;
    text-align: left;
  }
  .buttonAdd {
    height: 44px;
    width: fit-content;
    user-select: none;
    cursor: pointer;
    @include flex($justify: start);
    span {
      font-size: 14px;
    }
    &.buttonAddDisable {
      path {
        stroke: $primary200;
      }
      span {
        color: $primary200;
      }
    }
    span {
      font-weight: 700;
      color: $primary600;
      margin-left: 6px;
    }
  }
  .workingDayFrameHeading,
  .emailHeading,
  .paymentHeading {
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 0;
  }
  .workingDayFrame {
    margin-top: 0;
    display: flex;
    .workingDayFrameItem {
      flex: 1;
    }
    .workingDayFrameItemLable {
      color: $primary600;
      font-weight: 700;
    }
  }
  .systemUsageFeeUnit {
    position: absolute;
    right: 12px;
    bottom: 14px;
    font-weight: 400;
  }
}

.submit {
  padding: 24px 66px 0;
  margin: 0 -32px;
  border-top: 1px solid $primary100;
  @include flex();
}

.submitButton {
  height: 44px;
  width: 240px;
  background-color: $primary400;
  border-radius: 4px;
  border: none;
  color: $primary50;
  font-weight: 700;
  flex: 1;
  &:hover,
  &:active {
    background-color: $primary500;
  }
}
.prevButton {
  height: 44px;
  width: 240px;
  background-color: white;
  border-radius: 4px;
  border: none;
  color: $primary600;
  font-weight: 700;
  margin-right: 24px;
  border: 1px solid $primary400;
  flex: 1;
  &:hover,
  &:active {
    background-color: $primary300;
    color: white;
  }
}

.disableButton {
  border-radius: 4px;
  border: none;
  color: $primary200;
  font-weight: 700;
  height: 44px;
  width: 100%;
  cursor: not-allowed;
  background-color: $primary400 !important ;
}
.headingImages {
  text-align: left;
  font-weight: 700;
  margin-top: 16px;
  font-size: 14px;
}

.submitOptionFrame {
  display: flex;
  justify-content: center;
  padding-left: 10px;
  .submitOptionFrameItem {
    flex: 1;
  }
  .submitOptionFrameItemLable {
    color: $primary600;
    font-weight: 700;
    width: 100%;
    text-align: left;
  }
}
